import React, { Fragment } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

//control
import PrivateRouter from './ui/Control/PrivateRouter'
//component
import LoginPage from './ui/Login'
import MainPage from './ui/Main'
//constant
import * as paths from './assets/constant/path'

function App() {
  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }))

  return (
    <Fragment>
      <Switch>
        <Route exact path="/">
          <Redirect to={`/${paths.LOGIN}`} />
        </Route>
        <Route path={`/${paths.LOGIN}`}>
          <LoginPage />
        </Route>
        <PrivateRouter path={`/${paths.MAIN}`} isLogin={loginReducer.loggedIn}>
          <MainPage />
        </PrivateRouter>
        <Redirect to="/" />
      </Switch>
    </Fragment>
  );
}

export default App;
