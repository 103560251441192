import styled from 'styled-components'

const StepDetailsStyles = styled.div`
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 10px;
`;

export {
    StepDetailsStyles
}