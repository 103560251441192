import styled from "styled-components";

const Bound = styled.div`
    display: grid;
    grid-template-rows: 88px 1fr;
    background: #016730;
    box-shadow: 4px 0px 48px rgba(0, 0, 0, 0.05);
    width: 74px;
    /* width: 266px; */
    padding-left: 6px;
    height: 100%;
    transition: all 0.3s;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    #short-logo, #long-logo{
        transition: all 0.3s;
        display: block;
        position: absolute;
        margin-left: 18px;
    }
    #short-logo{
        opacity: 1;
    }
    #long-logo{
        opacity: 0;
    }
    .side-menu-header {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        position: relative;
        div {
            width: 28px;
            height: 28px;
        }
    }
    .side-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-top: 37px;
        .menu-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            p {
                width: max-content;
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 128%;
                color: #ffffff;
                position: absolute;
                left: 54px;
                opacity: 0;
                transition: all 0.3s;
            }
            .menu-logo {
                width: 25px;
                height: 25px;
                margin-left: 24px;
                transition: all 0.3s;
            }
            .active-bound {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.15);
                border-radius: 30px 0px 0px 30px;
                transition: left 0.3s;
            }
            .line-active {
                width: 5px;
                height: 100%;
                background-color: #fff;
                border-radius: 3px 0px 0px 3px;
                position: absolute;
                top: 0;
                right: -5px;
                transition: right 0.5s;
            }
        }
        .active-link {
            .active-bound {
                left: 0;
            }
            .line-active {
                right: 0;
            }
        }
    }
    :hover {
        width: 266px;
        padding-left: 34px;
        #short-logo, #long-logo{
            margin-left: 41px;
        }
        #short-logo{
            opacity: 0;
        }
        #long-logo{
            opacity: 1;
        }
        .side-menu{
            .menu-item{
                p{
                    opacity: 1;
                }
            }
            .menu-logo {
                margin-left: 22px;
            }
        }
    }
`;

export { Bound };
