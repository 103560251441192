import React, { useState, useReducer, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

// styles
import { WrapTransactionInfos } from './index.styles'

// component
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import StepSix from './StepSix'
import StepSeven from './StepSeven'
import StepEight from './StepEight'

// socket
import WSFormStream from './WSFormStream'

// api
import { apiGetImgFormStream } from '../../../../../../assets/constant/host'

// constant
import formReceiveCardTypes from '../../../../../../assets/constant/formReceiveCardTypes'

const FORM_STEP_1 = 'FORM-STEP-1'
const FORM_STEP_2 = 'FORM-STEP-2'
const FORM_STEP_3 = 'FORM-STEP-3'
const FORM_STEP_4 = 'FORM-STEP-4'
const FORM_STEP_5 = 'FORM-STEP-5'
const FORM_STEP_6 = 'FORM-STEP-6'
const FORM_STEP_7 = 'FORM-STEP-7'
const FORM_STEP_8 = 'FORM-STEP-8'
const PRE_STEP = 'FORM-STEP-'
const END_STEP = 'END-STEP'


const getStepStatus = (condInprogress, condDone) => {
    if(condDone)
        return 'PASSED'
    if(condInprogress)
        return 'INPROGRESS'
    return null
}

const TransactionInfos = ({...props}) => {

    const wsFormStreamRef = useRef()
    const stepInprogress = useRef(1)

    const { uuid } = useSelector(state => ({
        uuid: state.loginReducer.dataLogin.uuid
    }))
    
    const [stepDone, setStepDone] = useState([]);
    const [dataSteps, setDataSteps] = useReducer(
        (state, action) => ({
            ...state,
            [action.type]: {
                ...state[action.type],
                ...action.payload
            },
        }),
        {
            [FORM_STEP_1]: {
                idCardType: '',
            },
            [FORM_STEP_2]: {
                idCardType: '',
                listIdCard: []
            },
            [FORM_STEP_3]: {
                idCardType: '',
                ocrInfos: {}
            },
            [FORM_STEP_4]: {
                faceImg: '',
                recogImg: '',
                isMatched: false
            },
            [FORM_STEP_5]: {
                bankCardSelected: ''
            },
            [FORM_STEP_6]: {
                selectedForm: '',
                infosReceive: {}
            },
            // [FORM_STEP_7]: {
            //     isConfirmed: false
            // },
            [FORM_STEP_8]: {
                reviewType: 0
            }
        }
    );

    useEffect(() => {
        if(!uuid) return;
        
        const wsFormStream = wsFormStreamRef.current = new WSFormStream(uuid)
        if(process.env.NODE_ENV === 'development')
            window.socketForm = wsFormStream

        wsFormStream.on((evt) => {
            // console.log(evt.data)
            const dataStep = JSON.parse(evt.data)
            const { cmd, data } = dataStep
            if(cmd === END_STEP) {
                setStepDone(pre => [...pre, PRE_STEP + stepInprogress.current])
                stepInprogress.current += 1
                return
            }

            switch(cmd) {
                case FORM_STEP_1: {
                    setDataSteps({
                        type: FORM_STEP_1,
                        payload: {
                            idCardType: data.type
                        }
                    })
                    setDataSteps({
                        type: FORM_STEP_2,
                        payload: {
                            idCardType: data.type
                        }
                    })
                    setDataSteps({
                        type: FORM_STEP_3,
                        payload: {
                            idCardType: data.type
                        }
                    })
                    break;
                }
                case FORM_STEP_2: {
                    setDataSteps({
                        type: FORM_STEP_2,
                        payload: {
                            listIdCard: typeof(data.photo) === 'object' ? 
                                Object.values(data.photo).map(photoId => apiGetImgFormStream(photoId))
                                : 
                                []
                        }
                    })
                    setDataSteps({
                        type: FORM_STEP_4,
                        payload: {
                            recogImg: apiGetImgFormStream(data.photo.front)
                        }
                    })
                    break;
                }
                case FORM_STEP_3: {
                    setDataSteps({
                        type: FORM_STEP_3,
                        payload: {
                            ocrInfos: data ?? {}
                        }
                    })
                    break;
                }
                case FORM_STEP_4: {
                    setDataSteps({
                        type: FORM_STEP_4,
                        payload: {
                            faceImg: apiGetImgFormStream(data.faceImage),
                            isMatched: data.idCardMatched
                        }
                    })
                    break;
                }
                case FORM_STEP_5: {
                    setDataSteps({
                        type: FORM_STEP_5,
                        payload: {
                            bankCardSelected: data.cardType
                        }
                    })
                    break;
                }
                case FORM_STEP_6: {
                    if(!data) break;
                    const typeReceiveData = formReceiveCardTypes[data.methodOfReceivingType]?.keyGetInfosReceive

                    setDataSteps({
                        type: FORM_STEP_6,
                        payload: {
                            selectedForm: data.methodOfReceivingType,
                            infosReceive: typeReceiveData ? data[typeReceiveData] : {}
                        }
                    })
                    break;
                }
                case FORM_STEP_7: {
                    setDataSteps({
                        type: FORM_STEP_7,
                        payload: {
                            isConfirmed: data.IsConfirmed
                        }
                    })
                    break;
                }
                case FORM_STEP_8: {
                    setDataSteps({
                        type: FORM_STEP_8,
                        payload: {
                            reviewType: data.reviewType
                        }
                    })
                    break;
                }
                default:
                    break;
            }

        })

        return () => {
            wsFormStream.disconnect()
        }
    }, [uuid])

    const inStepDone = (step) => stepDone.includes(step)
    const step1InStepDone = inStepDone(FORM_STEP_1)
    const step2InStepDone = inStepDone(FORM_STEP_2)
    const step3InStepDone = inStepDone(FORM_STEP_3)
    const step4InStepDone = inStepDone(FORM_STEP_4)
    const step5InStepDone = inStepDone(FORM_STEP_5)
    const step6InStepDone = inStepDone(FORM_STEP_6)
    const step7InStepDone = inStepDone(FORM_STEP_7)
    const step8InStepDone = inStepDone(FORM_STEP_8)

    return (
        <WrapTransactionInfos {...props}>
            <StepOne 
                status={getStepStatus(!stepDone[-1], step1InStepDone)} 
                {...dataSteps[FORM_STEP_1]} 
            />
            <StepTwo 
                status={getStepStatus(step1InStepDone, step2InStepDone)}
                {...dataSteps[FORM_STEP_2]}
            />
            <StepThree 
                status={getStepStatus(step2InStepDone, step3InStepDone)} 
                {...dataSteps[FORM_STEP_3]}
            />
            <StepFour 
                status={getStepStatus(step3InStepDone, step4InStepDone)} 
                {...dataSteps[FORM_STEP_4]}
            />
            <StepFive 
                status={getStepStatus(step4InStepDone, step5InStepDone)} 
                {...dataSteps[FORM_STEP_5]}
            />
            <StepSix 
                status={getStepStatus(step5InStepDone, step6InStepDone)} 
                {...dataSteps[FORM_STEP_6]}
            />
            <StepSeven 
                status={getStepStatus(step6InStepDone, step7InStepDone)} 
                {...dataSteps[FORM_STEP_7]}
            />
            <StepEight
                status={getStepStatus(step7InStepDone, step8InStepDone)} 
                {...dataSteps[FORM_STEP_8]}
            />
        </WrapTransactionInfos>
    )
}

export default TransactionInfos
