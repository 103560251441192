import React from 'react'

const IcReactionNeutral = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M8.99998 18C13.9705 18 18 13.9705 18 8.99998C18 4.02943 13.9705 0 8.99998 0C4.02943 0 0 4.02943 0 8.99998C0 13.9705 4.02943 18 8.99998 18Z" fill="#FFCE52" />
                <path d="M12.5216 8.21734C13.1699 8.21734 13.6955 7.51657 13.6955 6.65213C13.6955 5.78768 13.1699 5.08691 12.5216 5.08691C11.8732 5.08691 11.3477 5.78768 11.3477 6.65213C11.3477 7.51657 11.8732 8.21734 12.5216 8.21734Z" fill="#273941" />
                <path d="M5.4786 8.21734C6.12693 8.21734 6.65251 7.51657 6.65251 6.65213C6.65251 5.78768 6.12693 5.08691 5.4786 5.08691C4.83027 5.08691 4.30469 5.78768 4.30469 6.65213C4.30469 7.51657 4.83027 8.21734 5.4786 8.21734Z" fill="#273941" />
                <path d="M12.5219 7.82609C12.9541 7.82609 13.3045 7.30051 13.3045 6.65218C13.3045 6.00385 12.9541 5.47827 12.5219 5.47827C12.0896 5.47827 11.7393 6.00385 11.7393 6.65218C11.7393 7.30051 12.0896 7.82609 12.5219 7.82609Z" fill="#141E21" />
                <path d="M5.47792 7.82609C5.91014 7.82609 6.26053 7.30051 6.26053 6.65218C6.26053 6.00385 5.91014 5.47827 5.47792 5.47827C5.0457 5.47827 4.69531 6.00385 4.69531 6.65218C4.69531 7.30051 5.0457 7.82609 5.47792 7.82609Z" fill="#141E21" />
                <path d="M12.9138 6.65199C13.1299 6.65199 13.3051 6.4768 13.3051 6.26069C13.3051 6.04458 13.1299 5.86938 12.9138 5.86938C12.6977 5.86938 12.5225 6.04458 12.5225 6.26069C12.5225 6.4768 12.6977 6.65199 12.9138 6.65199Z" fill="#F6FAFD" />
                <path d="M5.86982 6.65199C6.08593 6.65199 6.26112 6.4768 6.26112 6.26069C6.26112 6.04458 6.08593 5.86938 5.86982 5.86938C5.65371 5.86938 5.47852 6.04458 5.47852 6.26069C5.47852 6.4768 5.65371 6.65199 5.86982 6.65199Z" fill="#F6FAFD" />
                <path d="M8.99997 1.17378C13.7543 1.17378 17.6086 4.5026 17.6086 8.60855H17.9902C17.8823 6.29675 16.888 4.11535 15.2138 2.51748C13.5396 0.919606 11.3143 0.0280762 8.99997 0.0280762C6.68565 0.0280762 4.46028 0.919606 2.78611 2.51748C1.11193 4.11535 0.117642 6.29675 0.00976562 8.60855H0.391287C0.391287 4.5026 4.24563 1.17378 8.99997 1.17378Z" fill="#FFE369" />
                <path d="M17.6087 8.60864C17.6087 12.7146 13.7543 16.0434 8.99998 16.0434C4.24564 16.0434 0.391304 12.7146 0.391304 8.60864H0.00978258C0.00430433 8.73856 0 8.86886 0 8.99995C3.55682e-08 11.3869 0.94821 13.6761 2.63603 15.3639C4.32386 17.0517 6.61304 17.9999 8.99998 17.9999C11.3869 17.9999 13.6761 17.0517 15.3639 15.3639C17.0518 13.6761 18 11.3869 18 8.99995C18 8.86886 17.9957 8.73856 17.9902 8.60864H17.6087Z" fill="#FFB32B" />
                <path d="M5.87012 12.1304H12.131V12.913H5.87012V12.1304Z" fill="#273941" />
                <path d="M14.4064 3.45358C14.5325 3.32753 14.4924 3.08315 14.317 2.90775C14.1416 2.73235 13.8973 2.69234 13.7712 2.81839C13.6452 2.94445 13.6852 3.18882 13.8606 3.36423C14.036 3.53963 14.2804 3.57964 14.4064 3.45358Z" fill="#F6FAFD" />
                <path d="M11.8 1.53293C11.8631 1.46983 11.8432 1.34758 11.7555 1.25987C11.6678 1.17217 11.5455 1.15223 11.4824 1.21533C11.4193 1.27844 11.4393 1.40069 11.527 1.48839C11.6147 1.57609 11.7369 1.59603 11.8 1.53293Z" fill="#F6FAFD" />
                <path d="M13.5507 2.98829C13.8028 2.73619 13.7226 2.24731 13.3717 1.89635C13.0207 1.54539 12.5318 1.46525 12.2797 1.71735C12.0276 1.96946 12.1078 2.45834 12.4587 2.8093C12.8097 3.16026 13.2985 3.24039 13.5507 2.98829Z" fill="#F6FAFD" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={18} height={18} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcReactionNeutral
