import React from 'react'

// styles
import { StepOneDetails , WrapCardItem} from './index.styles'

//component 
import StepContainer from '../StepContainer'
import Identity from '../../../../../../../assets/images/IdentityCard'
import Identity12 from '../../../../../../../assets/images/IdentityCard12'
import CitizenIdentification from '../../../../../../../assets/images/CitizenIdentification'
import Passport from '../../../../../../../assets/images/Passport'
import ActiveCardControl from '../../../../../../Control/ActiveCardControl';

// constant
import { CCCD, CMND, CMND12, PASSPORT } from '../../../../../../../assets/constant/identityCardTypes'

const IdCardTypeItem = ({ isActive, typeName, Icon }) => {
    return (
        <WrapCardItem isActive={isActive}>
            <div className="id-card-type-item">
                <ActiveCardControl activeCardExtendStyle={"active-btn"} isActive={isActive}/>
                <div className="infos">
                    <div>{typeName}</div>
                    <Icon className="images-style" />
                </div>
            </div>
        </WrapCardItem>
    )
}

const StepOne = ({ idCardType , status }) => {
    
    return (
        <StepContainer title='BƯỚC 1: CHỌN LOẠI GIẤY TỜ' status={status} animationName='showStepOneAnimation'>
            <div className='show-container'>
                <StepOneDetails>
                    <IdCardTypeItem isActive={idCardType === CMND} typeName="Chứng minh nhân dân" Icon={Identity} /> 
                    <IdCardTypeItem isActive={idCardType === CMND12} typeName="Chứng minh nhân dân 12 số" Icon={Identity12}/>
                    <IdCardTypeItem isActive={idCardType === CCCD} typeName="Căn cước công dân" Icon={CitizenIdentification}/>
                    <IdCardTypeItem isActive={idCardType === PASSPORT} typeName="Hộ chiếu" Icon={Passport}/>
                </StepOneDetails>
            </div>
        </StepContainer>
    )
}

export default StepOne
