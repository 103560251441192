import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
    .title{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 170%;
        text-transform: ${props => props.textTranForm ? props.textTranForm : 'uppercase'};
        color: rgba(25, 25, 25, 0.6);
    }
    .icon{
        cursor: pointer;
        margin-right: ${props => props.marginRight ? props.marginRight : "" };
    }
`
export { Bound }