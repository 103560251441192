import React from 'react'

const IcCycleCardMini = (props) => {
    return (
        <svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <circle opacity="0.1" cx={15} cy={15} r={15} fill="#016730" fillOpacity="0.6" />
                <path d="M22.3252 17.8359H20.8643V16.375C20.8643 16.2838 20.828 16.1964 20.7636 16.1319C20.6991 16.0675 20.6117 16.0312 20.5205 16.0312H20.3486C20.2575 16.0312 20.17 16.0675 20.1056 16.1319C20.0411 16.1964 20.0049 16.2838 20.0049 16.375V17.8359H18.5439C18.4528 17.8359 18.3653 17.8722 18.3009 17.9366C18.2364 18.0011 18.2002 18.0885 18.2002 18.1797V18.3516C18.2002 18.4427 18.2364 18.5302 18.3009 18.5946C18.3653 18.6591 18.4528 18.6953 18.5439 18.6953H20.0049V20.1562C20.0049 20.2474 20.0411 20.3349 20.1056 20.3993C20.17 20.4638 20.2575 20.5 20.3486 20.5H20.5205C20.6117 20.5 20.6991 20.4638 20.7636 20.3993C20.828 20.3349 20.8643 20.2474 20.8643 20.1562V18.6953H22.3252C22.4164 18.6953 22.5038 18.6591 22.5683 18.5946C22.6327 18.5302 22.6689 18.4427 22.6689 18.3516V18.1797C22.6689 18.0885 22.6327 18.0011 22.5683 17.9366C22.5038 17.8722 22.4164 17.8359 22.3252 17.8359Z" fill="#016730" stroke="#016730" strokeWidth="0.3" />
                <path d="M13.7314 15.8594H10.1221C9.93222 15.8594 9.77832 16.0133 9.77832 16.2031V16.375C9.77832 16.5648 9.93222 16.7188 10.1221 16.7188H13.7314C13.9213 16.7188 14.0752 16.5648 14.0752 16.375V16.2031C14.0752 16.0133 13.9213 15.8594 13.7314 15.8594Z" fill="#016730" stroke="#016730" strokeWidth="0.3" />
                <path d="M17.2549 15.8594H15.708C15.5182 15.8594 15.3643 16.0133 15.3643 16.2031V16.375C15.3643 16.5648 15.5182 16.7188 15.708 16.7188H17.2549C17.4447 16.7188 17.5986 16.5648 17.5986 16.375V16.2031C17.5986 16.0133 17.4447 15.8594 17.2549 15.8594Z" fill="#016730" stroke="#016730" strokeWidth="0.3" />
                <path d="M21.0361 9.5H8.83301C8.4001 9.50045 7.98505 9.67263 7.67894 9.97874C7.37282 10.2849 7.20065 10.6999 7.2002 11.1328V18.8672C7.20065 19.3001 7.37282 19.7151 7.67894 20.0213C7.98505 20.3274 8.4001 20.4995 8.83301 20.5H16.9111C17.0251 20.5 17.1344 20.4547 17.215 20.3741C17.2956 20.2936 17.3408 20.1843 17.3408 20.0703C17.3408 19.9564 17.2956 19.8471 17.215 19.7665C17.1344 19.6859 17.0251 19.6406 16.9111 19.6406H8.83301C8.62802 19.6402 8.43156 19.5585 8.28661 19.4136C8.14166 19.2686 8.06002 19.0722 8.05957 18.8672V13.1953H21.8096V15.043C21.8096 15.1569 21.8548 15.2662 21.9354 15.3468C22.016 15.4274 22.1253 15.4727 22.2393 15.4727C22.3532 15.4727 22.4625 15.4274 22.5431 15.3468C22.6237 15.2662 22.6689 15.1569 22.6689 15.043V11.1328C22.6685 10.6999 22.4963 10.2849 22.1902 9.97874C21.8841 9.67263 21.469 9.50045 21.0361 9.5V9.5ZM8.05957 12.3359V11.1328C8.06002 10.9278 8.14166 10.7314 8.28661 10.5864C8.43156 10.4415 8.62802 10.3598 8.83301 10.3594H21.0361C21.2411 10.3598 21.4376 10.4415 21.5825 10.5864C21.7275 10.7314 21.8091 10.9278 21.8096 11.1328V12.3359H8.05957Z" fill="#016730" stroke="#016730" strokeWidth="0.3" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={30} height={30} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcCycleCardMini
