import React from 'react'

const IcMissCallGray = (props) => {
  return (
    <svg {...props} width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.375 0.625L10.625 4.375" stroke="#7A7A7A" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.625 0.625L14.375 4.375" stroke="#7A7A7A" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.75 10.5751V12.4501C13.7507 12.6241 13.7151 12.7964 13.6453 12.9559C13.5756 13.1154 13.4733 13.2586 13.3451 13.3762C13.2168 13.4939 13.0654 13.5835 12.9005 13.6393C12.7356 13.695 12.5609 13.7157 12.3875 13.7001C10.4643 13.4911 8.61689 12.8339 6.99377 11.7813C5.48366 10.8217 4.20335 9.54142 3.24377 8.03131C2.18751 6.40081 1.53017 4.54443 1.32502 2.61256C1.3094 2.43973 1.32994 2.26554 1.38533 2.10108C1.44072 1.93662 1.52975 1.78549 1.64675 1.65732C1.76374 1.52916 1.90615 1.42675 2.06489 1.35664C2.22363 1.28652 2.39523 1.25022 2.56877 1.25006H4.44377C4.74709 1.24708 5.04114 1.35448 5.27112 1.55227C5.5011 1.75005 5.65132 2.02471 5.69377 2.32506C5.77291 2.9251 5.91968 3.51426 6.13127 4.08131C6.21536 4.30501 6.23356 4.54813 6.18371 4.78186C6.13386 5.01559 6.01806 5.23013 5.85002 5.40006L5.05627 6.19381C5.94599 7.75853 7.24155 9.05409 8.80627 9.94381L9.60002 9.15006C9.76995 8.98202 9.98449 8.86622 10.2182 8.81637C10.4519 8.76652 10.6951 8.78472 10.9188 8.86881C11.4858 9.0804 12.075 9.22717 12.675 9.30631C12.9786 9.34914 13.2559 9.50206 13.4541 9.73599C13.6523 9.96993 13.7576 10.2685 13.75 10.5751Z" stroke="#7A7A7A" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default IcMissCallGray;