import React from 'react'

// constant
import bankCards from '../../../../../../../assets/constant/bankCards'

// component
import StepContainer from '../StepContainer'
import ActiveCardControl from '../../../../../../Control/ActiveCardControl'

// styles
import { StepFiveDetails } from './index.styles'


const StepFive = ({ status, bankCardSelected }) => {
    return (
        <StepContainer title='BƯỚC 5: CHỌN THẺ' status={status} animationName='showStepFiveAnimation' >
            <div className='show-container'>
                <StepFiveDetails>
                    {Object.values(bankCards).map((item) => (
                        <div className={`wrap-card ${bankCardSelected === item.type && 'selected'}`} key={item.type}>
                            <item.Icon className="icon" />
                            <div className="name">
                                <ActiveCardControl  
                                    activeCardExtendStyle="radio-btn"
                                    isActive={bankCardSelected === item.type}
                                />
                                <div>{item.nameVi}</div>
                            </div>
                        </div>
                    ))}
                </StepFiveDetails>
            </div>
        </StepContainer>
    )
}

export default StepFive
