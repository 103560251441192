import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";
//constant
import { data } from '../../../assets/constant/dashboard'
//styles
import * as styles from './index.styles'
import WrapperPage from '../../CustomStyles/PageWrapper'
//images
import IcArrowGreen from '../../../assets/images/IcArrowGreen'
//component
import BreadCrumbControl from '../../Control/BreadCrumbControl'
import { getDashBoard } from '../../../redux/dashboard/action';

export default function HomePage() {

    const dispatch = useDispatch()
    const [dataDashBoard, setDataDashBoard] = useState({
        customerSystem: {
            count: 0,
            cardAuto: 0,
            cardSupport: 0
        },
        customerDaily: {
            count: 0,
            cardAuto: 0,
            cardSupport: 0
        },
        callDaily: {
            count: 0,
            received: 0,
            denied: 0
        },}
    )

    const { uuid, dashboard, system } = useSelector(state => ({
        uuid: state.loginReducer.dataLogin.uuid,
        dashboard: state.dashboardReducer.dashboard,
        system: state.systemReducer
    }))

    useEffect(() => {
        dispatch(getDashBoard({ uuid }))
    }, [])

    useEffect(() => {
        if(!system) return
        if(system.type === 'CLOSE_POPUP_VIDEO_CALL')
            dispatch(getDashBoard({ uuid }))
        return () => {
            
        }
    }, [system])

    useEffect(() => {
        if(!dashboard) return
        setDataDashBoard({
            customerSystem: {
                count: dashboard?.sysStatistics?.total || 0,
                cardAuto: dashboard?.sysStatistics?.autoTrans || 0,
                cardSupport: dashboard?.sysStatistics?.videoCallTrans || 0
            },
            customerDaily: {
                count: dashboard?.statisticsToday?.total || 0,
                cardAuto: dashboard?.statisticsToday?.autoTrans || 0,
                cardSupport: dashboard?.statisticsToday?.videoCallTrans || 0
            },
            callDaily: {
                count: dashboard?.statisticsToday?.videoCallTrans || 0,
                received: dashboard?.statisticsToday?.videoCallStatistics?.accept || 0,
                denied: dashboard?.statisticsToday?.videoCallStatistics?.reject || 0
            },
        })
    }, [JSON.stringify(dashboard)])

    return (
        <WrapperPage>
            <styles.Bound>
                <div className="title-container">
                    <BreadCrumbControl title="dashboard" />
                </div>
                {
                    data.map((item, i) => {
                        let objectChoice = {}
                        switch (item.key) {
                            case 'customer-system':
                                objectChoice = dataDashBoard.customerSystem
                                break;
                            case 'customer-daily':
                                objectChoice = dataDashBoard.customerDaily
                                break;
                            case 'call-daily':
                                objectChoice = dataDashBoard.callDaily
                                break;

                            default:
                                break;
                        }
                        return (
                            <div className="wrap-list" key={i} >
                                <div className="customer-line"
                                    style={
                                        item.key === 'customer-system' ? { background: '#E6F0EA' } : {}
                                    }>
                                    <div className='icon-container'>
                                        {item.icon}
                                    </div>
                                    <div className="left-col">
                                        <p className="number">{objectChoice.count}</p>
                                        <p className="customer">{item.title}</p>
                                    </div>
                                    <div className="childs-col">
                                        {
                                            item.childs.map((child, j) => {
                                                let childCount = 0
                                                switch (child.key) {
                                                    case 'card-auto':
                                                        childCount = objectChoice.cardAuto
                                                        break;
                                                    case 'card-support':
                                                        childCount = objectChoice.cardSupport
                                                        break;
                                                    case 'call-received':
                                                        childCount = objectChoice.received
                                                        break;
                                                    case 'call-denied':
                                                        childCount = objectChoice.denied
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                return (
                                                    <div className="child-item-content" key={j}>
                                                        { child.icon}
                                                        <p><span className="green-number">{childCount}</span>{child.title}</p>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </div>
                                    <div className="right-col">
                                        <NavLink to={item.navigation.href} >
                                            <IcArrowGreen className="arrow" />
                                            <p className="green-number">{item.navigation.title}</p>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </styles.Bound>
        </WrapperPage>
    )
}
