import React from 'react'

// styles
import { WrapStep } from './index.styles'
//image
import IcArrowDownBlack from '../../../../../../../../assets/images/IcArrowDownBlack'
import IcCheckWhite from '../../../../../../../../assets/images/IcCheckWhite'
import IcEyeWhite from '../../../../../../../../assets/images/IcEyeWhite'

const PASSED = 'PASSED'
const INPROGRESS = 'INPROGRESS'

const stepStatusTypes = {
    [PASSED]: PASSED,
    [INPROGRESS]: INPROGRESS,
}

const stepStatus = (status) => {
    if(status === PASSED)
        return 'status-done'
    if(status === INPROGRESS)
        return 'status-view'
    return ''
}

const titleClass = (status) => {
    if(status === PASSED)
        return 'title-done'
    if(status === INPROGRESS)
        return 'title-inprogress'
    return ''
}

const arrowClass = (status) => {
    if(Object.values(stepStatusTypes).includes(status))
        return 'step-active'
    return ''
}

const Step = ({ status, title, isHideArrow }) => {
    return (
        <WrapStep status={status}>
            <div className={`step-status ${stepStatus(status)}`}>
                <div className='status-bound'>
                    {
                        status === INPROGRESS ?
                        <IcEyeWhite />
                        :
                        <IcCheckWhite />
                    }
                </div>
            </div>
            <div className={`step-title ${titleClass(status)}`}>{title}</div>
            {
                !isHideArrow ?
                <div className={`step-arrow ${arrowClass(status)}`}>
                    <IcArrowDownBlack />
                </div>
                :
                status===stepStatusTypes['PASSED'] &&
                <div className='status-confirmed'>
                    <p>ĐÃ xác nhận</p>
                </div>
            }
            
        </WrapStep>
    )
}

export default Step
