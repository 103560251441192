import React from 'react'
//styles
import * as styles from './index.styles'
//component
import Header from './Header'
import LoginPage from './PageLogin'

export default function Login() {
    return (
        <styles.Bound>
            <Header />
            <LoginPage />
        </styles.Bound>
    )
}
