import React from 'react'

const AvatarDefault = (props) => {
  return (
    <svg {...props} width={65} height={65} viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.0556 0.00483963C15.1094 -0.301764 0.311508 13.9982 0.00490387 31.9444C-0.169247 42.1199 4.36604 51.2653 11.5872 57.3471C12.0593 56.935 12.5658 56.5573 13.1288 56.2507L22.826 50.9612C24.0978 50.267 24.8901 48.9339 24.8901 47.4843V43.5107C24.8901 43.5107 22.0436 40.1062 20.9582 35.3759C20.058 34.7933 19.4546 33.7864 19.4546 32.6397V28.2909C19.4546 27.3343 19.8802 26.4795 20.5412 25.881V19.5944C20.5412 19.5944 19.2498 9.81126 32.4988 9.81126C45.7477 9.81126 44.4563 19.5944 44.4563 19.5944V25.881C45.1186 26.4795 45.5429 27.3343 45.5429 28.2909V32.6397C45.5429 34.1016 44.5618 35.3317 43.2299 35.7426C42.4879 38.0495 41.4173 40.2484 40.002 42.2475C39.6451 42.7515 39.3115 43.1783 39.0196 43.5107V47.5848C39.0196 49.0847 39.8671 50.4571 41.2088 51.1267L51.5928 56.3181C52.2159 56.6297 52.7788 57.0233 53.2976 57.4612C60.3004 51.621 64.8246 42.8889 64.9927 33.0555C65.3017 15.1094 51.0029 0.311443 33.0556 0.00483963Z" fill="#F1F1F1" />
      <path d="M22.8272 50.9599L13.1299 56.2495C12.5609 56.5598 12.0495 56.9412 11.5736 57.3569C17.2274 62.124 24.5258 64.9999 32.4999 64.9999C40.4152 64.9999 47.667 62.1669 53.3048 57.4649C52.7848 57.0258 52.2207 56.6309 51.5952 56.3194L41.2112 51.128C39.8695 50.4571 39.022 49.086 39.022 47.5861V43.5119C39.3139 43.1796 39.6475 42.7528 40.0044 42.2487C41.4196 40.2497 42.4903 38.0507 43.2323 35.7438C44.5642 35.333 45.5453 34.1029 45.5453 32.641V28.2921C45.5453 27.3355 45.1197 26.4807 44.4587 25.8822V19.5956C44.4587 19.5956 45.7501 9.8125 32.5012 9.8125C19.2522 9.8125 20.5436 19.5956 20.5436 19.5956V25.8822C19.8813 26.4807 19.457 27.3355 19.457 28.2921V32.641C19.457 33.7865 20.0592 34.7946 20.9606 35.3771C22.0472 40.1074 24.8925 43.5119 24.8925 43.5119V47.4855C24.8913 48.9327 24.099 50.2658 22.8272 50.9599Z" fill="#E4E4E4" />
    </svg>

  )
}
export default AvatarDefault;

