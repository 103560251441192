import React from 'react'
import { NavLink } from "react-router-dom";
import { Bound } from './index.styles'

//data 
import data from '../../../assets/constant/menu'
import LogoVPBankShortStroke from '../../../assets/images/LogoVPBankShortStroke';
import LogoVPBankFullStroke from '../../../assets/images/LogoVPBankFullStroke'

function SideMenu() {

    const renderListMenu = () => {
        return data.map((item, i) => {
            return (
                <NavLink
                    to={`${item.path}`}
                    className="menu-item"
                    activeClassName="active-link"
                    key={i}
                >
                    <div className='menu-logo'>
                        {item.logo}
                    </div>
                    <p>{item.title}</p>
                    <div className='active-bound' />
                    <div className='line-active' />
                </NavLink>
            )
        })
    }

    return (
        <Bound>
            <div className='side-menu-header'>
                <LogoVPBankShortStroke id='short-logo' />
                <LogoVPBankFullStroke id='long-logo' />
            </div>
            <div className='side-menu'>
                {renderListMenu()}
            </div>
        </Bound>
    )
}

export default React.memo(SideMenu)
