import React from 'react'

const IcGender = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.79076 12.8899C8.0747 12.8899 8.3537 12.8626 8.62574 12.8109C8.03522 12.3321 7.55009 11.7191 7.22115 11.0038C7.12041 10.9811 7.02083 10.953 6.92241 10.9182C5.57763 10.4448 4.87272 8.97666 5.35146 7.64553C5.71843 6.62664 6.69886 5.94183 7.79105 5.94183C8.08573 5.94183 8.3778 5.9921 8.65912 6.0912C10.0042 6.56517 10.7091 8.03303 10.2301 9.36388C10.1938 9.4647 10.1514 9.56179 10.1038 9.65601C10.1046 9.65831 10.1055 9.66089 10.1067 9.66348C10.372 10.2687 10.9315 10.6832 11.5777 10.7803C11.7322 10.529 11.8626 10.2598 11.965 9.97572C12.7843 7.6981 11.5783 5.18607 9.27693 4.37515C8.79703 4.20625 8.2968 4.12036 7.79105 4.12036C5.92166 4.12036 4.24417 5.29178 3.61678 7.03483C2.88865 9.05853 3.75991 11.2678 5.57821 12.3036L5.03501 13.8131L4.85037 13.4276C4.66136 13.0344 4.1861 12.8666 3.78836 13.0534C3.39062 13.2401 3.22136 13.7109 3.41036 14.1041L4.44652 16.2625C4.58298 16.5469 4.86866 16.713 5.16682 16.713C5.2815 16.713 5.39763 16.6885 5.50824 16.6365L7.58086 15.6625C7.9786 15.4758 8.14786 15.0049 7.95915 14.612C7.77044 14.2182 7.29518 14.0507 6.89744 14.238L6.50811 14.421L7.07976 12.8322C7.31434 12.8704 7.55241 12.8899 7.79076 12.8899Z" fill="#7A7A7A" />
            <path d="M16.6025 13.522C16.4338 13.13 15.9823 12.9508 15.5932 13.1198L14.7478 13.4888L14.4216 12.7314C15.1389 12.2506 15.6948 11.5648 16.0187 10.7415C16.4383 9.67404 16.4201 8.50573 15.9664 7.45233C15.2894 5.87787 13.7508 4.86108 12.0469 4.86108C11.7201 4.86108 11.4706 4.88615 11.1553 4.96023C11.7747 5.35708 12.2217 5.90519 12.5325 6.70002C13.3499 6.84451 14.0001 7.37825 14.3377 8.16239C14.6024 8.77809 14.613 9.46054 14.3682 10.0847C14.1229 10.7086 13.6515 11.1992 13.0394 11.4657C12.723 11.6031 12.3904 11.673 12.05 11.673C11.9017 11.673 11.7554 11.6597 11.613 11.6341C10.7973 11.4885 10.096 10.9415 9.75914 10.1585C9.50429 9.56588 9.48499 8.91159 9.70207 8.30716C9.71046 8.2835 9.71942 8.25984 9.72865 8.2359C9.77732 8.11253 9.83691 7.99621 9.90237 7.88383C9.6078 7.37994 9.15209 6.99886 8.5982 6.80029C8.56547 6.78875 8.5319 6.78339 8.49917 6.77354C8.33356 7.02393 8.19117 7.29235 8.07899 7.57851C8.07312 7.59344 8.0678 7.60809 8.06193 7.62273C7.66022 8.67866 7.68427 9.82895 8.13103 10.868C8.63876 12.0484 9.63102 12.9156 10.8138 13.2753C11.2082 13.3953 11.6239 13.4589 12.0502 13.4589C12.3762 13.4589 12.697 13.4178 13.0115 13.344L13.3382 14.1025L12.4925 14.4718C12.1034 14.6413 11.9246 15.0965 12.093 15.4883C12.2181 15.7804 12.5012 15.9542 12.7983 15.9542C12.9001 15.9542 13.0033 15.9339 13.1029 15.8902L13.948 15.5215L14.2605 16.2468C14.3856 16.5386 14.6681 16.7129 14.9655 16.7129C15.067 16.7129 15.1708 16.6924 15.2701 16.649C15.6595 16.4792 15.8383 16.024 15.6699 15.6325L15.3574 14.9069L16.2033 14.5383C16.5927 14.369 16.7715 13.9141 16.6025 13.522Z" fill="#7A7A7A" />
        </svg>
    )
}
export default IcGender
