import Axios from 'axios'
//host
import { host } from '../../../assets/constant/host'
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
//help
import mapErrors from '../../../helper/mapErrors'
//types
import * as types from './types'

const apiGetListCallHistory = `${host}/counter/getVideoCallHistory`

export const getListCallHistory = ({ uuid, isDesc, pageSize, pageNumber, }, callbackSuccess) => async dispatch => {
    const condFilters = {
        uuid,
        isDesc,
        pageSize,
        pageNumber,
    }
    const dataRequest = JSON.stringify(condFilters)
    try {
        const res = await Axios.post(apiGetListCallHistory, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_CALL_HISTORY,
                payload: {
                    result: data.result || [],
                    total: data.total || 0,
                    condFilters
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_CALL_HISTORY_ERR,
                payload: {
                    ...data
                }
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_CALL_HISTORY_ERR))
    }
}

export const clearListCallHistory = () => ({
    type: types.CLEAR_LIST_CALL_HISTORY
})

