import React from 'react'

const IcCancel = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.8515 6.99996L11.0832 10.2316V11.0833H10.2315L6.99984 7.85163L3.76817 11.0833H2.9165V10.2316L6.14817 6.99996L2.9165 3.76829V2.91663H3.76817L6.99984 6.14829L10.2315 2.91663H11.0832V3.76829L7.8515 6.99996Z" fill="#191919" fillOpacity="0.6" />
        </svg>
    )
}

export default IcCancel
