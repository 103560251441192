import React from 'react'

const IcCardMini = (props) => {
    return (
        <svg {...props} width={16} height={12} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8713 8.83594H13.4509V7.375C13.4509 7.28383 13.4157 7.1964 13.353 7.13193C13.2903 7.06747 13.2053 7.03125 13.1167 7.03125H12.9496C12.861 7.03125 12.776 7.06747 12.7133 7.13193C12.6506 7.1964 12.6154 7.28383 12.6154 7.375V8.83594H11.195C11.1064 8.83594 11.0214 8.87215 10.9587 8.93662C10.896 9.00108 10.8608 9.08852 10.8608 9.17969V9.35156C10.8608 9.44273 10.896 9.53017 10.9587 9.59463C11.0214 9.6591 11.1064 9.69531 11.195 9.69531H12.6154V11.1562C12.6154 11.2474 12.6506 11.3349 12.7133 11.3993C12.776 11.4638 12.861 11.5 12.9496 11.5H13.1167C13.2053 11.5 13.2903 11.4638 13.353 11.3993C13.4157 11.3349 13.4509 11.2474 13.4509 11.1562V9.69531H14.8713C14.9599 9.69531 15.0449 9.6591 15.1076 9.59463C15.1702 9.53017 15.2055 9.44273 15.2055 9.35156V9.17969C15.2055 9.08852 15.1702 9.00108 15.1076 8.93662C15.0449 8.87215 14.9599 8.83594 14.8713 8.83594Z" fill="#7A7A7A" stroke="#7A7A7A" strokeWidth="0.3" />
            <path d="M6.51641 6.85938H3.0073C2.82272 6.85938 2.6731 7.01328 2.6731 7.20312V7.375C2.6731 7.56485 2.82272 7.71875 3.0073 7.71875H6.51641C6.70099 7.71875 6.85061 7.56485 6.85061 7.375V7.20312C6.85061 7.01328 6.70099 6.85938 6.51641 6.85938Z" fill="#7A7A7A" stroke="#7A7A7A" strokeWidth="0.3" />
            <path d="M9.94187 6.85938H8.43796C8.25339 6.85938 8.10376 7.01328 8.10376 7.20312V7.375C8.10376 7.56485 8.25339 7.71875 8.43796 7.71875H9.94187C10.1264 7.71875 10.2761 7.56485 10.2761 7.375V7.20312C10.2761 7.01328 10.1264 6.85938 9.94187 6.85938Z" fill="#7A7A7A" stroke="#7A7A7A" strokeWidth="0.3" />
            <path d="M13.6181 0.5H1.75396C1.33308 0.500455 0.929558 0.672629 0.631948 0.978742C0.334338 1.28486 0.166946 1.6999 0.166504 2.13281L0.166504 9.86719C0.166946 10.3001 0.334338 10.7151 0.631948 11.0213C0.929558 11.3274 1.33308 11.4995 1.75396 11.5H9.60769C9.71849 11.5 9.82474 11.4547 9.90309 11.3741C9.98143 11.2936 10.0254 11.1843 10.0254 11.0703C10.0254 10.9564 9.98143 10.8471 9.90309 10.7665C9.82474 10.6859 9.71849 10.6406 9.60769 10.6406H1.75396C1.55467 10.6402 1.36366 10.5585 1.22274 10.4136C1.08181 10.2686 1.00245 10.0722 1.00201 9.86719V4.19531H14.3701V6.04297C14.3701 6.15693 14.4141 6.26622 14.4924 6.3468C14.5708 6.42739 14.677 6.47266 14.7878 6.47266C14.8986 6.47266 15.0049 6.42739 15.0832 6.3468C15.1616 6.26622 15.2056 6.15693 15.2056 6.04297V2.13281C15.2051 1.6999 15.0377 1.28486 14.7401 0.978742C14.4425 0.672629 14.039 0.500455 13.6181 0.5V0.5ZM1.00201 3.33594V2.13281C1.00245 1.92782 1.08181 1.73136 1.22274 1.58641C1.36366 1.44146 1.55467 1.35983 1.75396 1.35938H13.6181C13.8174 1.35983 14.0084 1.44146 14.1493 1.58641C14.2903 1.73136 14.3696 1.92782 14.3701 2.13281V3.33594H1.00201Z" fill="#7A7A7A" stroke="#7A7A7A" strokeWidth="0.3" />
        </svg>
    )
}

export default IcCardMini
