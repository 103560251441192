import React, { useState, useEffect } from 'react'
import { CCCD, CMND, CMND12, PASSPORT } from '../../../../../../../assets/constant/identityCardTypes'

// container
import StepContainer from '../StepContainer'

// styles
import { StepThreeDetails } from './index.styles'

const genders = {
    '1': 'Nam',
    '2': 'Nữ',
    '3': 'Khác'
}

const CMNDWrap = ({ ocrInfos }) => {
    const {
        fullName,
        dateOfBirth,
        gender,
        idCardNumber,
        issuedDate,
        issuedPlace,
        hometown,
        permanentAddress,
        email,
        phoneNumber
    } = ocrInfos

    return (
        <div className="wrap-page">
            <div className='wrap-content'>
                <div className="title">Họ và tên: </div>
                <div className="detail">{fullName || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày sinh:</div>
                <div className="detail">{dateOfBirth || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Giới tính: </div>
                <div className="detail">{genders[gender] || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số CMND: </div>
                <div className="detail">{idCardNumber || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày cấp: </div>
                <div className="detail">{issuedDate || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Nơi cấp: </div>
                <div className="detail">{issuedPlace || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Nguyên quán: </div>
                <div className="detail-address" title={hometown}>{hometown || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Địa chỉ: </div>
                <div className="detail-address" title={permanentAddress}>{permanentAddress || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Email: </div>
                <div className="detail">{email || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số điện thoại: </div>
                <div className="detail">{phoneNumber || '-'}</div>
            </div>
        </div>
    )
}

const CCCDWrap = ({ ocrInfos }) => {
    const {
        fullName,
        dateOfBirth,
        gender,
        idCardNumber,
        issuedDate,
        issuedPlace,
        hometown,
        permanentAddress,
        expiredDate,
        email,
        phoneNumber
    } = ocrInfos

    return (
        <div className="wrap-page">
            <div className='wrap-content'>
                <div className="title">Họ và tên: </div>
                <div className="detail">{fullName || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày sinh:</div>
                <div className="detail">{dateOfBirth || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Giới tính: </div>
                <div className="detail">{genders[gender] || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số CCCD: </div>
                <div className="detail">{idCardNumber || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày cấp: </div>
                <div className="detail">{issuedDate || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Nơi cấp: </div>
                <div className="detail">{issuedPlace || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Nguyên quán: </div>
                <div className="detail-address" title={hometown}>{hometown || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Địa chỉ: </div>
                <div className="detail-address" title={permanentAddress}>{permanentAddress || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày hết hạn: </div>
                <div className="detail">{expiredDate || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Email: </div>
                <div className="detail">{email || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số điện thoại: </div>
                <div className="detail">{phoneNumber || '-'}</div>
            </div>
        </div>
    )
}
const HCWrap = ({ ocrInfos }) => {

    const {
        fullName,
        dateOfBirth,
        gender,
        idCardNumber,
        passportNumber,
        passportCode,
        passportType,
        nationality,
        expiredDate,
        email,
        phoneNumber,
    } = ocrInfos

    return (
        <div className="wrap-page">
            <div className='wrap-content'>
                <div className="title">Họ và tên:</div>
                <div className="detail">{fullName || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày sinh:</div>
                <div className="detail">{dateOfBirth || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Giới tính:</div>
                <div className="detail">{genders[gender] || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số hộ chiếu:</div>
                <div className="detail">{passportNumber || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Loại: </div>
                <div className="detail">{passportType ||'-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Code:</div>
                <div className="detail">{passportCode || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số CMND:</div>
                <div className="detail">{idCardNumber || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Ngày hết hạn: </div>
                <div className="detail">{expiredDate || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Quốc tịch: </div>
                <div className="detail">{nationality || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Email: </div>
                <div className="detail">{email || '-'}</div>
            </div>
            <div className='wrap-content'>
                <div className="title">Số điện thoại: </div>
                <div className="detail">{phoneNumber || '-'}</div>
            </div>
        </div>
    )
}

const StepThree = ({ idCardType, status, ocrInfos }) => {

    const [detailCard, setDetailCard] = useState(null);

    useEffect(() => {
        switch(idCardType) {
            case CMND:
            case CMND12:
                setDetailCard(<CMNDWrap ocrInfos={ocrInfos} />)
                break;
            case CCCD:
                setDetailCard(<CCCDWrap ocrInfos={ocrInfos} />)
                break;
            case PASSPORT:
                setDetailCard(<HCWrap ocrInfos={ocrInfos} />)
                break;
            default:
                break;
        }
    }, [idCardType, JSON.stringify(ocrInfos)]);

    return (
        <StepContainer title='BƯỚC 3: THÔNG TIN OCR VÀ NHẬP THÊM' status={status} animationName='showStepThreeAnimation' >
            <div className='show-container'>
                <StepThreeDetails>
                    {detailCard}
                </StepThreeDetails>
            </div>
        </StepContainer>
    )
}

export default StepThree
