import React from 'react'

const IcIDCard = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1668 9.16658H15.0002C15.2212 9.16658 15.4331 9.07879 15.5894 8.92251C15.7457 8.76623 15.8335 8.55427 15.8335 8.33325V7.49992C15.8335 7.2789 15.7457 7.06694 15.5894 6.91066C15.4331 6.75438 15.2212 6.66658 15.0002 6.66658H14.1668C13.9458 6.66658 13.7339 6.75438 13.5776 6.91066C13.4213 7.06694 13.3335 7.2789 13.3335 7.49992V8.33325C13.3335 8.55427 13.4213 8.76623 13.5776 8.92251C13.7339 9.07879 13.9458 9.16658 14.1668 9.16658ZM5.00016 9.99992H9.16683C9.38784 9.99992 9.5998 9.91212 9.75608 9.75584C9.91236 9.59956 10.0002 9.3876 10.0002 9.16658C10.0002 8.94557 9.91236 8.73361 9.75608 8.57733C9.5998 8.42105 9.38784 8.33325 9.16683 8.33325H5.00016C4.77915 8.33325 4.56719 8.42105 4.41091 8.57733C4.25463 8.73361 4.16683 8.94557 4.16683 9.16658C4.16683 9.3876 4.25463 9.59956 4.41091 9.75584C4.56719 9.91212 4.77915 9.99992 5.00016 9.99992ZM18.3335 3.33325H1.66683C1.44582 3.33325 1.23385 3.42105 1.07757 3.57733C0.921293 3.73361 0.833496 3.94557 0.833496 4.16659V15.8333C0.833496 16.0543 0.921293 16.2662 1.07757 16.4225C1.23385 16.5788 1.44582 16.6666 1.66683 16.6666H18.3335C18.5545 16.6666 18.7665 16.5788 18.9228 16.4225C19.079 16.2662 19.1668 16.0543 19.1668 15.8333V4.16659C19.1668 3.94557 19.079 3.73361 18.9228 3.57733C18.7665 3.42105 18.5545 3.33325 18.3335 3.33325ZM17.5002 14.9999H2.50016V4.99992H17.5002V14.9999ZM5.00016 13.3333H9.16683C9.38784 13.3333 9.5998 13.2455 9.75608 13.0892C9.91236 12.9329 10.0002 12.7209 10.0002 12.4999C10.0002 12.2789 9.91236 12.0669 9.75608 11.9107C9.5998 11.7544 9.38784 11.6666 9.16683 11.6666H5.00016C4.77915 11.6666 4.56719 11.7544 4.41091 11.9107C4.25463 12.0669 4.16683 12.2789 4.16683 12.4999C4.16683 12.7209 4.25463 12.9329 4.41091 13.0892C4.56719 13.2455 4.77915 13.3333 5.00016 13.3333Z" fill="#016730" />
        </svg>
    )
}
export default IcIDCard
