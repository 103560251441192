import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepFourDetails = styled(StepDetailsStyles)`
    height: calc(100% - 32px);
    @keyframes showStepFourAnimation{
        from{ height: 0; }
        to{ height: 314px; };
    }
    padding: 16px 0;
    width: 100% ;
    font-family: "SVN-Gilroy";
    color: #191919;
    .title {
        padding-left: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 170%;
        display: flex;
        align-items: center;
        letter-spacing: 0.05px;
        margin-bottom: 10px;
    }
    .wrap-content  {
        display: flex;
    }
    .wrap-images {
        height: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
            .type-of-image {
                margin-top: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 128%;
                color: #7A7A7A;
            }
            .images {
                display: flex;
                align-items: center;
                position: relative;
                width: 200px;
                height: 200px;
                margin: 0 36px 0 44px;
                .avatar-current {
                    border-radius: 50%;
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: contain;
                }
            }
            /* .images-avatar {
                margin: 0;
                width: 200px;
                height: 200px;
                .avatar-from-origin {
                    box-sizing: border-box;
                    ${
                        props => 
                            props.haveResultRecog?
                                props.isMatched ?
                                `border: 3px solid #016730;
                                padding: 3px;`
                                :
                                `border: 3px solid #016730;
                                padding: 3px;`
                            :
                            ''
                    }
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            } */


    }
    .wrap-id-card-img {
        display: flex;
        flex-direction: column;
        align-items: center;

        .wrap-center {
            flex-grow: 1;
            display: flex;
            align-items: center;

            .img-id-card {
                width: 238px;
                height: 150px;
                object-fit: contain;
                background: gray;
                border-radius: 10px;
            }
        }


        .title-id-card {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 128%;
            color: #7A7A7A;
        }
    }
    .wrap-result {
        margin-left: 76px;
        display: flex;
        align-items: center;
        .result {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
            text-transform: uppercase;
        }
        .status {
            margin-left: 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #016730;

            &.reject {
                color: #EC1E25;
            }
        }
    }
`;

export {
    StepFourDetails
}