import React, { useEffect } from 'react'
import moment from 'moment'
//styles
import * as styles from './index.styles'
//images
import IcCallBgrRed from '../../../../assets/images/IcCallBgrRed';
import IcTransactionGray from '../../../../assets/images/IcTransactionGray'
import IcDenyCallGray from '../../../../assets/images/IcDenyCallGray'
import IcIncomingCallWhite from '../../../../assets/images/IcIncomingCallWhite';
//control
import ButtonControl from '../../../Control/ButtonControl';
//media
import ringtone from '../../../../assets/media/ringtone.wav'


let audio = new Audio(ringtone)
let endCall = true

const PopupNotifyIncomingCall = ({ setShowDetailCall, setIncomingCall, socketRTC, setPhoneInfos }) => {

    useEffect(() => {
        setTimeout(() => {
            endCall = false
            audio.play()
        }, 300);

        audio.addEventListener('ended', handleAudio)
        return () => {
            audio.removeEventListener('ended', handleAudio)
            if(!endCall && audio){
                endCall = true
                audio.pause()
            }
        }
    }, [])

    useEffect(() => {
        if(process.env.NODE_ENV !== 'development') return;
        setTimeout(() => {
            handlePickupPhone()
        }, 3000)
    }, []);

    const handleAudio = () => {
        setTimeout(() => {
            if(!endCall) audio.play()
        }, 1500);
    }

    const handlePickupPhone = () => {
        setShowDetailCall(true)
        setIncomingCall(false)
        setPhoneInfos(pre => ({
            ...pre,
            pickupTime: moment()
        }))
    }

    return (
        <styles.Bound>
            <div className='container'>
                <div className="wrap-content">
                    <IcCallBgrRed className="icon-style"/>
                    <div>
                        <div className="title-call">Cuộc gọi đến từ</div>
                        <div className="content-call">KIOSK 001 - PGD Tân Bình</div>
                        <div className="content-call-1"><IcTransactionGray className="icon-transaction"/>Giao dịch mở thẻ với hỗ trợ</div>
                    </div>
                </div>
                
                <div className="wrap-btn-answer">   
                    <ButtonControl 
                        icon={<IcDenyCallGray className="icon-miss-call"/>}
                        children={'từ chối cuộc gọi'}
                        onClick={() => {
                            socketRTC.current.disconnect()
                            setIncomingCall(false)
                        }}
                    />
                    <ButtonControl 
                        icon={<IcIncomingCallWhite className="icon-call"/>}
                        children={'tiếp nhận cuộc gọi'}
                        onClick={handlePickupPhone}
                    />
                </div>
            </div>
        </styles.Bound>
    )
}

export default PopupNotifyIncomingCall
