import Axios from 'axios'
//host
import { host } from '../../../assets/constant/host'
//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors';
//types
import * as types from './types'

const apiGetDetailTransReducer = `${host}/counter/getTransDetails`

export const getDetailTransReducer = ({ uuid, transId }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        uuid,
        transId,
    })
    try {
        const res = await Axios.post(apiGetDetailTransReducer, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DETAIL_TRANSACTION,
                payload: {
                    details: {
                        ...data.result
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_DETAIL_TRANSACTION_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_TRANSACTION_ERR))
    }
}

export const clearDetailsTransaction = () => async dispatch =>  {
    dispatch({
        type: types.CLEAR_DETAIL_TRANSACTION,
    })
}