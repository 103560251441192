import React from 'react'
//style
import * as styles from './index.styles'
//component
import TitleDropDownFilter from '../../../Control/TitleDropdownFilter'


const DropDownFilterCustomerInfo = () => {
    return (
        <styles.Bound>
            <TitleDropDownFilter children="thông tin khách hàng" />
        </styles.Bound>
    )
}

export default DropDownFilterCustomerInfo
