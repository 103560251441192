import React from 'react'

// container
import StepContainer from '../StepContainer'

// styles
import { StepSevenDetails } from './index.styles'


const StepSeven = ({ status }) => {
    return (
        <StepContainer title='BƯỚC 7: XÁC NHẬN THÔNG TIN' status={status} isHideArrow={true} >
            <StepSevenDetails>
                
            </StepSevenDetails>
        </StepContainer>
    )
}

export default StepSeven
