import React from 'react'

const IcTellerGray = (props) => {
  return (
    <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#IcTellerGray)">
        <path d="M4.81641 7.36963H3.60073C3.8061 4.57031 6.14919 2.35547 9 2.35547C11.2559 2.35547 13.2972 3.77635 14.0795 5.89119C14.1974 6.20985 14.5513 6.37261 14.87 6.25476C15.1887 6.13685 15.3515 5.78297 15.2336 5.46428C14.7673 4.20379 13.9389 3.12516 12.838 2.34498C11.7119 1.54687 10.3847 1.125 9 1.125C7.2252 1.125 5.55659 1.81615 4.30161 3.07114C3.06299 4.30976 2.37408 5.95133 2.3563 7.70022C1.62103 8.12627 1.125 8.92134 1.125 9.83057V11.9224C1.125 13.2793 2.22898 14.3833 3.58594 14.3833H4.81641C5.1562 14.3833 5.43164 14.1079 5.43164 13.7681V7.98486C5.43164 7.64507 5.1562 7.36963 4.81641 7.36963ZM4.20117 13.1528H3.58594C2.90746 13.1528 2.35547 12.6008 2.35547 11.9224V9.83057C2.35547 9.15209 2.90746 8.6001 3.58594 8.6001H4.20117V13.1528Z" fill="#7A7A7A" />
        <path d="M14.4141 7.36963H13.1836C12.8438 7.36963 12.5684 7.64507 12.5684 7.98486V12.1992V13.7681V14.4141C12.5684 14.7533 12.2924 15.0293 11.9531 15.0293H10.0767V15.0488C9.8668 14.6703 9.46339 14.4141 9 14.4141C8.32044 14.4141 7.76953 14.965 7.76953 15.6445C7.76953 16.3241 8.32044 16.875 9 16.875C9.46339 16.875 9.8668 16.6187 10.0767 16.2403V16.2598H11.9531C12.9708 16.2598 13.7988 15.4318 13.7988 14.4141V14.3833H14.4141C15.771 14.3833 16.875 13.2793 16.875 11.9224V9.83057C16.875 8.47361 15.771 7.36963 14.4141 7.36963ZM15.6445 11.9224C15.6445 12.6008 15.0925 13.1528 14.4141 13.1528H13.7988V12.1992V8.6001H14.4141C15.0925 8.6001 15.6445 9.15209 15.6445 9.83057V11.9224Z" fill="#7A7A7A" />
      </g>
      <defs>
        <clipPath id="IcTellerGray">
          <rect width="15.75" height="15.75" fill="white" transform="translate(1.125 1.125)" />
        </clipPath>
      </defs>
    </svg>


  )
}

export default IcTellerGray;