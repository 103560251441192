import React from 'react'

//style
import * as styles from './index.style'
import IcNoData from '../../../assets/images/IcNoData'
// import * as styles from './index.style'


const EmptyDataControl = (props) => {
    const { labelDes } = props
    return (
        <styles.Bound>
            <div className='animation-container'>
                <IcNoData className="ic-no-data" />
                <h1>Danh sách trống!</h1>
                <p>{labelDes}</p>
            </div>
        </styles.Bound>
    )
}

export default React.memo(EmptyDataControl)
