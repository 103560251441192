import styled from 'styled-components'

const WrapIdentityCard = styled.div`
    padding: 25px 30px 30px 30px;
    background: #212121;
    border-radius: 10px;
    display: flex;
    align-items: center;

    & > :nth-child(2) {
        margin-left: 22px;
    }

    .title-popup {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #FFFFFF;
        text-align: left;
        margin-bottom: 14px;
        .image-orc-passort{
            position: absolute;
            
        }
    }
`

export {
    WrapIdentityCard
}