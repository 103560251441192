import { hostWSListenCall } from "../../../assets/constant/host"

const CODE_CLOSE_SAFE = 4000
class WsListenCall {
    constructor(streamID) {
        this.streamID = streamID
        this.socket = new WebSocket(hostWSListenCall(streamID))
        this.socket.onopen = e => console.log('socket open', e)
        this.socket.onclose = e => {
            console.log('socket close', e)
            const { code } = e;
            if(code !== CODE_CLOSE_SAFE) {
                this.reconnect()
            }
        }
        this.socket.onerror = e => {
            console.log('socket error', e)
            const { code } = e;
            if(code !== CODE_CLOSE_SAFE) {
                this.reconnect()
            }
        }
        this.reconnectRef = null
    }

    reconnect = () => {
        this.reconnectRef = setTimeout(() => {
            this.socket = new WebSocket(hostWSListenCall(this.streamID))
        }, 3000);
    }

    disconnect = () => {
        clearTimeout(this.reconnectRef)
        this.socket.close(CODE_CLOSE_SAFE)
        this.socket = null;
    }

    on = (callback) => {
        this.socket.onmessage = callback
    }

    emit = (data) => {
        this.socket.send(data)
    }
}

export default WsListenCall