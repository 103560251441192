import React from 'react'

export default function IcEyeWhite(props) {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5372 12.3081L2.5372 12.3081L2.53818 12.3059C4.50117 7.81335 8.14958 5.11426 12 5.11426C15.8504 5.11426 19.4988 7.81335 21.4618 12.3059L21.92 12.1057L21.4628 12.3081C21.4897 12.3689 21.5035 12.4342 21.5035 12.5C21.5035 12.5658 21.4897 12.6311 21.4628 12.6918L21.4618 12.6941C19.4988 17.1866 15.8504 19.8857 12 19.8857C8.14958 19.8857 4.50117 17.1866 2.53818 12.6941L2.5372 12.6918C2.51029 12.6311 2.49652 12.5658 2.49652 12.5C2.49652 12.4342 2.51029 12.3689 2.5372 12.3081ZM3.64803 12.2862L3.54688 12.5L3.64803 12.7138C5.43247 16.4859 8.57842 18.9143 12 18.9143C15.4216 18.9143 18.5675 16.4859 20.352 12.7138L20.4531 12.5L20.352 12.2862C18.5675 8.51408 15.4216 6.08569 12 6.08569C8.57842 6.08569 5.43247 8.51408 3.64803 12.2862ZM10.0527 9.63917C10.6285 9.25995 11.3062 9.05711 12 9.05711C12.9306 9.05711 13.8216 9.42157 14.4774 10.068C15.133 10.7143 15.5 11.5892 15.5 12.5C15.5 13.1793 15.2957 13.844 14.912 14.41C14.5283 14.976 13.9821 15.4183 13.3417 15.6798C12.7013 15.9413 11.9962 16.0098 11.3158 15.8764C10.6355 15.743 10.0116 15.4139 9.52258 14.9319C9.03362 14.4499 8.70147 13.8367 8.56698 13.1703C8.4325 12.5039 8.50146 11.813 8.76544 11.1848C9.02946 10.5565 9.47706 10.0183 10.0527 9.63917ZM10.6138 14.5567C11.0248 14.8274 11.5071 14.9714 12 14.9714C12.6607 14.9714 13.2958 14.7127 13.7652 14.2501C14.2348 13.7872 14.5 13.1578 14.5 12.5C14.5 12.0096 14.3524 11.5308 14.0768 11.1242C13.8013 10.7176 13.4104 10.4019 12.9544 10.2157C12.4984 10.0296 11.9972 9.98096 11.5136 10.0758C11.03 10.1706 10.5849 10.4048 10.2348 10.7499C9.88463 11.095 9.64534 11.5357 9.54831 12.0165C9.45128 12.4973 9.50115 12.9956 9.69129 13.4481C9.8814 13.9005 10.2028 14.286 10.6138 14.5567Z" fill="white" stroke="white"/>
        </svg>

    )
}
