import React from 'react'
//styles
import {WrapAnswer} from './index.styles';
const ButtonControl = ({ icon, children, ...props } ) => {
  return (
    <WrapAnswer {...props}>
      {/* <img src={icon} alt=""/> */}
      {icon}
      <p>{children}</p>
    </WrapAnswer>
  )
}
export default ButtonControl;