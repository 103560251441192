import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//styles
import * as styles from './index.styles'
import WrapperPage from '../../CustomStyles/PageWrapper'
//component
import BreadCrumbControl from '../../Control/BreadCrumbControl'
import DropDownFilterTransactionType from './DropDownFilterTransactionType'
import DropDownFilterCustomerInfo from './DropDownFilterCustomerInfo'
import DropDownFilterKiosk from './DropDownFilterKiosk'
import DropDownFilterTransactionTime from './DropDownFilterTransactionTime'
import DropDownFilterEKycType from './DropDownFilterEKycType'
import DropDownFilterCallTime from './DropDownFilterCallTime'
import DropDownFilterStatus from './DropDownFilterStatus'
import ItemTransactionHistory from './ItemTransactionHistory'
import Pagination from '../../Control/Pagination'
import EmptyDataControl from '../../Control/EmptyDataControl'
//mockData
import { transactionHistoryMockData } from '../../../assets/mockData/transactionHistoryMockData'

//action
import { getListTransaction } from '../../../redux/transaction/listTransaction/action'
import TransactionDetail from '../TransactionDetail'
import { getDetailTransReducer } from '../../../redux/transaction/detailTransaction/action'


export default function TransactionHistoryPage({handleShowPopupDetail}) {

    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const { listTransaction, condFilters, uuid, total, detailTransaction, system } = useSelector(state => ({
        listTransaction: state.transactionReducer.listTransReducer,
        condFilters: state.transactionReducer.listTransReducer.condFilters,
        uuid: state.loginReducer.dataLogin.uuid,
        total: state.transactionReducer.listTransReducer.total,
        detailTransaction: state.transactionReducer.detailTransReducer,
        system: state.systemReducer
    }))

    useEffect(() => {
        dispatch(getListTransaction({ ...condFilters, uuid }))
    }, [])

    useEffect(() => {
        if (listTransaction?.result)
            setData(listTransaction.result)
    }, [JSON.stringify(listTransaction?.result)])

    useEffect(() => {
        if(!system) return
        if(system.type === 'CLOSE_POPUP_VIDEO_CALL')
            dispatch(getListTransaction({ ...condFilters, uuid }))
        return () => {
            
        }
    }, [system])

    const _onChangePreviousPage = () => {
        dispatch(getListTransaction({
            ...condFilters,
            uuid,
            pageNumber: condFilters.pageNumber - 1,
        }))
    }

    const _onChangeNextPage = () => {
        dispatch(getListTransaction({
            ...condFilters,
            uuid,
            pageNumber: condFilters.pageNumber + 1,
        }))
    }

    return (
        <WrapperPage>
            <styles.Bound>
                <div className="title-container">
                    <BreadCrumbControl title="lịch sử giao dịch " />
                </div>
                <div className="table-container">
                    <div className="header-table">
                        <div className="filter-wrap">
                            <DropDownFilterTransactionType />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterCustomerInfo />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterKiosk />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterEKycType />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterTransactionTime />
                        </div>
                        <div className="filter-wrap status">
                            <DropDownFilterStatus />
                        </div>
                    </div>
                    <div className="content-table">
                        {
                            total === 0 ?
                                <EmptyDataControl />
                                :
                                <>
                                    {
                                        data.map((item) => (
                                            <div className='item-container' key={item.id} onClick={() => handleShowPopupDetail(item)}>
                                                <ItemTransactionHistory data={item} />
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
                    <div className="footer-table" style={total === 0 ? {backgroundColor: '#fff'}:{}}>
                    {
                        total !== 0 &&
                        <Pagination
                            total={total}
                            pageNumber={condFilters.pageNumber}
                            pageSize={condFilters.pageSize}
                            onChangePreviousPage={_onChangePreviousPage}
                            onChangeNextPage={_onChangeNextPage}
                        />
                    }
                    </div>
                </div>
            </styles.Bound>
        </WrapperPage>
    )
}
