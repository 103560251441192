import React from 'react'

const IcPrevious = (props) => {
    return (
        <svg {...props} width={5} height={9} viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-0.000148954 4.12624C0.00471669 3.96343 0.067971 3.81457 0.204211 3.69362L3.98974 0.158208C4.09678 0.0558672 4.23302 4.48492e-05 4.39359 4.48434e-05C4.71473 4.48319e-05 4.97261 0.241941 4.97261 0.548965C4.97261 0.697824 4.97266 4.12159 4.97266 4.12159C4.97266 4.12159 4.97261 7.55001 4.97261 7.70352C4.97261 8.01054 4.71473 8.25244 4.39359 8.25244C4.23302 8.25244 4.09678 8.19662 3.98974 8.09428L0.204211 4.55421C0.0679711 4.42861 -0.000148946 4.28906 -0.000148954 4.12624Z" fill="#191919" fillOpacity="0.6" />
        </svg>
    )
}

export default IcPrevious
