import React from 'react'

const IcReactionLove = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99951 17.625C13.763 17.625 17.6245 13.7635 17.6245 9C17.6245 4.23654 13.763 0.375 8.99951 0.375C4.23606 0.375 0.374512 4.23654 0.374512 9C0.374512 13.7635 4.23606 17.625 8.99951 17.625Z" fill="#FFCE52" />
            <path d="M8.99941 1.50007C13.5557 1.50007 17.2494 4.69019 17.2494 8.62507H17.615C17.5117 6.40959 16.5588 4.31908 14.9544 2.78778C13.35 1.25648 11.2173 0.4021 8.99941 0.4021C6.78152 0.4021 4.64887 1.25648 3.04445 2.78778C1.44003 4.31908 0.487171 6.40959 0.383789 8.62507H0.749414C0.749414 4.69019 4.44316 1.50007 8.99941 1.50007Z" fill="#FFE369" />
            <path d="M17.2495 8.625C17.2495 12.5599 13.5558 15.75 8.99951 15.75C4.44326 15.75 0.749512 12.5599 0.749512 8.625H0.383887C0.378637 8.7495 0.374512 8.87438 0.374512 9C0.374512 11.2875 1.28321 13.4813 2.90072 15.0988C4.51822 16.7163 6.71202 17.625 8.99951 17.625C11.287 17.625 13.4808 16.7163 15.0983 15.0988C16.7158 13.4813 17.6245 11.2875 17.6245 9C17.6245 8.87438 17.6204 8.7495 17.6151 8.625H17.2495Z" fill="#FFB32B" />
            <path d="M14.18 3.68468C14.3008 3.56388 14.2625 3.32968 14.0944 3.16158C13.9263 2.99349 13.6921 2.95515 13.5713 3.07595C13.4505 3.19675 13.4888 3.43095 13.6569 3.59904C13.825 3.76714 14.0592 3.80548 14.18 3.68468Z" fill="#F6FAFD" />
            <path d="M11.6824 1.84379C11.7429 1.78332 11.7238 1.66616 11.6397 1.58211C11.5557 1.49807 11.4385 1.47896 11.3781 1.53943C11.3176 1.5999 11.3367 1.71706 11.4207 1.80111C11.5048 1.88516 11.6219 1.90427 11.6824 1.84379Z" fill="#F6FAFD" />
            <path d="M13.3595 3.23878C13.6011 2.99718 13.5243 2.52867 13.188 2.19233C12.8517 1.85599 12.3832 1.7792 12.1416 2.0208C11.9 2.2624 11.9768 2.73091 12.3131 3.06724C12.6494 3.40358 13.1179 3.48038 13.3595 3.23878Z" fill="#F6FAFD" />
            <path d="M12.7496 14.625C12.7496 13.59 11.0734 12.75 8.99963 12.75C6.92588 12.75 5.24963 13.59 5.24963 14.625L5.21213 14.6738C4.64966 14.3098 4.18698 13.8112 3.8661 13.2231C3.54521 12.6351 3.37628 11.9762 3.37463 11.3062C3.36172 11.1066 3.42814 10.9099 3.55945 10.7589C3.69076 10.6079 3.87635 10.5149 4.07588 10.5C4.78088 10.905 6.47213 10.875 8.99963 10.875C11.5271 10.875 13.2184 10.905 13.9196 10.5C14.1198 10.5141 14.3063 10.6067 14.4384 10.7578C14.5704 10.9088 14.6374 11.106 14.6246 11.3062C14.623 11.9762 14.454 12.6351 14.1332 13.2231C13.8123 13.8112 13.3496 14.3098 12.7871 14.6738L12.7496 14.625Z" fill="#273941" />
            <path d="M12.7494 14.625L12.7869 14.6738C11.6606 15.4034 10.3412 15.7783 8.99941 15.75C7.65766 15.7783 6.33827 15.4034 5.21191 14.6738L5.24941 14.625C5.24941 13.59 6.92566 12.75 8.99941 12.75C11.0732 12.75 12.7494 13.59 12.7494 14.625Z" fill="#AE2D4C" />
            <path d="M6.18729 3.3751C6.41925 3.37262 6.6492 3.41838 6.86255 3.50946C7.0759 3.60054 7.268 3.73496 7.42666 3.9042C7.58532 4.07344 7.70708 4.2738 7.78422 4.49257C7.86136 4.71135 7.8922 4.94377 7.87478 5.1751C7.87478 7.8751 5.06229 9.3751 4.49979 9.3751C4.22979 9.3751 1.35729 7.9876 1.13604 5.47885C1.12854 5.37759 1.12479 5.27635 1.12479 5.1751C1.09428 4.76796 1.21405 4.36377 1.46144 4.03898C1.70883 3.7142 2.06668 3.49135 2.46729 3.4126C2.58048 3.38696 2.69623 3.37438 2.81229 3.3751C3.65604 3.3751 4.21854 3.9751 4.49979 4.8751C4.78104 3.9751 5.34354 3.3751 6.18729 3.3751Z" fill="#CF4054" />
            <path d="M16.8748 5.1751C16.8748 5.2751 16.8711 5.37634 16.8636 5.47885C16.6648 7.9876 14.0398 9.3751 13.4998 9.3751C13.2186 9.3751 10.1248 7.8751 10.1248 5.1751C10.1074 4.94377 10.1383 4.71135 10.2154 4.49257C10.2926 4.2738 10.4143 4.07344 10.573 3.9042C10.7316 3.73496 10.9237 3.60054 11.1371 3.50946C11.3504 3.41838 11.5804 3.37262 11.8123 3.3751C12.6561 3.3751 13.2186 3.9751 13.4998 4.8751C13.7811 3.9751 14.3436 3.3751 15.1873 3.3751C15.3034 3.37438 15.4192 3.38696 15.5323 3.4126C15.9329 3.49135 16.2908 3.7142 16.5382 4.03898C16.7856 4.36377 16.9054 4.76796 16.8748 5.1751Z" fill="#CF4054" />
            <path d="M4.07588 11.25C4.78088 11.655 6.47213 11.625 8.99963 11.625C11.5271 11.625 13.2184 11.655 13.9196 11.25C14.0797 11.2578 14.2327 11.3186 14.3544 11.4229C14.4762 11.5272 14.5597 11.669 14.592 11.826C14.6138 11.6536 14.6247 11.48 14.6246 11.3062C14.6374 11.106 14.5704 10.9088 14.4384 10.7578C14.3063 10.6067 14.1198 10.5141 13.9196 10.5C13.2184 10.905 11.5271 10.875 8.99963 10.875C6.47213 10.875 4.78088 10.905 4.07588 10.5C3.87635 10.5149 3.69076 10.6079 3.55945 10.7589C3.42814 10.9099 3.36172 11.1066 3.37463 11.3062C3.37458 11.4799 3.38547 11.6534 3.40725 11.8256C3.43907 11.6691 3.52203 11.5276 3.64307 11.4234C3.76411 11.3192 3.91637 11.2582 4.07588 11.25Z" fill="#141E21" />
            <path d="M12.5735 14.0571C11.4918 14.6947 10.2549 15.0209 8.99941 14.9999C7.74393 15.0209 6.50705 14.6947 5.42529 14.0571C5.31338 14.2256 5.25232 14.4227 5.24941 14.6249L5.21191 14.6736C6.33827 15.4033 7.65766 15.7782 8.99941 15.7499C10.3412 15.7782 11.6606 15.4033 12.7869 14.6736L12.7494 14.6249C12.7465 14.4227 12.6854 14.2256 12.5735 14.0571Z" fill="#8A293D" />
            <path d="M13.499 9.37509C13.2328 9.37509 10.4499 8.02884 10.1536 5.59471C10.1357 5.70396 10.1258 5.81438 10.124 5.92509C10.124 8.62509 13.2178 10.1251 13.499 10.1251C14.039 10.1251 16.664 8.73759 16.8628 6.22884C16.8703 6.12634 16.874 6.02509 16.874 5.92509C16.8738 5.81121 16.865 5.69752 16.8478 5.58496C16.5834 8.02509 14.0311 9.37509 13.499 9.37509Z" fill="#FBB40A" />
            <path d="M4.49951 9.37509C4.23326 9.37509 1.45039 8.02884 1.15414 5.59471C1.13615 5.70396 1.12624 5.81438 1.12451 5.92509C1.12451 8.62509 4.21826 10.1251 4.49951 10.1251C5.03951 10.1251 7.66451 8.73759 7.86326 6.22884C7.87076 6.12634 7.87451 6.02509 7.87451 5.92509C7.87427 5.81121 7.86549 5.69752 7.84826 5.58496C7.58389 8.02509 5.03164 9.37509 4.49951 9.37509Z" fill="#FBB40A" />
            <path d="M15.1873 3.3751C14.3435 3.3751 13.781 3.9751 13.4998 4.8751C13.7184 4.2001 14.156 3.7501 14.8123 3.7501C14.9892 3.74503 15.1653 3.77678 15.3293 3.84333C15.4934 3.90988 15.6418 4.00979 15.7652 4.13671C15.8886 4.26363 15.9842 4.4148 16.0461 4.58063C16.1081 4.74647 16.1348 4.92336 16.1248 5.1001C16.1248 7.1251 13.9374 8.2501 13.4998 8.2501C13.2898 8.2501 11.0555 7.20947 10.8834 5.32772C10.8778 5.25272 10.8748 5.17585 10.8748 5.1001C10.8555 4.78988 10.9514 4.48346 11.144 4.23954C11.3367 3.99563 11.6125 3.83136 11.9188 3.77822C12.007 3.75891 12.097 3.74948 12.1873 3.7501C12.8435 3.7501 13.2812 4.2001 13.4998 4.8751C13.2185 3.9751 12.656 3.3751 11.8123 3.3751C11.6962 3.37438 11.5805 3.38696 11.4673 3.4126C11.0667 3.49135 10.7088 3.7142 10.4614 4.03898C10.2141 4.36377 10.0943 4.76796 10.1248 5.1751C10.1248 5.2751 10.1285 5.37634 10.136 5.47885C10.3573 7.9876 13.2298 9.3751 13.4998 9.3751C14.0623 9.3751 16.8748 7.8751 16.8748 5.1751C16.8922 4.94377 16.8614 4.71135 16.7842 4.49257C16.7071 4.2738 16.5853 4.07344 16.4267 3.9042C16.268 3.73496 16.0759 3.60054 15.8626 3.50946C15.6492 3.41838 15.4193 3.37262 15.1873 3.3751Z" fill="#AE2D4C" />
            <path d="M6.18729 3.3751C5.34354 3.3751 4.78104 3.9751 4.49979 4.8751C4.71841 4.2001 5.15604 3.7501 5.81229 3.7501C5.98923 3.74503 6.1653 3.77678 6.32933 3.84333C6.49335 3.90988 6.64177 4.00979 6.76516 4.13671C6.88856 4.26363 6.98424 4.4148 7.04615 4.58063C7.10805 4.74647 7.13483 4.92336 7.12478 5.1001C7.12478 7.1251 4.93741 8.2501 4.49979 8.2501C4.28979 8.2501 2.05554 7.20947 1.88341 5.32772C1.87779 5.25197 1.87479 5.1751 1.87479 5.1001C1.85553 4.78988 1.9514 4.48346 2.14404 4.23954C2.33668 3.99563 2.61255 3.83136 2.91879 3.77822C3.00696 3.75891 3.09702 3.74948 3.18729 3.7501C3.84354 3.7501 4.28116 4.2001 4.49979 4.8751C4.21854 3.9751 3.65604 3.3751 2.81229 3.3751C2.69623 3.37438 2.58048 3.38696 2.46729 3.4126C2.06668 3.49135 1.70883 3.7142 1.46144 4.03898C1.21405 4.36377 1.09428 4.76796 1.12479 5.1751C1.12479 5.2751 1.12854 5.37634 1.13604 5.47885C1.35729 7.9876 4.22979 9.3751 4.49979 9.3751C5.06229 9.3751 7.87478 7.8751 7.87478 5.1751C7.8922 4.94377 7.86136 4.71135 7.78422 4.49257C7.70708 4.2738 7.58532 4.07344 7.42666 3.9042C7.268 3.73496 7.0759 3.60054 6.86255 3.50946C6.6492 3.41838 6.41925 3.37262 6.18729 3.3751Z" fill="#AE2D4C" />
            <path d="M16.055 5.18541C16.1758 5.06461 16.1375 4.83041 15.9694 4.66232C15.8013 4.49422 15.5671 4.45588 15.4463 4.57668C15.3255 4.69748 15.3638 4.93168 15.5319 5.09977C15.7 5.26787 15.9342 5.30621 16.055 5.18541Z" fill="#F6FAFD" />
            <path d="M15.4324 4.47075C15.4929 4.41027 15.4738 4.29312 15.3897 4.20907C15.3057 4.12502 15.1885 4.10591 15.128 4.16638C15.0676 4.22686 15.0867 4.34401 15.1707 4.42806C15.2548 4.51211 15.3719 4.53122 15.4324 4.47075Z" fill="#F6FAFD" />
            <path d="M7.05353 5.18248C7.17433 5.06168 7.13599 4.82748 6.96789 4.65939C6.7998 4.49129 6.5656 4.45295 6.4448 4.57375C6.324 4.69455 6.36234 4.92875 6.53044 5.09684C6.69853 5.26494 6.93273 5.30328 7.05353 5.18248Z" fill="#F6FAFD" />
            <path d="M6.43095 4.46733C6.49142 4.40686 6.47231 4.2897 6.38826 4.20565C6.30422 4.1216 6.18706 4.10249 6.12659 4.16296C6.06611 4.22344 6.08522 4.3406 6.16927 4.42464C6.25332 4.50869 6.37048 4.5278 6.43095 4.46733Z" fill="#F6FAFD" />
        </svg>
    )
}

export default IcReactionLove
