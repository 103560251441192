import React from 'react'

// container
import StepContainer from '../StepContainer'

// styles
import { StepFourDetails } from './index.styles'

//images
import AvatarDefault from '../../../../../../../assets/images/AvatarDefault'
// const ImageStatus = {
//     True: "True",
//     False: "False"
// }
const StepFour = ({ status, faceImg, recogImg, isMatched }) => {
    return (
        <StepContainer title='BƯỚC 4: HÌNH ẢNH KHUÔN MẶT' status={status} animationName='showStepFourAnimation'>
            <div className='show-container'>
                <StepFourDetails haveResultRecog={!!faceImg && !!recogImg} isMatched={isMatched}>
                    <div className="title">
                        Hình ảnh
                    </div>
                    <div className="wrap-content">
                        <div className="wrap-images">
                            <div className="images">
                                {
                                    faceImg ?
                                    <img 
                                        className="avatar-current" 
                                        alt="face"
                                        src={faceImg}
                                    />
                                    :
                                    <AvatarDefault className="avatar-current" />
                                }
                            </div>
                            <p className="type-of-image">Hình đăng ký tài khoản</p>
                        </div>
                        {/* <div className="wrap-images">
                            <div className="images-avatar">
                                {
                                    recogImg ?
                                    <img
                                        src={recogImg}
                                        className="avatar-from-origin"
                                        alt="face-recognition"
                                    />
                                    :
                                    <AvatarDefault className="avatar-from-origin" />
                                }
                            </div>
                            <p className="type-of-image">Hình cắt từ giấy tờ</p>
                        </div> */}
                        <div className="wrap-id-card-img">
                            <div className="wrap-center">
                            {
                                recogImg ?
                                <img 
                                    src={recogImg}
                                    alt="id-card" 
                                    className="img-id-card"
                                />
                                :
                                <div className="img-id-card" />
                            }
                            </div>
                            <div className="title-id-card">Hình ảnh giấy tờ</div>
                        </div>
                        <div className="wrap-result">
                            <div className="result">Kết quả so sánh:</div>
                            {
                                faceImg && recogImg ?
                                    isMatched ?
                                    <div className="status">Khuôn mặt khớp nhau!</div>
                                    :
                                    <div className="status reject">Không khớp</div>
                                :
                                    null
                            }
                        </div>
                    </div>
                </StepFourDetails>
            </div>
        </StepContainer>
    )
}

export default StepFour
