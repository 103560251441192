import React from 'react'
import {Bound} from './index.styles';
const ActiveCardControl = ({isActive, activeCardExtendStyle}) => {
  return (
    <Bound isActive={isActive} className={activeCardExtendStyle}>
      <div className="active">
      </div>
    </Bound>
  )
}
export default ActiveCardControl;