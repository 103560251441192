import styled from 'styled-components'

const WrapStep = styled.div`
    height: 70px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 93px 1fr 160px;
    overflow: hidden;
    padding-right: 35px;

    p{
        font-family: 'SVN-Gilroy';
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #016730;
    }

    .step-status{
        width: 100%;
        height: 100%;
        background: rgba(25, 25, 25, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;

        &.status-done {
            background: rgba(1, 103, 48, 0.2);
            .status-bound{
                background: #016730;
            }
        }

        &.status-view {
            background: rgba(255, 189, 19, 0.1);
            .status-bound{
                background: #FFBD13;
            }
        }

        .status-bound{
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: #B1B1B1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
    
    .step-title{
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #191919;

        &.title-done {
            color: #016730;
        }

        &.title-inprogress {
            color: #FFBD13;
        }
    }

    .step-arrow{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 0.2;

        &.step-active{
            animation-name: changeOpacityAnimation;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            animation-delay: 0.3s;
            svg{
                animation-name: rotateAnimation;
                animation-duration: 0.5s;
                animation-fill-mode: forwards;
                animation-delay: 0.3s;
            }
        }

        svg{
            transform: rotate(-90deg);
        }
    }

    .status-confirmed{
        width: 160px;
        height: 36px;
        border: 1px solid #016730;
        box-sizing: border-box;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        p{
            font-size: 12px;
            line-height: 30px;
            text-transform: uppercase;
        }
    }
    
    @keyframes changeOpacityAnimation{
        from{
            opacity: 0.2;
        }
        to{
            opacity: 1;
        }
    }
    @keyframes rotateAnimation{
        from{
            transform: rotate(-90deg);
        }
        to{
            transform: rotate(0deg);
        }
    }
`;

export {
    WrapStep
}