import React from 'react'

// styles
import { WrapDetailCall } from './index.styles'

// component
import CallInfos from './CallInfos'
import TransactionInfos from './TransactionInfos'

const DetailCall = ({ 
    socketRTC, 
    hangupRef, 
    dataRTCConfig, 
    rtcConfigRef,
    kioskData,
    phoneInfos
}) => {
    return (
        <WrapDetailCall>
            <div className="wrapper">
                <div className="header">{`chi tiết phục vụ khách hàng`}</div>
                <TransactionInfos className="transaction-infos" />
                <CallInfos 
                    socketRTC={socketRTC} 
                    hangupRef={hangupRef} 
                    dataRTCConfig={dataRTCConfig}
                    rtcConfigRef={rtcConfigRef}
                    kioskData={kioskData}
                    phoneInfos={phoneInfos}
                />
            </div>
        </WrapDetailCall>
    )
}

export default DetailCall
