import React from 'react'

const IcNext = (props) => {
    return (
        <svg {...props} width={6} height={9} viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.23404 4.1262C5.22917 4.28901 5.16592 4.43787 5.02968 4.55882L1.24415 8.09423C1.1371 8.19657 1.00086 8.2524 0.840295 8.2524C0.519157 8.2524 0.261274 8.0105 0.261274 7.70348C0.261274 7.55462 0.261231 4.13085 0.261231 4.13085C0.261231 4.13085 0.261274 0.702431 0.261274 0.548919C0.261274 0.241897 0.519157 -1.03054e-08 0.840294 -2.31365e-08C1.00086 -2.9552e-08 1.1371 0.0558223 1.24415 0.158163L5.02968 3.69823C5.16592 3.82383 5.23404 3.96338 5.23404 4.1262Z" fill="#191919" fillOpacity="0.6" />
        </svg>
    )
}

export default IcNext
