import React, { useState } from 'react'
import moment from "moment"
//style
import * as styles from './index.styles'
//tool
import { convertDateTime } from '../../../../helper/tools'
//image
import IcCardMini from '../../../../assets/images/IcCardMini'
import IcReceivedCall from '../../../../assets/images/IcReceivedCall'
import IcMissedCall from '../../../../assets/images/IcMissedCall'
import IcRefusedCall from '../../../../assets/images/IcRefusedCall'
import AvatarDefault from '../../../../assets/images/AvatarDefault'
//constant
import { MISSED, RECEIVED, REJECTED } from '../../../../assets/constant/statusCallType'
//api
import { apiAvatarImage } from '../../../../assets/constant/host'



const ItemCallHistory = ({ data }) => {

    const [isErrImg, setIsErrImg] = useState(false)

    return (
        <styles.Bound className='item-bound'>
            <div className="info-customer-wrap">
                <div className="avatar-wrap">
                    {
                        isErrImg ?
                            <AvatarDefault className="avatar" />
                            :
                            <img
                                className="avatar"
                                src={apiAvatarImage(data?.customerInfo?.customerId)}
                                alt="avatar"
                                onError={() => setIsErrImg(true)}
                            />
                    }
                </div>
                <div className="info">
                    <p className="name" title={data?.customerInfo?.identityCardInfo?.fullName || ""}>
                        {data?.customerInfo?.identityCardInfo?.fullName || "Khách hàng vãng lai"}
                    </p>
                    <p className="phone" title={data?.customerInfo?.phoneNumber || ""}>
                        {data?.customerInfo?.phoneNumber || "-"}
                    </p>
                </div>
            </div>
            <div className="trans-type">
                <IcCardMini className="icon" />
                <p>Mở tài khoản</p>
            </div>
            <div className="branch-wrap">
                <p title={data?.kioskInfo?.branchInfo?.address || ""}>
                    {data?.kioskInfo?.branchInfo?.address || "-"}
                </p>
                <p className="branck-kiosk" title={data?.kioskInfo?.branchInfo?.name || ""}>
                    {data?.kioskInfo?.branchInfo?.name || "-"}
                </p>
            </div>
            <div className="user-wrap">
                <p title={data?.counterInfo?.userName || ""}>
                    {data?.counterInfo?.userName ? `@ ${data.counterInfo.userName}` : "-"}
                </p>
                <p className="full-username" title={data?.counterInfo?.fullName || ""}>
                    {data?.counterInfo?.fullName || "-"}
                </p>
            </div>
            <div className="time-wrap">
                <p>{data?.status === 1 ? convertDateTime(data?.timeReceived, "DD/MM/YYYY") : "-"}</p>
                <p className="time">{data?.status === 1 ? convertDateTime(data?.timeReceived, "HH:mm") : "-"}</p>
            </div>
            <div className="status-wrap" style={{paddingRight: '30px'}}>
                {
                    data?.status === RECEIVED ?
                        <div className="icon-received">
                            <IcReceivedCall className="icon" />
                            <p>ĐÃ NHẬN</p>
                        </div>
                        :
                        <>
                            {
                                data?.status === REJECTED ?
                                    <div className="icon-refused">
                                        <IcRefusedCall className="icon" />
                                        <p>TỪ CHỐI</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            data?.status === MISSED ?
                                                <div className="icon-missed">
                                                    <IcMissedCall className="icon" />
                                                    <p>GỌI NHỠ</p>
                                                </div>
                                                :
                                                <div/>
                                        }
                                    </>
                                    
                            }
                        </>
                }
                {
                    data?.status === RECEIVED ?
                        <div className="status-time">
                            {
                                data?.processingTime &&
                                moment().startOf('day').seconds(data.processingTime).format('HH:mm:ss')
                            }
                        </div>
                        :
                        "-"
                }
            </div>
        </styles.Bound>
    )
}

export default ItemCallHistory
