import React, { useState, useLayoutEffect } from 'react'

// styles
import { WrapIdentity } from './index.styles'

// component
import IcExpand from '../../../assets/images/IcExpand'
// import Portal from '../../../../Controls/Portal'
// import PopupZoomIdentity from './PopupZoomIdentity'

const Identity = ({ uniqueKey, image, isHaveZoom = true, ...props }) => {

    const [isErrImg, setIsErrImg] = useState(false)
    // const [isShowZoom, setIsShowZoom] = useState(false)

    useLayoutEffect(() => {
        setIsErrImg(false)
    }, [image])

    return (
        <WrapIdentity {...props}>
            {
                isErrImg ?
                    <div className="identity" />
                    :
                    <>
                        <img
                            src={`data:image/png;base64,${image}`}
                            alt="identity"
                            className="identity"
                            onError={() => setIsErrImg(true)}
                        />
                        {
                            isHaveZoom &&
                            <IcExpand 
                                className="zoom" 
                                uniqueKey={uniqueKey}
                                // onClick={() => setIsShowZoom(true)}
                            />
                        }
                    </>

            }
            {/* {
                isShowZoom &&
                <Portal>
                    <PopupZoomIdentity 
                        onClosePopup={() => setIsShowZoom(false)}
                        image={image}
                    />
                </Portal>
            } */}
        </WrapIdentity>
    )
}

export default Identity
