import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns: 408px 408px;
    grid-gap: 32px;
    position: relative;
    .details-content{
        display: flex;
        flex-direction: column;
        .detail{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 14px;
            :last-child{
                margin-bottom: 0;
            }
            .title{
                color: rgba(25, 25, 25, 0.6);
                width: fit-content;
            }
            .content{
                display: flex;
                align-items: center;
                font-weight: 600;
                color: #191919;
                text-align: right;                    
                .icon-card{
                    margin-right: 8px;
                }
                .papers{
                    text-decoration-line: underline;
                    color: #016730;
                    cursor: pointer;
                }
                &:hover {
                    cursor: pointer;
                    & > .img-identity-card {
                        display: flex;
                    }
                }
                .img-identity-card {
                    display: none;
                    position: absolute;
                    bottom: calc(100% + 20px);
                    left: 0px;
                }
                &.address{
                    max-width: 252px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    justify-self: right;
                }
            }
        }
    }

`
export { Bound }