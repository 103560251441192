import styled from 'styled-components'

const Bound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .details-container{
        display: grid;
        grid-template-columns: 408px 408px;
        grid-gap: 32px;
        margin-bottom: 30px;
        .details-content{
            display: flex;
            flex-direction: column;
            .detail{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 14px;
                :last-child{
                    margin-bottom: 0;
                }
                .title{
                    color: rgba(25, 25, 25, 0.6);
                    width: fit-content;
                }
                .content{
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: #191919;
                    text-align: right;
                    .icon{
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .button-watch-video{
        width: fit-content;
        height: 26px;
        background: rgba(1, 103, 48, 0.1);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center; 
        padding: 0 9px;     
        cursor: pointer;
        .text{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            text-transform: uppercase;
            color: #016730;
            margin-right: 6px;
        }
    }
`


export { Bound }