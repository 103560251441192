import React from 'react'

const IcArrowNextGreen = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9467 6.74647C10.9149 6.66463 10.8674 6.58987 10.8067 6.52647L7.47333 3.19313C7.41117 3.13097 7.33738 3.08167 7.25617 3.04803C7.17495 3.01438 7.08791 2.99707 7 2.99707C6.82246 2.99707 6.6522 3.0676 6.52667 3.19313C6.46451 3.25529 6.4152 3.32908 6.38156 3.4103C6.34792 3.49151 6.33061 3.57856 6.33061 3.66646C6.33061 3.844 6.40113 4.01426 6.52667 4.1398L8.72667 6.33313H3.66667C3.48986 6.33313 3.32029 6.40337 3.19526 6.52839C3.07024 6.65342 3 6.82299 3 6.9998C3 7.17661 3.07024 7.34618 3.19526 7.4712C3.32029 7.59623 3.48986 7.66647 3.66667 7.66647H8.72667L6.52667 9.8598C6.46418 9.92177 6.41458 9.99551 6.38074 10.0767C6.34689 10.158 6.32947 10.2451 6.32947 10.3331C6.32947 10.4211 6.34689 10.5083 6.38074 10.5895C6.41458 10.6708 6.46418 10.7445 6.52667 10.8065C6.58864 10.869 6.66238 10.9185 6.74361 10.9524C6.82485 10.9862 6.91199 11.0037 7 11.0037C7.08801 11.0037 7.17514 10.9862 7.25638 10.9524C7.33762 10.9185 7.41136 10.869 7.47333 10.8065L10.8067 7.47313C10.8674 7.40973 10.9149 7.33497 10.9467 7.25313C11.0133 7.09082 11.0133 6.90877 10.9467 6.74647Z" fill="#016730" />
        </svg>
    )
}

export default IcArrowNextGreen
