const servers = {
    iceServers: [
        {'urls': 'stun:stun.wee.vn:3478'},
        {
            'urls': ['turn:turn.wee.vn:3478?transport=udp', 'turn:turn.wee.vn:3478?transport=tcp'],
            'username': 'wee',
            'credential': 'sycomore22'
        }
    ]
}

const mediaStreamConstraints = {
    video: true,
    audio: process.env.NODE_ENV === 'production',
    // audio: true
}

class RTCConfig {
    constructor(localVideo, remoteVideo, configs, socketRTC, hangupWhenDisconnect) {
        this.peerConnection = new RTCPeerConnection(servers);
        this.localStream = null;
        this.localVideo = localVideo
        this.remoteStream = null;
        this.remoteVideo = remoteVideo
        this.listCandidates = configs.Content.listCandidates
        this.sessionDescription = configs.Content.sessionDescription
        this.socketRTC = socketRTC
        this.hangupWhenDisconnect = hangupWhenDisconnect
        this.answerForRemote = {
            listCandidates : [],
            receiverID : '',
            sessionDescription : {
                description: '',
                type: "ANSWER"
            }
        }
    }

    initial = () => {
        this.peerConnection.addEventListener('icecandidate', this.handleConnection);
        this.peerConnection.addEventListener('iceconnectionstatechange', this.handleConnectionChange);
        this.peerConnection.addEventListener('addstream', this.gotRemoteMediaStream);
        

        navigator.mediaDevices
            .getUserMedia(mediaStreamConstraints)
            .then(this.gotLocalMediaStream)
            .catch((e) => console.log(e));
    }

    handleConnection = (e) => {
        console.log('have connection')
        if(e.candidate) {
            this.answerForRemote = {
                ...this.answerForRemote,
                listCandidates: [
                    ...this.answerForRemote.listCandidates,
                    {
                        sdp: e.candidate.candidate,
                        sdpMLineIndex: e.candidate.sdpMLineIndex,
                        sdpMid: e.candidate.sdpMid
                    }
                ]
            }

            
        }
        else {
            if(!this.answerForRemote.listCandidates.length) return;
            this.socketRTC.emitConnecting(this.answerForRemote)
        }
    }

    handleConnectionChange = (event) => {
        console.log('ICE state change event: ', event);
        if(!event.currentTarget?.iceConnectionState) return
        switch (event.currentTarget.iceConnectionState) {
            case "failed":
            case "disconnected":
            case "closed": {
                console.log('disconnected')
                this.hangupWhenDisconnect()
                break;
            }
            default:
                break;
        }
    }

    gotLocalMediaStream = (mediaStream) => {
        this.localStream = mediaStream;
        if(this.localVideo)
            this.localVideo.srcObject = mediaStream;
        this.localStream.getTracks().forEach(track => {
            track.applyConstraints({ frameRate: { max: 24 } });
            this.peerConnection.addTrack(track, this.localStream)
            // const sender = this.peerConnection.addTrack(track, this.localStream)
            // const params = sender.getParameters();
            // if (!params.encodings) params.encodings = [{}];
            // params.encodings[0].maxBitrate = 20000;
            // // params.encodings[0].scaleResolutionDownBy = 2;
            // sender.setParameters(params);
        });

        this.executeOffer()
        this.executeLstCandidates()
    }

    executeOffer = () => {
        const { type, description } = this.sessionDescription
        const sessionDescription = new RTCSessionDescription({
            sdp: description,
            type: type.toLowerCase()
        })
        this.peerConnection
            .setRemoteDescription(sessionDescription)
            .then(() => {
                const supportsSetCodecPreferences = window.RTCRtpTransceiver &&
                        'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
                if (supportsSetCodecPreferences) {
                    const preferredCodec = 'video/VP8'
                    if (preferredCodec !== '') {
                        const [mimeType, sdpFmtpLine] = preferredCodec.split(' ');
                        const {codecs} = RTCRtpSender.getCapabilities('video');
                        const selectedCodecIndex = codecs.findIndex(c => c.mimeType === mimeType && c.sdpFmtpLine === sdpFmtpLine);
                        const selectedCodec = codecs[selectedCodecIndex];
                        codecs.slice(selectedCodecIndex, 1);
                        codecs.unshift(selectedCodec);
                        const transceiver = this.peerConnection.getTransceivers().find(t => t.sender && t.sender.track === this.localStream.getVideoTracks()[0]);
                        transceiver.setCodecPreferences(codecs);
                    }
                }
            })
            .then(this.configVideoRemote) 
            .then(this.setRemoteDescSuccess)

    }

    configVideoRemote = () => {
        // const mimeType = 'video/VP8'
        // const {codecs} = RTCRtpSender.getCapabilities('video');
        // const selectedCodecIndex = codecs.findIndex(c => c.mimeType === mimeType);
        // const selectedCodec = codecs[selectedCodecIndex];
        // codecs.slice(selectedCodecIndex, 1);
        // codecs.unshift(selectedCodec);
        // const transceiver = this.peerConnection.getTransceivers().find(t => t.sender && t.sender.track === this.localStream.getVideoTracks()[0]);
        // transceiver.setCodecPreferences(codecs);
    }

    setRemoteDescSuccess = () => {
        console.log("set remote desc success")
        this.peerConnection.createAnswer()
            .then((answer) => {
                this.peerConnection.setLocalDescription(answer)
                    .then(() => console.log('set local desc success'))
                    .then(() => {
                        this.answerForRemote = {
                            ...this.answerForRemote,
                            sessionDescription : {
                                description: this.peerConnection.localDescription.sdp,
                                type: "ANSWER"
                            }
                        }

                        if(!this.answerForRemote.listCandidates.length) return;

                        this.socketRTC.emitConnecting(this.answerForRemote)
                    })
            })
    }

    executeLstCandidates = () => {
        this.listCandidates.forEach(candidate => {
            const ice = new RTCIceCandidate(candidate)
            this.peerConnection.addIceCandidate(ice)
        })
    }

    gotRemoteMediaStream = (event) => {
        console.log("have incoming media", event)
        const mediaStream = event.stream;
        this.remoteVideo.srcObject = mediaStream;
        this.remoteStream = mediaStream;
    }

    hangupAction = () => {
        this.peerConnection?.close();
        this.peerConnection = null;
        this.localStream &&
            this.localStream.getTracks().forEach(function(track) {
                track.stop();
            });
        console.log('Ending call.');
    }
}

export default RTCConfig