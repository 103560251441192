import React from 'react'

// container
import StepContainer from '../StepContainer'

// styles
import { StepEightDetails, WrapItem } from './index.styles'

// constant
import ratingTypes from '../../../../../../../assets/constant/ratingTypes'

const IconReaction = ({ isActive, Icon }) => {
    return (
        <WrapItem isActive={isActive}>
            <Icon className="images-style"/>
        </WrapItem>
    )
}
const typeOfReactions = {
    LOVE : "LOVE",
    SMILE : "SMILE",
    NEUTRAL : "NEUTRAL",
    SAD: "SAD",
}
const StepEight = ({ status, reviewType }) => {
    return (
        <StepContainer title='BƯỚC 8: ĐÁNH GIÁ' status={status} animationName='showStepEightAnimation' >
            <div className='show-container'>
                <StepEightDetails>
                    {
                        Object.values(ratingTypes).filter(item => !!item.Icon).map(({ message, code, numCode, Icon }) => (
                            <div className="wrap-reaction" key={code}>
                                <IconReaction className="icon-reaction" isActive={reviewType === numCode} Icon={Icon} />
                                <p>{message}</p>
                            </div>
                        ))
                    }
                </StepEightDetails>
            </div>
        </StepContainer>
    )
}

export default StepEight
