import styled from 'styled-components'

const WrapStepContainer = styled.div`
    
    &:not(:first-child) {
        margin-top: 10px;
    }
    .show-container{
        animation-name: ${props => props.animationName};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
        overflow: hidden;
        height: 0;
        border-radius: 0 0 10px 10px;
    }
    
`;

export {
    WrapStepContainer
}