import React, { useMemo } from 'react'

// styles
import { WrapIdentityCard } from './index.styles'

// constant
import { PASSPORT, CCCD, CMND, CMND12 } from '../../../../../../assets/constant/identityCardTypes'

//component
import Identity from '../../../../../Control/Identity'

// api get image
// import { apiGetIdenCardBackImg, apiGetIdenCardFrontImg } from '../../../../../../assets/constant/host'

const PopupShowImageIdentityCard = ({ identityCardFront, identityCardBack, typeIdentityCard, ...props }) => {

    const renderContent = useMemo(() => {
        switch (typeIdentityCard) {
            case PASSPORT:
                return (
                    <div>
                        <div className="title-popup">Hỉnh ảnh giấy tờ</div>
                        <Identity className="image-orc-passort"
                            uniqueKey="image-orc-front-wrap"
                            isHaveZoom={false}
                            image={identityCardFront || ""} 
                        />

                    </div>
                )
            case CCCD:
            case CMND:
            case CMND12:
                return (
                    <>
                        <div>
                            <div className="title-popup">Mặt trước</div>
                            <Identity className="image-orc-front-wrap"
                                uniqueKey="image-orc-front-wrap"
                                image={identityCardFront || ""}
                            />
                        </div>
                        <div>
                            <div className="title-popup">Mặt sau</div>
                            <Identity className="image-orc-back-wrap"
                                uniqueKey="image-orc-back-wrap"
                                image={identityCardBack || ""}
                            />
                        </div>
                    </>
                )
            default:
                return null;
        }
    }, [typeIdentityCard])

    return (
        <WrapIdentityCard {...props}>
            {renderContent}
        </WrapIdentityCard>
    )
}

export default PopupShowImageIdentityCard
