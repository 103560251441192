import * as types from "./types";
import * as transactionTypes from "../transaction/listTransaction/types";
import * as dashboardTypes from "../dashboard/types";
import * as callHistoryTypes from "../callHistory/listCallHistory/types";

const intitialState = {
  type: ''
};

const systemReducer = (state = intitialState, action) => {
  switch (action.type) {
    case types.CLOSE_POPUP_VIDEO_CALL:
      return {
        ...state,
        type: action.type
      }
    case transactionTypes.GET_LIST_TRANSACTION:
    case dashboardTypes.GET_DASHBOARD:
    case callHistoryTypes.GET_LIST_CALL_HISTORY:  {
      return {
        ...state,
        type: ''
      }
    }
    default:
      return state;
  }
};

export default systemReducer;
