import React, { useEffect, useState } from 'react'
import moment from 'moment'
//style
import * as styles from './index.styles'
//tools
import { convertDateTime, covertDayTime } from '../../../../helper/tools'
//image
import IcClock from '../../../../assets/images/IcClock'
import IcCalendar from '../../../../assets/images/IcCandelar'


function ClockControl() {
    const [dateTime, setDateTime] = useState(moment())
    const convertDayOfWeek = (momentDate) => {
        let dayOfW = convertDateTime(momentDate, 'e')
        switch (dayOfW) {
            case '1':
                return 'Thứ 2'
            case '2':
                return 'Thứ 3'
            case '3':
                return 'Thứ 4'
            case '4':
                return 'Thứ 5'
            case '5':
                return 'Thứ 6'
            case '6':
                return 'Thứ 7'
            case '0':
                return 'Chủ Nhật'
            default:
                break;
        }
    }
    const [dayOfweek, setDayOfWeek] = useState(convertDayOfWeek(dateTime))
    
    useEffect(() => {
        let timer = setInterval(() => {
            setDateTime(moment())
        }, 5000);
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <styles.Bound>
            <div className='date-container'>
                <IcClock className='icon' />
                <p>{convertDateTime(dateTime, 'HH:mm')}</p>
            </div>
            <div className='date-container'>
                <IcCalendar className='icon' />
                <p>{dayOfweek}, {convertDateTime(dateTime, 'DD/MM/YYYY')}</p>
                {/* <p>{convertDateTime(dateTime, 'DD/MM/YYYY')}</p> */}
            </div>            
        </styles.Bound>
    )
}

export default ClockControl
