import moment from 'moment'
// export const host = "http://weezi.biz:7080"

// export const apiRegisterStream = 'http://124.158.11.189:8480/stream/receiver/register'
// export const hostWSFormStream = (uuid) => `ws://weezi.biz:7080/counter/form-stream/connect?uuid=${uuid}`
// export const hostWSSocketRTC = (streamID) => `ws://124.158.11.189:8443/stream/receiver/ws/connect?receiverId=${streamID}`
// export const hostWSListenCall = (streamID) => `ws://124.158.11.189:8443/stream/notify/ws/connect?receiverId=${streamID}`

export const { 
    host, 
    apiRegisterStream, 
    hostWSFormStream, 
    hostWSSocketRTC, 
    hostWSListenCall
} = (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'poc':
            return {
                host: 'https://vpbank.wee.vn/api',
                apiRegisterStream: 'https://vpbank.wee.vn/vdcall/stream/receiver/register', 
                hostWSFormStream: (uuid) => `wss://vpbank.wee.vn/ws/counter/form-stream/connect?uuid=${uuid}`, 
                hostWSSocketRTC: (streamID) => `wss://vpbank.wee.vn/wsvdcall/stream/receiver/ws/connect?receiverId=${streamID}`, 
                hostWSListenCall: (streamID) => `wss://vpbank.wee.vn/wsvdcall/stream/notify/ws/connect?receiverId=${streamID}`
            }
        default:
            return {
                host: "http://weezi.biz:7080", 
                apiRegisterStream: 'http://124.158.11.189:8480/stream/receiver/register', 
                hostWSFormStream: (uuid) => `ws://weezi.biz:7080/counter/form-stream/connect?uuid=${uuid}`, 
                hostWSSocketRTC: (streamID) => `ws://124.158.11.189:8443/stream/receiver/ws/connect?receiverId=${streamID}`, 
                hostWSListenCall: (streamID) => `ws://124.158.11.189:8443/stream/notify/ws/connect?receiverId=${streamID}`
            }
    }
})()

export const apiGetKioskInfos = `${host}/counter/getKioskInfo`
export const apiAvatarImage = (customerId = "") => {
    return `${host}/counter/customer/avatar/download?customerId=${customerId}&time=${moment().unix()}`;
};

export const apiGetImgFormStream = photoId => `${host}/counter/live-screen/photo/download?photoId=${photoId}`

export const apiGetVideoCallRecord = (videoCallId) => {
    return `${host}/counter/video-call/record/download?videoCallId=${videoCallId}`
}