import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
//styles
import * as styles from './index.styles'
//constant
import * as paths from '../../assets/constant/path'
//component
import Header from './Header'
import SideMenu from './SideMenu'
import HomePage from './PageHome'
import TransactionHistoryPage from './PageTrasactionHistory'
import CallHistoryPage from './PageCallHistory'
//popup
import VideoCall from './VideoCall';
import CallDetail from './CallDetail'
import TransactionDetail from './TransactionDetail'
//action
import { getDetailTransReducer, clearDetailsTransaction } from '../../redux/transaction/detailTransaction/action'

export default React.memo(function Main() {
    const dispatch = useDispatch()

    const [detailTransactionData, setDetailTransactionData] = useState({})
    const [isShowPopupTransactionDetail, setIsShowPopupTransactionDetail] = useState(false)

    const { uuid, detailTransaction } = useSelector(state => ({
        uuid: state.loginReducer.dataLogin.uuid,
        detailTransaction: state.transactionReducer.detailTransReducer,
    }))

    useEffect(() => {
        if (detailTransaction?.details)
            setDetailTransactionData(detailTransaction.details)
    }, [JSON.stringify(detailTransaction?.details)])


    const _handleShowPopupTransactionDetail = (item) => {
        setIsShowPopupTransactionDetail(true)
        dispatch(getDetailTransReducer({
            uuid,
            transId: item.id
        }))
    }

    const _handleClosePopupTransactionDetail = () => {
        setIsShowPopupTransactionDetail(false)
        dispatch(clearDetailsTransaction())
    }

    return (
        <styles.Bound>
            <Header />
            <SideMenu />
            <Switch>
                <Route path={`/${paths.MAIN}/${paths.HOME}`}>
                    <HomePage />
                </Route>
                <Route path={`/${paths.MAIN}/${paths.TRANSACTION_HISTORY}`}>
                    <TransactionHistoryPage
                        handleShowPopupDetail={_handleShowPopupTransactionDetail} />
                </Route>
                <Route path={`/${paths.MAIN}/${paths.CALL_HISTORY}`}>
                    <CallHistoryPage
                        handleShowPopupDetail={_handleShowPopupTransactionDetail} />
                </Route>
                <Redirect to={`/${paths.MAIN}/${paths.HOME}`} />
            </Switch>
            {
                isShowPopupTransactionDetail &&
                <TransactionDetail
                    item={detailTransactionData}
                    onClosePopup={_handleClosePopupTransactionDetail}
                />
            }
            <VideoCall />
            {/* <TransactionDetail /> */}
            {/* <CallDetail /> */}
        </styles.Bound>
    )
})
