import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepThreeDetails = styled(StepDetailsStyles)`
    height: 167px;
    @keyframes showStepThreeAnimation{
        from{ height: 0; }
        to{ height: 177px; };
    }
    font-family: "SVN-Gilroy";
    font-style: normal;
    font-size: 14px;
    color: #191919;
    .wrap-page {
        grid-auto-flow: column;
        width: calc(100% - 32px);
        padding: 16px;
        display: grid;
        grid-template-columns: 288px 1fr 280px;
        grid-template-rows: repeat(4, 20px);
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        .wrap-content {
            display: grid;
            grid-template-columns: 100px 1fr;
            grid-auto-flow: column;
            align-items: baseline;
        }
        .detail, .detail-address {
            font-weight: normal;
            line-height: 128%;
            color: #191919;
        }
        .detail-address {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .title {
            font-weight: normal;
            line-height: 15px;
            color: #757575;
        }
    }
`;

export {
    StepThreeDetails
}