import React from 'react'

export default function IconDashboard(props) {
    return (
        <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0678 1.66663C20.1436 1.66663 23.8889 5.5784 23.8889 11.5709L14.0678 11.4877V1.66663ZM12.7362 12.9026H22.474C22.474 19.0616 18.3125 23.8888 12.0703 23.8888C6.3275 23.8888 1.66666 19.228 1.66666 13.4852C1.74989 7.15976 6.66041 3.08152 12.7362 3.16475V12.9026ZM21.1423 14.151H11.4877V4.41319C6.74364 4.74611 2.99833 8.65789 2.99833 13.4852C2.9151 18.4789 6.99333 22.6404 12.0703 22.6404C16.8144 22.6404 20.8094 18.9783 21.1423 14.151ZM15.3995 2.99829L15.3163 10.2392H22.6404C22.3075 6.4107 19.228 3.33121 15.3995 2.99829Z" fill="white"/>
        </svg>

    )
}
