import React, { useEffect, useState, useRef } from 'react'
//styles
import * as styles from './index.styles'
//image
import IcCloseBtn from '../../../assets/images/IcCloseBtn'
// component
import Portal from '../../Control/Portal'
import PopupHeader from './PopupHeader'
import PopupContent from './PopupContent'
//mockData
import { transactionDetailMockData } from '../../../assets/mockData/transactionDetailMockData'


const TransactionDetail = ({ item, onClosePopup }) => {
    const boundRef = useRef()
    useEffect(() => {
        document.body.style.overflow = "hidden"
        setTimeout(() => {
            boundRef.current.style.opacity = 1
        }, 150);
        return () => {
            document.body.style.overflow = ""
        }
    }, [])

    return (
        <Portal>
            <styles.Overlay ref={boundRef}>
                <styles.PopupContainer>
                    <PopupHeader data={item} />
                    <PopupContent data={item} />
                    <div className='close-btn' onClick={() => onClosePopup()} >
                        <IcCloseBtn />
                    </div>
                </styles.PopupContainer>
            </styles.Overlay>
        </Portal>
    )
}

export default TransactionDetail
