import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//styles
import * as styles from './index.styles'
import WrapperPage from '../../CustomStyles/PageWrapper'
//component
import BreadCrumbControl from '../../Control/BreadCrumbControl'
import DropDownFilterCustomerInfo from './DropDownFilterCustomerInfo'
import DropDownFilterTimeReceived from './DropDownFilterTimeReceived'
import DropDownFilterCallStatus from './DropDownFilterCallStatus'
import DropDownFilterBranch from './DropDownFilterBranch'
import DropDownFilterTransactionType from './DropDownFilterTransactionType'
import DropDownFilterSupportStaff from './DropDownFilterSupportStaff'
import ItemCallHistory from './ItemCallHistory'
import Pagination from '../../Control/Pagination'
import EmptyDataControl from '../../Control/EmptyDataControl'
//mockData
import { callHistoryMockData } from '../../../assets/mockData/callHistoryMockData'
//action
import { getListCallHistory } from '../../../redux/callHistory/listCallHistory/action'
import CallDetail from '../CallDetail'

const total = 100
const pageSize = 10
const pageNumber = 1
export default function CallHistoryPage({ handleShowPopupDetail }) {

    const dispatch = useDispatch()

    const [data, setData] = useState([])
    const [detailData, setDetailData] = useState({})
    const [isShowPopup, setIsShowPopup] = useState(false)

    const { listCallHistory, condFilters, uuid, total, system } = useSelector(state => ({
        listCallHistory: state.callHistoryReducer.listCallHistoryReducer,
        condFilters: state.callHistoryReducer.listCallHistoryReducer.condFilters,
        uuid: state.loginReducer.dataLogin.uuid,
        total: state.callHistoryReducer.listCallHistoryReducer.total,
        system: state.systemReducer
    }))

    useEffect(() => {
        dispatch(getListCallHistory({ ...condFilters, uuid }))
    }, [])

    useEffect(() => {
        if (listCallHistory?.result)
            setData(listCallHistory.result)
    }, [JSON.stringify(listCallHistory?.result)])

    useEffect(() => {
        if(!system) return
        if(system.type === 'CLOSE_POPUP_VIDEO_CALL')
            dispatch(getListCallHistory({ ...condFilters, uuid }))
        return () => {
            
        }
    }, [system])

    const _onChangePreviousPage = () => {
        dispatch(getListCallHistory({
            ...condFilters,
            uuid,
            pageNumber: condFilters.pageNumber - 1,
        }))
    }

    const _onChangeNextPage = () => {
        dispatch(getListCallHistory({
            ...condFilters,
            uuid,
            pageNumber: condFilters.pageNumber + 1,
        }))
    }

    const _handleShowPopupDetail = (item) => {
        setIsShowPopup(true)
        setDetailData(item)
    }

    return (
        <WrapperPage>
            <styles.Bound>
                <div className="title-container">
                    <BreadCrumbControl title="lịch sử cuộc gọi" />
                </div>
                <div className="table-container">
                    <div className="header-table">
                        <div className="filter-wrap">
                            <DropDownFilterCustomerInfo />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterTransactionType />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterBranch />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterSupportStaff />
                        </div>
                        <div className="filter-wrap">
                            <DropDownFilterTimeReceived />
                        </div>
                        <div className="filter-wrap" style={{paddingRight: '30px'}} >
                            <DropDownFilterCallStatus justifyContent='flex-end'/>
                        </div>
                    </div>
                    <div className="content-table">
                        {
                            total === 0 ?
                                <EmptyDataControl />
                                :
                                <>
                                    {
                                        data.map((item, index) => (
                                            <div className='item-container' key={index} onClick={() => _handleShowPopupDetail(item)}>
                                                <ItemCallHistory data={item} />
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
                    <div className="footer-table" style={total === 0 ? { backgroundColor: '#fff' } : {}}>
                        {
                            total !== 0 &&
                            <Pagination
                                total={total}
                                pageNumber={condFilters.pageNumber}
                                pageSize={condFilters.pageSize}
                                onChangePreviousPage={_onChangePreviousPage}
                                onChangeNextPage={_onChangeNextPage}
                            />
                        }
                        {
                            isShowPopup &&
                            <CallDetail
                                data={detailData}
                                onClosePopup={() => {
                                    setIsShowPopup(false)
                                    setDetailData({})
                                }}
                                handleShowPopupDetail={handleShowPopupDetail}
                            />
                        }
                    </div>
                </div>
            </styles.Bound>
        </WrapperPage>
    )
}
