import React, { useEffect, useState } from 'react'
import IcNext from '../../../assets/images/IcNext'
import IcPrevious from '../../../assets/images/IcPrevious'

import { StylePagination } from './index.styles'

const Pagination = (props) => {

    const { total, pageNumber, pageSize } = props
    const { onChangePreviousPage, onChangeNextPage } = props

    const [pageChoice, setPageChoice] = useState(pageNumber)
    const [totalPage, setTotalPage] = useState(Math.ceil(total / pageSize))

    useEffect(() => {
        onChangePage(pageNumber)
    }, [pageNumber])

    useEffect(() => {
        setTotalPage(Math.ceil(total / pageSize))
        console.log(setTotalPage);
    }, [total])



    const onClickPreviousPage = () => {
        if (pageChoice === 1) return;
        onChangePreviousPage()
    }

    const onClickNextPage = () => {
        if (pageChoice === totalPage) return;
        onChangeNextPage()
    }

    const onChangePage = (page) => {
        let choice = document.getElementById('choice')
        if (!choice) return;
        setPageChoice(page)
    }

    return (
        <StylePagination>
            <div className='btn-previous'
                data-testid="btn-previous"
                style={pageChoice === 1 ? { opacity: 0, cursor: 'unset' } : {}}
                onClick={() => onClickPreviousPage()} >
                <IcPrevious />
            </div>
            <div className='paging-container' id="choice" data-testid="paging-container">
                <div className='current-page'>{pageChoice}</div>

                <div className='total-page'>{`/${totalPage}`}</div>
            </div>
            <div className='btn-next'
                data-testid="btn-next"
                style={pageChoice === totalPage ? { opacity: 0, cursor: 'unset' } : {}}
                onClick={() => onClickNextPage()} >
                <IcNext />
            </div>
        </StylePagination>
    )
}

export default Pagination
