import styled from 'styled-components'

const Bound = styled.div`
    /* width: calc(100% - 38px); */
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding: 0 38px 0 0; */
    .avatar{
        width: 36px;
        height: 36px;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 12px 0 0;
    }
    .info{
        display: flex;
        flex-direction: column;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: normal;
        color: #222222;
        margin: 0 36px 0 0;
        .name{   
            font-weight: bold;         
            font-size: 16px;
            line-height: 21px;            
        }
        .position{
            display: flex;
            align-items: center;            
            font-size: 12px;
            line-height: 130%;
            .number{
                padding-right: 3px;
            }
        }
    }
    .icon{
        cursor: pointer;
    }
`

export { Bound }