import styled from 'styled-components'

const Bound = styled.div`
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid rgba(25, 25, 25, 0.1);
        .active {
            width: 10px;
            height: 10px;
            transform: translate(20%, 20%);
            border-radius: 50%;
            background: ${props => props.isActive ? "linear-gradient(180deg, #3CB512 0%, #048548 100%)" : "unset"};
        }   
    
`

export {
    Bound
}