import React from 'react'

const IcReactionSmile = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                    <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#FFCE52" />
                    <path d="M9.00004 14.8695C6.19517 14.8695 3.91309 12.9384 3.91309 10.5652H4.69569C4.69569 12.5068 6.62678 14.0869 9.00004 14.0869C11.3733 14.0869 13.3044 12.5068 13.3044 10.5652H14.087C14.087 12.9384 11.8049 14.8695 9.00004 14.8695Z" fill="#273941" />
                    <path d="M7.43453 7.82634H6.65192C6.65192 6.96312 6.12522 6.26112 5.47801 6.26112C4.83079 6.26112 4.30409 6.96312 4.30409 7.82634H3.52148C3.52148 6.53152 4.39918 5.47852 5.47801 5.47852C6.55683 5.47852 7.43453 6.53152 7.43453 7.82634Z" fill="#273941" />
                    <path d="M14.4785 7.8261H13.6959C13.6959 6.96288 13.1692 6.26088 12.522 6.26088C11.8747 6.26088 11.348 6.96288 11.348 7.8261H10.5654C10.5654 6.53127 11.4431 5.47827 12.522 5.47827C13.6008 5.47827 14.4785 6.53127 14.4785 7.8261Z" fill="#273941" />
                    <path d="M8.99998 1.17378C13.7543 1.17378 17.6087 4.50261 17.6087 8.60856H17.9902C17.8823 6.29676 16.888 4.11536 15.2138 2.51748C13.5397 0.919608 11.3143 0.0280762 8.99998 0.0280762C6.68566 0.0280762 4.46029 0.919608 2.78611 2.51748C1.11193 4.11536 0.117642 6.29676 0.00976562 8.60856H0.391287C0.391287 4.50261 4.24563 1.17378 8.99998 1.17378Z" fill="#FFE369" />
                    <path d="M17.6087 8.60864C17.6087 12.7146 13.7543 16.0434 9 16.0434C4.24565 16.0434 0.391304 12.7146 0.391304 8.60864H0.0097826C0.00430434 8.73856 0 8.86886 0 8.99995C3.55683e-08 11.3869 0.948212 13.6761 2.63604 15.3639C4.32387 17.0517 6.61305 18 9 18C11.3869 18 13.6761 17.0517 15.364 15.3639C17.0518 13.6761 18 11.3869 18 8.99995C18 8.86886 17.9957 8.73856 17.9902 8.60864H17.6087Z" fill="#FFB32B" />
                    <path d="M14.4044 3.45359C14.5305 3.32753 14.4905 3.08315 14.3151 2.90775C14.1397 2.73235 13.8953 2.69234 13.7693 2.81839C13.6432 2.94445 13.6832 3.18882 13.8586 3.36423C14.034 3.53963 14.2784 3.57964 14.4044 3.45359Z" fill="#F6FAFD" />
                    <path d="M11.799 1.53293C11.8621 1.46983 11.8422 1.34758 11.7545 1.25987C11.6668 1.17217 11.5445 1.15223 11.4814 1.21533C11.4183 1.27844 11.4383 1.40069 11.526 1.48839C11.6137 1.57609 11.7359 1.59603 11.799 1.53293Z" fill="#F6FAFD" />
                    <path d="M13.5497 2.98829C13.8018 2.73619 13.7216 2.24731 13.3707 1.89635C13.0197 1.54539 12.5308 1.46525 12.2787 1.71735C12.0266 1.96946 12.1068 2.45834 12.4577 2.8093C12.8087 3.16026 13.2976 3.2404 13.5497 2.98829Z" fill="#F6FAFD" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={18} height={18} fill="white" />
                </clipPath>
                <clipPath id="clip1">
                    <rect width={18} height={18} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcReactionSmile
