import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
    const elRef = useRef()

    useEffect(() => {
        return () => {
            elRef.current && document.body.removeChild(elRef.current);
            elRef.current = null
        }
    }, [])

    if(!elRef.current) {
        elRef.current = document.createElement('div');
        document.body.appendChild(elRef.current);
    }

    return createPortal(children, elRef.current)
}

export default Portal
