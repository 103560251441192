import styled from 'styled-components'

const WrapDetailCall = styled.div`
    background: #F3F3F3;
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 1;
    overflow: auto;
    animation: show-popup-detail-call;
    animation-delay: 0.5s;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    @keyframes show-popup-detail-call{
        from{ top: 100% }
        to{ top: 0 }
    }
    .wrapper {
        width: 100%;
        min-width: 1440px;
        min-height: 100%;
        background: #F3F3F3;
        display: grid;
        grid-template-columns: 1fr 400px;
        grid-template-rows: 72px auto;

        .header {
            grid-column: 1/3;
            background: #FFFFFF;
            border-bottom: 4px solid #016730;
            box-sizing: border-box;
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: #222222;
        }
    
        .transaction-infos {
            /* min-width: calc(100% - 400px); */
        }
    }

`;

export {
    WrapDetailCall
}