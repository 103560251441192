import styled from 'styled-components'

const Bound = styled.div`
    width: 522px;
    height: 450px;
    margin: 100px auto;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 40px 38px 43px 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 26px;
        color: #016730;
    }
    .text{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #191919;
        margin: 10px 0 39px 0;
    }
    .input-wrap{
        width: 100%;
        display: flex;
        flex-direction: column;
        &:first-of-type{
            margin: 0 0 30px 0;             
        }      
        .title-input{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 128%;
            color: #7A7A7A;
            margin: 0 0 10px 10px;
        }
        .input{
            width: 100%;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid rgba(1, 103, 48, 0.4);
            box-sizing: border-box;
            border-radius: 3px;
            padding: 0 10px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #191919;
            ::placeholder{
                color: rgba(25, 25, 25, 0.3);
            }
            &.err{
                border: 2px solid #EC1E25;
            }
        }
    }
    .error{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 128%;
        color: #F31919;
        margin: 3px 0 30px 0;
        width: 100%;
        height: 12px;
    }
    .button-container{
        display: flex;
        align-items:center;
        justify-content: center;
        height: 50px;
        width: 196px;
        background: #016730;
        border-radius: 50px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        .icon{
            margin: 0 13px 0 0;
        }
    }

`

export { Bound }