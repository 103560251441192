import * as types from './types'

const intitialState = {
    details: {},
};

const detailTransReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_DETAIL_TRANSACTION: {
            return {
                ...state,
                ...action.payload
            }
        }
        
        case types.CLEAR_DETAIL_TRANSACTION: {
            return {
                ...intitialState,
            }
        }
        
        default:
            return state
    }
};
export default detailTransReducer

