import React, { useEffect } from 'react'
//styles
import * as styles from './index.styles'
//tool
import { convertDateTime } from '../../../../helper/tools'
//constant
import ratingTypes from '../../../../assets/constant/ratingTypes'
//image
import IcCycleCardMini from '../../../../assets/images/IcCycleCardMini'

const PopupHeader = (props) => {
    const { data } = props

    const _handleCheckRating = (reviewType) => {
        const { message, Icon } = ratingTypes[reviewType] || {}
        return (
            <div className="content">
                {!!Icon && <Icon />}
                <p className="message">{message}</p>
            </div>
        )
    }

    return (
        <styles.Bound type={data?.eKYCType}>
            <div className='title'>
                <IcCycleCardMini className="icon" />
                {data?.eKYCType === 1 ? 'Mở thẻ tự động' : 'Mở thẻ với hỗ trợ'}
            </div>
            <div className='line-container'>
                <p className="label">Thời gian gửi yêu cầu:</p>
                <p className="content">
                    {data?.createAt ? convertDateTime(data?.createAt, "DD/MM/YYYY HH:mm:ss") : "../.."}
                </p>
            </div>
            <div className='line-container'>
                <p className="label">Kiosk tiếp nhận:</p>
                <div className="content">
                    <p>{data?.kioskInfo?.code || ""}</p>
                    <span>-</span>
                    <p>{data?.kioskInfo?.name || ""}</p>
                </div>
            </div>
            {
                data?.eKYCType === 2 &&
                <>
                    <div className='line-container'>
                        <p className="label">GDV tiếp nhận:</p>
                        <p className="content">{data?.userName ? `@ ${data.userName}` : "-"}</p>
                    </div>
                    <div className='line-container'>
                        <p className="label">Thời gian tiếp nhận:</p>
                        <p className="content">
                            {data?.timeReception ? convertDateTime(data?.timeReception, "DD/MM/YYYY HH:mm:ss") : "../.."}
                        </p>
                    </div>
                </>
            }
            <div className='line-container'>
                <p className="label">Chi nhánh:</p>
                <p className="content">{data?.kioskInfo?.branchInfo?.name || "-"}</p>
            </div>
            <div className='line-container'>
                <p className="label">Đánh giá:</p>
                {_handleCheckRating(data?.reviewType)}
            </div>
        </styles.Bound>
    )
}

export default PopupHeader
