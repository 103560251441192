import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

// component
import Portal from '../../Control/Portal'
import PopupNotifyIncomingCall from './PopupNotifyIncomingCall';
import PopupShowDetailCall from './PopupShowDetailCall';

// socket
import WsListenCall from './WsListenCall';
import WsConnectRTC from './WsConnectRTC';
// action
import { closePopupVideoCall } from '../../../redux/system/action'
// host-api
import { apiGetKioskInfos, apiRegisterStream } from '../../../assets/constant/host'

const initialStatePhoneInfos = {
    pickupTime: null,
    startTimeHavePhone: null
}

const VideoCall = () => {
    const wsListenCallRef = useRef()
    const wsConnectRTCRef = useRef()
    const hangupRef = useRef()
    const rtcConfigRef = useRef()
    const dispatch = useDispatch()

    const [showDetailCall, setShowDetailCall] = useState(false);

    const [streamID, setStreamID] = useState("");
    const [incomingCall, setIncomingCall] = useState(false);
    const [dataRTCConfig, setDataRTCConfig] = useState(null);
    const [kioskData, setKioskData] = useState({});
    const [phoneInfos, setPhoneInfos] = useState({...initialStatePhoneInfos});

    const { loginReducer } = useSelector(state => ({
        loginReducer: state.loginReducer
    }))

    useEffect(() => {
        return () => { 
            wsListenCallRef.current.disconnect()
        }
    }, [])

    useEffect(() => {
        if(!loginReducer.loggedIn) return;
        
        const registerStream = async () => {
            const dataRequest = {
                Name: loginReducer.dataLogin.uuid
            }
            try {
                const res = await axios.post(apiRegisterStream, dataRequest)
                const { data } = res;
                console.log(data)
                const isSuccess = data.Code === 0;
                if(isSuccess) {
                    setStreamID(data.ReceiverID)
                }
                else {
                    console.log(data)
                }
            }
            catch(err) {
                console.dir(err)
            }
        }
        registerStream()
    }, [loginReducer.loggedIn])

    useEffect(() => {
        if(!streamID) return;
        const wsListenCall = wsListenCallRef.current = new WsListenCall(streamID)
        wsListenCall.on((evt) => {
            console.log(evt)
            if(incomingCall) return;
            const message = JSON.parse(evt.data)
            const { data: { senderName } } = message
            getDetailsKiosk(senderName)
            setIncomingCall(true)
            setPhoneInfos(pre => ({
                ...pre,
                startTimeHavePhone: moment()
            }))
            // {"notification":{},"data":{"senderId":"6006a72b6eecb0021b66e44e","senderName":"40663480"}}
        })
    }, [streamID])

    useEffect(() => {
        if(!incomingCall) return;
        const wsConnectRTC = wsConnectRTCRef.current = new WsConnectRTC(streamID)
        wsConnectRTC.on((evt) => {
            console.log(evt)
            if(typeof(evt.data) !== 'string') {
                handleHangup()
                return;
            }
            const dataRTCConfigs = JSON.parse(evt.data)
            setDataRTCConfig(dataRTCConfigs)
        })

    }, [incomingCall])

    useEffect(() => {
        const handleBeforeUnload = function(event){
            return wsConnectRTCRef.current?.disconnect()
        };
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, []);

    const getDetailsKiosk = async (kioskId) => {
        const dataRequest = JSON.stringify({
            uuid: loginReducer.dataLogin.uuid,
            kioskId
        })
        try {
            const res = await axios.post(apiGetKioskInfos, dataRequest)
            const { data } = res;
            const isSuccess = data.code === 0;
            if(isSuccess) {
                setKioskData(data.result)
            }
            else {
                console.log(data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleHangup = () => {
        rtcConfigRef.current?.hangupAction()
        setShowDetailCall(false)
        setIncomingCall(false)
        setKioskData({})
        setPhoneInfos({...initialStatePhoneInfos})
        if(wsConnectRTCRef.current)
            wsConnectRTCRef.current.close()
        dispatch(closePopupVideoCall())
    }

    hangupRef.current = handleHangup

    return (
        <>
            {
                incomingCall &&
                <Portal>
                    <PopupNotifyIncomingCall 
                        setShowDetailCall={setShowDetailCall}
                        setIncomingCall={setIncomingCall}
                        socketRTC={wsConnectRTCRef}
                        setPhoneInfos={setPhoneInfos}
                    />
                </Portal>
            }
            {
                showDetailCall &&
                <Portal>
                    <PopupShowDetailCall 
                        socketRTC={wsConnectRTCRef}
                        hangupRef={hangupRef}
                        dataRTCConfig={dataRTCConfig}
                        rtcConfigRef={rtcConfigRef}
                        kioskData={kioskData}
                        phoneInfos={phoneInfos}
                    />
                </Portal>
            }
        </>
    )
}

// export default VideoCall
export default React.memo(VideoCall)
