import React, { useState, useEffect } from 'react'

// constant
import { CCCD, CMND, CMND12, PASSPORT } from '../../../../../../../assets/constant/identityCardTypes';

// container
import StepContainer from '../StepContainer'

// styles
import { StepTwoDetails } from './index.styles'

const Figure = ({ title, srcImg }) => {
    const [isError, setIsError] = useState(false);

    return (
        <div className="wrap-id-card-img">
            <div className="title">{title}</div>
            {
                isError || !srcImg ?
                <div className="img-id-card" />
                :
                <img 
                    src={srcImg}
                    alt="title" 
                    className="img-id-card"
                    onError={() => setIsError(true)}
                />

            }
        </div>
    )
}

const checkFrontBack = {
    "0": "Mặt trước",
    "1": "Mặt sau"
}

const StepTwo = ({ idCardType, status, listIdCard=[] }) => {
    const [registerCard, setRegisterCard] = useState(null);

    useEffect(() => {
        switch(idCardType) {
            case CMND:
            case CMND12: 
            case CCCD:
            {
                const registerCard = (
                    <>
                        {[1,2].map((_, index) => (
                            <Figure key={index} title={checkFrontBack[index]} srcImg={listIdCard[index]}  />
                        ))}
                    </>
                )
                setRegisterCard(registerCard)
                break;
            }
            case PASSPORT: {
                const registerCard = (
                    <Figure title="Hình ảnh" srcImg={listIdCard[0]} />
                )
                setRegisterCard(registerCard)
                break;
            }
            default:
                break;
        }
    }, [idCardType, listIdCard]);

    return (
        <StepContainer title='BƯỚC 2: CHỤP HÌNH GIẤY TỜ' status={status} animationName='showStepTwoAnimation'>
            <div className='show-container'>
                <StepTwoDetails>
                    {registerCard}
                </StepTwoDetails>
            </div>
        </StepContainer>
    )
}

export default StepTwo
