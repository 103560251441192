import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-bread{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 35px;
        text-transform: uppercase;
        color: #000000;
    }

`
export { Bound }