import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
// tools
import { convertDateTime } from '../../../../../../helper/tools'
// styles
import { WrapCallInfos } from './index.styles'
//images
import IcVideoCallRed from '../../../../../../assets/images/IcVideoCallRed';
import IcCardMini from '../../../../../../assets/images/IcCardMini';
import AvatarDefault from '../../../../../../assets/images/AvatarDefault'
// socket
import RTCConfig from './RTCConfig';

const convertToTwoNum = (num) => ("0" + num).slice(-2)

const CallInfos = ({ 
    socketRTC, 
    dataRTCConfig, 
    hangupRef, 
    rtcConfigRef,
    kioskData,
    phoneInfos
}) => {

    const remoteVideoRef = useRef()
    const timeStart = useRef()
    const [data, setData] = useState({});
    const [timeRecord, setTimeRecord] = useState(null)
    let intervalRecord = null

    useEffect(() => {
        if(remoteVideoRef.current)
            remoteVideoRef.current.addEventListener('play', handlePlayVideo)
        return () => {
            clearInterval(intervalRecord)
            if(remoteVideoRef.current)
                remoteVideoRef.current.removeEventListener('play', handlePlayVideo)
        }
    }, [])

    useEffect(() => {
        if(!dataRTCConfig) return;
        const rtcConfig = rtcConfigRef.current = new RTCConfig(
            null, 
            remoteVideoRef.current,
            dataRTCConfig,
            socketRTC.current,
            hangupRef.current
        )
        rtcConfig.initial()
    }, [dataRTCConfig])

    const handlePlayVideo = () => {
        timeStart.current = moment()
        intervalRecord = setInterval(() => {
            let duration = moment.utc(moment().diff(timeStart.current)).format("HH:mm:ss")
            setTimeRecord(duration)
        }, 1000);
    }

    const totalWaitingTime = (() => {
        const { pickupTime, startTimeHavePhone } = phoneInfos
        if(!pickupTime || !startTimeHavePhone) return;

        const diff = pickupTime.diff(startTimeHavePhone)
        const duration = moment.duration(diff)

        const durationAsHours = duration.asHours()
        const hour = Math.floor(durationAsHours)
        const minutes = duration.minutes()
        const seconds = duration.seconds()

        return `${convertToTwoNum(hour)}:${convertToTwoNum(minutes)}:${convertToTwoNum(seconds)}`
    })()

    return (
        <WrapCallInfos>
            <div className="call-title">THÔNG TIN CUỘC GỌI</div>
            <div className="wrap-call">
                <div className="wrap-col-1">
                    <p className="title title-video">VIDEO CALL</p>
                    <div className="wrap-video">
                        {
                            timeRecord &&
                            <div className="time-record">
                                <span className="red-dot"></span>
                                <p className="time">{timeRecord}</p>
                            </div>
                        }
                        <video autoPlay playsInline className="video-call" ref={remoteVideoRef}>
                            
                        </video>
                        <IcVideoCallRed 
                            className="end-call-btn"
                            onClick={hangupRef.current}
                        />
                    </div>
                </div>
                <div className="wrap-scroll">
                    <div className="wrap-col-2">
                        <div className="title">
                            thông tin khách hàng
                        </div>
                        <div className="customer-info">
                            <AvatarDefault className="avatar"/>
                            <div className="customer-title">
                                <p className="p-firstline">Khách hàng vãng lai</p>
                                <p>-</p>
                            </div> 
                        </div>
                        <div className="wrap-detail">
                            <div className="wrap-left">
                                Giao dịch thực hiện                              
                            </div>
                            <div className="wrap-right">
                                <IcCardMini className="card-style"/>
                                Mở thẻ hỗ trợ
                            </div>
                        </div>
                    </div>
                    <div className="wrap-col-2">
                        <div className="title">
                            thông tin cuộc gọi
                        </div>
                        <div className="wrap-detail">
                                <div className="wrap-left">
                                    Thời gian tiếp nhận                                
                                </div>
                                <div className="wrap-right">
                                    {phoneInfos.pickupTime?.format('DD/MM/YYYY - HH:mm:ss')}
                                </div>
                        </div>
                        <div className="wrap-detail">
                                <div className="wrap-left">
                                    Thời gian chờ tiếp nhận
                                </div>
                                <div className="wrap-right">
                                    {totalWaitingTime}
                                </div>
                        </div>
                    </div>
                    <div className="wrap-col-3">
                    <div className="title">
                        thông tin thiết bị
                    </div>
                    <div className="wrap-detail">
                        <div className="wrap-left">
                            Mã máy KIOSK                              
                        </div>
                        <div className="wrap-right">
                            {kioskData.code || '-'}
                        </div>
                    </div>
                    <div className="wrap-detail">
                            <div className="wrap-left">
                                Tên máy                               
                            </div>
                            <div className="wrap-right wrap-name" title={kioskData.name}>
                                {kioskData.name || '-'}
                            </div>
                    </div>
                    <div className="wrap-detail">
                            <div className="wrap-left">
                                Địa điểm                              
                            </div>
                            <div className="wrap-right wrap-name" title={kioskData.branchInfo?.address}>
                                {kioskData.branchInfo?.address || '-'}
                            </div>
                    </div>
                    <div className="wrap-detail">
                            <div className="wrap-left">
                                Chi nhánh                                
                            </div>
                            <div className="wrap-right">
                                {kioskData.branchInfo?.name || '-'}
                            </div>
                    </div>
                </div>
                </div>
            </div>
        </WrapCallInfos>
    )
}

export default CallInfos
