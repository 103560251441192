import React from 'react'

const IcDenyCallGray = (props) => {
  return (
    <svg {...props} width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.36167 6.79423C5.20167 8.44506 6.555 9.79256 8.20583 10.6384L9.48917 9.35506C9.64667 9.19756 9.88 9.14506 10.0842 9.21506C10.7375 9.43089 11.4433 9.54756 12.1667 9.54756C12.4875 9.54756 12.75 9.81006 12.75 10.1309V12.1667C12.75 12.4876 12.4875 12.7501 12.1667 12.7501C6.68917 12.7501 2.25 8.3109 2.25 2.8334C2.25 2.51256 2.5125 2.25006 2.83333 2.25006H4.875C5.19583 2.25006 5.45833 2.51256 5.45833 2.8334C5.45833 3.56256 5.575 4.26256 5.79083 4.9159C5.855 5.12006 5.80833 5.34756 5.645 5.5109L4.36167 6.79423Z" fill="#808080" />
      <path d="M9.93653 4.48031L11.9605 2.4563L11.548 2.04382L9.52405 4.06783L7.50004 2.04384L7.08756 2.45632L9.11157 4.48031L7.08765 6.50425L7.50013 6.91673L9.52405 4.89279L11.548 6.91671L11.9605 6.50423L9.93653 4.48031Z" fill="#808080" />
    </svg>


  )
}
export default IcDenyCallGray;