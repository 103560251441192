import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepSixDetails = styled(StepDetailsStyles)`
    height: 252px;
    @keyframes showStepSixAnimation{
        from{ height: 0; }
        to{ height: 262px; };
    }

    padding: 16px;
    display: flex;

    .form-receive-card {
        width: 311px;
        flex-shrink: 0;

        .form-receive-card-item {
            min-height: 58px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            box-sizing: border-box;
            border-radius: 12px;
            padding: 20px 17px 10px 20px;

            &:not(:first-child) {
                margin-top: 6px;
            }

            &.selected {
                & > .wrap-title > .title, & > .description {
                    color: #191919;
                }
            }

            .wrap-title {
                display: flex;
                align-items: center;

                .title {
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    color: #19191980;
                    flex-grow: 1;
                    margin-left: 10px;
                }
            }

            .description {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 140%;
                color: #19191940;
            }
        }
    }

    .form-receive-card-details {
        margin-left: 60px;
        font-family: 'SVN-Gilroy';

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 170%;
            color: #191919;
        }

        .receive-at-home-details-item {
            display: grid;
            grid-template-columns: 130px auto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;

            &:nth-child(2) {
                margin-top: 9px;
            }

            &:not(:nth-child(2)) {
                margin-top: 23px;
            }

            & > *:first-child {
                line-height: 15px;
                color: rgba(25, 25, 25, 0.6);
            }

            & > *:last-child {
                line-height: 128%;
                color: #191919;
            }
        }

        .branch-name {
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #016730;
            margin-top: 10px;
        }

        .receive-at-branch-details-item {
            display: flex;

            &:nth-child(3) {
                margin-top: 11px;
            }

            &:last-child {
                margin-top: 6px;
            }

            & > :last-child {
                margin-left: 11px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 140%;
                color: #191919;
            }
        }
    }
`;

export {
    StepSixDetails
}