import React, { useEffect } from 'react'
//styles
import * as styles from './index.styles'
//image
import card_vbbank from '../../../../../assets/images/CardDefault/card_vpbank.svg'
//constant
import bankCards from '../../../../../assets/constant/bankCards'
import formReceiveCardTypes from '../../../../../assets/constant/formReceiveCardTypes'

const CardInfo = (props) => {
    const { data } = props

    const Icon = data?.cardInfo?.cardType ?
        bankCards[data?.cardInfo?.cardType]?.Icon ?? (() => null)
        :
        (() => null)

    return (
        <styles.Bound>
            <div className="image-container">
                {
                    data?.cardInfo?.cardType ?
                        <div className="image-card">
                            <Icon />
                        </div>
                        :
                        <div className="empty-card"></div>
                }

                <div className="info-card">
                    <p className="card-number">{data?.cardInfo?.cardNumber || "-"}</p>
                    {
                        data?.cardInfo?.cardType ?
                            <p className="card-type">
                                {bankCards[data.cardInfo.cardType].nameVi}
                            </p>
                            :
                            "-"
                    }
                    <div className="account-number">
                        <p className="role">Số tài khoản:</p>
                        <p className="number">{data?.customerInfo?.accountNumber || "-"}</p>
                    </div>
                </div>
            </div>
            <div className="details-container">
                <div className="details-content">
                    <div className="detail">
                        <p className="title">Loại thẻ</p>
                        <p className="content">{data?.cardInfo?.type || "Thẻ ghi nợ"}</p>
                    </div>
                    <div className="detail">
                        <p className="title">Chủ thẻ</p>
                        <p className="content">{data?.customerInfo?.identityCardInfo?.fullName || "-"}</p>
                    </div>
                    <div className="detail">
                        <p className="title">Ngày tạo</p>
                        <p className="content">{data?.cardInfo?.issuedDate || "../.."}</p>
                    </div>
                    <div className="detail">
                        <p className="title">Ngày hết hạn</p>
                        <p className="content">{data?.cardInfo?.expiredDate || "../.."}</p>
                    </div>
                </div>
                <div className="details-content">
                    <div className="detail">
                        <p className="title">Phương thức nhận thẻ</p>
                        {
                            data?.cardInfo?.methodOfReceiving?.type ?
                                <p className="content">
                                    {formReceiveCardTypes[data.cardInfo.methodOfReceiving.type].textStep}
                                </p>
                                :
                                "-"
                        }
                    </div>
                    <div className="detail">
                        <p className="title">Người nhận</p>
                        <p className="content">{data?.cardInfo?.methodOfReceiving?.fullName || "-"}</p>
                    </div>
                    <div className="detail">
                        <p className="title">Điện thoại</p>
                        <p className="content">{data?.cardInfo?.methodOfReceiving?.phoneNumber || "-"}</p>
                    </div>
                    <div className="detail">
                        <p className="title">Địa chỉ</p>
                        {
                            data?.cardInfo?.methodOfReceiving?.homeInfo?.province ?
                                <p className="content address">
                                    {`${data.cardInfo.methodOfReceiving.homeInfo.apartmentNumber}, ${data.cardInfo.methodOfReceiving.homeInfo.wards}, ${data.cardInfo.methodOfReceiving.homeInfo.district}, ${data.cardInfo.methodOfReceiving.homeInfo.province}`}
                                </p>
                                :
                                "-"
                        }
                    </div>
                </div>
            </div>
        </styles.Bound>
    )
}

export default CardInfo
