import React from 'react'

const LogoVPBankFull = (props) => {
    return (
        <svg {...props} width={144} height={31} viewBox="0 0 144 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0-vpbank-full)">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.754414 14.2235C1.24147 14.3438 1.66765 14.2837 2.15471 14.2837C3.9203 14.2837 5.68588 14.3438 7.45147 14.2837C8.36471 14.2837 9.15618 14.5242 9.94765 14.9451C11.835 15.9673 13.2962 17.4105 14.7574 18.8536C15.975 20.0562 17.1318 21.2588 18.2885 22.4615C18.4712 22.6418 18.5929 22.8222 18.5929 23.1229C18.5929 25.5281 18.5321 27.8732 18.5321 30.2784C18.5321 30.7595 18.3494 30.6994 17.9841 30.5791C16.7665 30.1582 15.7924 29.4366 14.8182 28.5948C12.8091 26.851 11.0435 24.9268 9.21706 23.0628C6.35559 20.1765 3.67677 17.1098 0.754414 14.2837C0.754414 14.2837 0.754414 14.2235 0.754414 14.2235Z" fill="#EC1E25" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.36 0.874481C27.2991 1.05487 27.2991 1.23527 27.2991 1.47579C27.2991 4.2418 27.2991 7.00782 27.2991 9.77383C27.2991 11.9385 26.6294 13.8026 25.1074 15.366C23.3418 17.23 21.5762 19.0941 19.8106 20.9581C19.6279 20.8379 19.6888 20.7176 19.6888 20.5974C19.6888 16.8091 19.6888 13.0209 19.6888 9.23265C19.6888 8.81174 19.9324 8.63135 20.115 8.39082C22.4894 5.86533 24.8638 3.39997 27.1774 0.874481C27.2991 0.874481 27.2991 0.874481 27.36 0.874481Z" fill="#EC1E26" />
            <path fillRule="evenodd" clipRule="evenodd" d="M134.696 24.3857C134.087 24.8667 133.539 25.3478 132.686 25.468C132.686 24.0249 132.686 22.6419 132.686 21.1988C133.904 19.9961 135.061 18.7334 136.279 17.5308C136.583 17.2301 136.887 16.9896 137.192 16.7491C138.044 16.0876 138.957 15.6667 140.114 15.787C141.149 15.8471 142.184 15.787 143.219 15.787C143.219 15.8471 143.219 15.8471 143.219 15.9073C142.245 16.7491 141.332 17.7713 140.419 18.6733C139.323 19.7556 138.227 20.838 137.192 21.9203C136.218 22.6419 135.426 23.4837 134.696 24.3857Z" fill="#EC1E25" />
            <path fillRule="evenodd" clipRule="evenodd" d="M91.5909 18.6131C93.6609 19.4549 94.8785 20.8981 95.4265 22.8824C96.4006 26.5503 94.3915 29.4967 90.5559 30.098C89.6426 30.2183 88.7903 30.2784 87.8771 30.2784C85.5027 30.2784 83.0674 30.2784 80.6929 30.2784C80.3276 30.2784 80.2059 30.1582 80.2059 29.7974C80.2059 22.9425 80.2059 16.0876 80.2059 9.23269C80.2059 8.87191 80.2668 8.75165 80.6929 8.75165C83.3109 8.75165 85.9897 8.75165 88.6076 8.75165C89.7035 8.75165 90.6776 8.99217 91.6518 9.53335C93.6609 10.6157 94.3915 12.3595 94.2088 14.5242C94.0871 16.3882 93.3565 17.7713 91.5909 18.6131ZM83.8588 23.6039C83.8588 24.566 83.8588 25.5281 83.8588 26.4902C83.8588 26.6706 83.7979 26.9111 84.1023 26.9111C85.8679 26.851 87.5726 26.9713 89.3382 26.851C90.7994 26.7909 92.1997 25.1072 91.7735 23.3033C91.3474 21.6797 90.1297 20.9582 88.6685 20.5974C87.2073 20.2366 85.6244 20.417 84.1632 20.3569C83.8588 20.3569 83.9197 20.5974 83.9197 20.7778C83.7979 21.7399 83.8588 22.702 83.8588 23.6039ZM83.7979 14.6445C83.7979 15.1856 83.7979 15.7268 83.7979 16.268C83.7979 17.2902 83.7979 17.3503 84.8329 17.2902C85.9897 17.2902 87.2073 17.4105 88.3641 17.2301C89.8253 16.9896 90.7385 15.7869 90.6168 14.2837C90.5559 12.9007 89.6426 12.119 88.1206 11.9987C86.8421 11.8784 85.5027 11.9386 84.2241 11.8784C83.7979 11.8784 83.7371 11.9987 83.7371 12.3595C83.7979 13.2013 83.7979 13.9229 83.7979 14.6445Z" fill="#048548" />
            <path fillRule="evenodd" clipRule="evenodd" d="M62.6718 19.5151C62.6718 16.1477 62.6718 12.7203 62.6718 9.35298C62.6718 8.93206 62.7935 8.8118 63.2197 8.8118C66.0812 8.87193 68.9427 8.75167 71.865 8.93206C75.2744 9.05232 77.4662 11.217 77.7097 14.5242C77.8315 16.1477 77.5879 17.651 76.7965 19.034C75.7615 20.7778 74.2394 21.6798 72.2303 21.9203C70.5865 22.1007 68.9426 22.1007 67.2379 22.1007C66.8118 22.1007 66.69 22.221 66.69 22.6419C66.69 25.0471 66.69 27.3922 66.69 29.7974C66.69 30.1582 66.6291 30.3386 66.2029 30.2785C65.2288 30.2183 64.1938 30.2183 63.2197 30.2785C62.7935 30.2785 62.7327 30.1582 62.7327 29.7974C62.6718 26.37 62.6718 22.9425 62.6718 19.5151ZM66.6291 15.4863C66.6291 16.5085 66.6291 17.4706 66.6291 18.4928C66.6291 18.7334 66.6291 18.8536 66.9335 18.8536C68.3338 18.8536 69.6732 18.9138 71.0735 18.7334C73.0218 18.4928 74.1176 17.2301 74.0568 15.3059C73.9959 13.502 72.7782 12.2994 70.83 12.1791C69.5515 12.0589 68.2729 12.119 67.0553 12.119C66.69 12.119 66.6291 12.2393 66.6291 12.5399C66.6291 13.502 66.6291 14.4641 66.6291 15.4863Z" fill="#048548" />
            <path fillRule="evenodd" clipRule="evenodd" d="M51.7738 26.37C52.9915 23.4837 53.9656 20.5974 54.6962 17.651C55.4268 14.885 55.9747 12.119 56.2182 9.29283C56.2791 8.87191 56.4009 8.75165 56.8271 8.75165C57.8012 8.81178 58.8362 8.75165 59.8103 8.75165C60.1147 8.75165 60.1756 8.81178 60.1147 9.11243C59.2624 15.1255 57.8012 20.9582 55.305 26.5503C54.8179 27.6928 54.27 28.7752 53.7221 29.8575C53.6003 30.0981 53.4785 30.2183 53.1741 30.2183C52.1391 30.2183 51.1041 30.2183 50.0691 30.2183C49.7038 30.2183 49.5212 30.0981 49.3994 29.7974C47.2685 25.8288 45.8073 21.6196 44.7115 17.2902C44.0418 14.5843 43.4938 11.8784 43.0676 9.17256C43.0068 8.81178 43.1285 8.75165 43.4329 8.75165C44.4071 8.75165 45.3812 8.81178 46.3553 8.75165C46.8423 8.75165 46.9641 8.93204 47.025 9.35296C47.3294 11.6981 47.7556 14.0432 48.3035 16.3281C49.095 19.5752 50.0691 22.7621 51.4085 25.8288C51.5303 25.949 51.6521 26.1294 51.7738 26.37Z" fill="#048548" />
            <path fillRule="evenodd" clipRule="evenodd" d="M113.63 22.7621C113.63 20.4772 113.63 18.1321 113.63 15.8471C113.63 15.4863 113.691 15.3059 114.117 15.3059C114.787 15.3661 115.457 15.3059 116.126 15.3059C116.796 15.3059 116.918 15.4262 116.979 16.0876C116.979 16.2079 116.979 16.3282 116.979 16.4484C116.979 16.8092 117.101 16.8693 117.405 16.6288C118.744 15.7268 120.266 15.0654 121.91 15.0053C124.711 14.885 126.111 16.0876 126.111 18.8536C126.172 22.5216 126.111 26.1896 126.111 29.7974C126.111 30.0981 125.989 30.2183 125.685 30.1582C124.65 30.1582 123.615 30.1582 122.58 30.1582C122.215 30.1582 122.093 30.038 122.093 29.6772C122.093 26.2497 122.093 22.8824 122.093 19.455C122.093 18.1321 121.362 17.5308 120.023 17.8314C119.11 18.0118 118.318 18.4327 117.527 18.8536C117.222 19.034 117.283 19.2144 117.283 19.5151C117.283 22.8824 117.283 26.2497 117.283 29.5569C117.283 29.9778 117.161 30.1582 116.735 30.0981C115.822 30.038 114.909 30.038 113.996 30.0981C113.569 30.0981 113.448 29.9778 113.448 29.5569C113.63 27.3922 113.63 25.0471 113.63 22.7621Z" fill="#048548" />
            <path fillRule="evenodd" clipRule="evenodd" d="M107.177 28.5948C106.264 29.3765 105.289 30.0379 104.133 30.3386C103.219 30.5791 102.245 30.6993 101.332 30.4588C99.8709 30.1582 98.8968 29.3163 98.5315 27.8131C98.2271 26.6105 98.1662 25.4078 98.5315 24.1451C99.0794 22.3412 100.541 21.5595 102.245 21.1386C103.646 20.7778 105.107 20.6575 106.568 20.6575C106.933 20.6575 107.055 20.5373 106.994 20.2366C106.994 20.1765 106.994 20.1163 106.994 20.0562C106.994 18.2523 106.142 17.4706 104.315 17.651C102.915 17.7712 101.576 18.0719 100.297 18.6131C99.9926 18.7333 99.8709 18.6732 99.7491 18.3725C99.5665 17.8314 99.2621 17.3503 99.0794 16.8091C98.8968 16.3882 98.9577 16.1477 99.4447 15.9673C101.515 15.3059 103.646 14.7647 105.898 14.885C106.629 14.9451 107.42 15.0052 108.09 15.2458C109.673 15.7268 110.343 16.8693 110.586 18.3725C110.647 18.8536 110.708 19.3346 110.708 19.8157C110.708 22.281 110.708 24.6863 110.708 27.1516C110.708 28.0536 110.647 29.0157 110.83 29.9176C110.891 30.2183 110.83 30.3386 110.464 30.3386C109.734 30.3386 108.942 30.3386 108.212 30.3386C107.907 30.3386 107.725 30.2183 107.603 29.9176C107.481 29.4967 107.359 29.0758 107.177 28.5948ZM107.055 24.6863C107.055 24.2654 106.994 23.8444 107.055 23.4837C107.116 23.0627 106.933 22.9425 106.568 23.0026C106.142 23.0026 105.655 23.0026 105.229 23.0026C102.854 23.0026 101.758 24.0248 102.124 26.0091C102.124 26.1294 102.124 26.2497 102.124 26.3699C102.306 27.6327 103.159 28.1739 104.437 27.8732C105.107 27.6928 105.655 27.3922 106.264 27.0314C106.811 26.6706 107.238 26.2497 107.055 25.468C106.994 25.1673 107.055 24.9268 107.055 24.6863Z" fill="#048548" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.5273 14.2235C36.4923 15.2458 35.5791 16.2079 34.6659 17.1699C31.3782 20.5974 28.2124 24.0249 24.8029 27.332C23.8897 28.234 22.8547 29.136 21.7588 29.8575C21.3326 30.1582 20.8456 30.3987 20.2976 30.5791C19.6888 30.7595 19.6888 30.7595 19.6888 30.1582C19.6888 28.4745 19.6888 26.7909 19.6888 25.1072C19.6888 24.9268 19.6888 24.7464 19.6888 24.566C19.5062 23.3033 19.8715 22.4013 20.8456 21.4994C22.6721 19.7556 24.3159 17.8314 26.2641 16.268C26.7512 15.9072 27.1774 15.5464 27.7253 15.2458C28.7603 14.5843 29.8562 14.2235 31.1347 14.2837C33.0221 14.3438 34.9703 14.2837 36.8576 14.2837C37.1012 14.2235 37.2229 14.2235 37.5273 14.2235Z" fill="#EC1E25" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8609 0.874481C11.7132 1.77644 12.5047 2.55814 13.2962 3.39997C14.94 5.08363 16.5229 6.76729 18.1668 8.45095C18.3494 8.63134 18.5321 8.81174 18.5321 9.11239C18.5321 12.9608 18.5321 16.8693 18.5321 20.7176C18.5321 20.7778 18.532 20.8379 18.4712 20.9581C17.8623 20.3568 17.3144 19.7555 16.7665 19.1542C15.3662 17.7111 13.9659 16.3281 12.6873 14.8248C11.4088 13.5621 10.8609 12.0588 10.8 10.315C10.7391 7.24834 10.8 4.18167 10.8 1.17513C10.8 1.115 10.8 1.05487 10.8609 0.874481Z" fill="#EC1D25" />
            <path fillRule="evenodd" clipRule="evenodd" d="M132.747 21.1987C132.747 22.6418 132.747 24.0248 132.747 25.468C132.747 26.9712 132.747 28.4144 132.747 29.9176C132.747 30.2784 132.626 30.3386 132.321 30.3386C131.347 30.3386 130.373 30.2784 129.399 30.3386C128.912 30.3386 128.912 30.1582 128.912 29.7974C128.912 23.2431 128.912 16.6889 128.912 10.0745C128.912 9.65359 128.729 9.11241 128.973 8.87189C129.216 8.6915 129.764 8.81176 130.19 8.81176C130.921 8.81176 131.651 8.81176 132.321 8.81176C132.686 8.81176 132.808 8.93202 132.808 9.29281C132.808 13.081 132.808 16.8091 132.808 20.5974C132.686 20.7778 132.747 20.9582 132.747 21.1987Z" fill="#048548" />
            <path fillRule="evenodd" clipRule="evenodd" d="M134.696 24.3856C135.426 23.4836 136.218 22.7019 137.131 21.9804C138.957 24.7464 140.784 27.5124 142.671 30.3385C141.149 30.3385 139.749 30.3385 138.349 30.3385C138.044 30.3385 138.044 30.098 137.922 29.9176C136.887 28.0536 135.67 26.2497 134.696 24.3856Z" fill="#048548" />
        </g>
        <defs>
            <clipPath id="clip0-vpbank-full">
                <rect width="143.074" height="30.6667" fill="white" transform="translate(0.450001 0.333313)" />
            </clipPath>
        </defs>
        </svg>
    )
}

export default LogoVPBankFull
