import * as routePaths from './path'
import IconDashboard from '../images/IconDashboard'
import IconCallHistory from '../images/IconCallHistory'
import IconTransactionHistory from '../images/IconTransactionHistory'

const data = [
    {
        title: 'Dashboard',
        key: 'dashboard',
        logo: <IconDashboard />,
        path: `/${routePaths.MAIN}/${routePaths.HOME}`
    },
    {
        title: 'Lịch sử giao dịch',
        key: 'transaction-history',
        logo: <IconTransactionHistory />,
        path: `/${routePaths.MAIN}/${routePaths.TRANSACTION_HISTORY}`
    },
    {
        title: 'Lịch sử cuộc gọi',
        key: 'call-history',
        logo: <IconCallHistory />,
        path: `/${routePaths.MAIN}/${routePaths.CALL_HISTORY}`
    },
    
]

export default data