import { combineReducers } from 'redux';

import loginReducer from './login'
import transactionReducer from './transaction'
import callHistoryReducer from './callHistory'
import dashboardReducer from './dashboard'
import systemReducer from './system'

const appReducer = combineReducers({
    loginReducer,
    transactionReducer,
    callHistoryReducer,
    dashboardReducer,
    systemReducer
})

const mainReducer = (state, action) => {
  
    return appReducer(state, action)
}

export default mainReducer