import React from 'react'

export default function IcArrowDownBlack(props) {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9.54297L12 15.4573L18 9.54297" stroke="#191919" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
