import styled from 'styled-components'

const Bound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .image-container{
        display: flex;
        margin-bottom: 57px;
        .image-card{
            width: 257px;
            height: 163px;
            border-radius: 5px;
        }
        .empty-card{
            width: 257px;
            height: 163px;
            border-radius: 5px;
            background-color: grey;
        }
        .info-card{
            display: flex;
            flex-direction: column;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 600;
            margin-left: 33px;
            .card-number{                
                font-size: 30px;
                line-height: 128%;
                color: #212121;
                margin: 19px 0 12px 0;
            }
            .card-type{
                font-size: 14px;
                line-height: 24px;
                color: #191919;
                margin-bottom: 19px;
            }
            .account-number{
                display: flex;
                align-items: center;
                font-size: 20px;
                    line-height: 26px;
                .role{
                    color: rgba(25, 25, 25, 0.6);
                }
                .number{                                        
                    color: #016730;
                    margin-left: 3px;
                }
            }
        }
    }
    .details-container{
        display: grid;
        grid-template-columns: 408px 408px;
        grid-gap: 32px;
        .details-content{
            display: flex;
            flex-direction: column;
            .detail{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 14px;
                :last-child{
                    margin-bottom: 0;
                }
                .title{
                    color: rgba(25, 25, 25, 0.6);
                    width: fit-content;
                }
                .content{
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: #191919;
                    text-align: right;
                    &.address{
                        max-width: 252px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        justify-self: right;
                    }
                }
            }
        }
    }
`

export { Bound }