import styled from 'styled-components'

const WrapperHeader = styled.div`
    width: calc(100% - 90px);
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
`

export default WrapperHeader