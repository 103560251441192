import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
//styles
import WrapperHeader from '../../CustomStyles/HeaderWrapper'
import * as styles from './index.styles'
//image
import IcLogOut from '../../../assets/images/IcLogOut'
import AvatarDefault from '../../../assets/images/AvatarDefault'
//component
import ClockControl from '../../Control/BreadCrumbControl/ClockControl'
//action
import { logoutAction } from '../../../redux/login/action'

export default function Header() {

    const dispatch = useDispatch()
    const history = useHistory()

    const { uuid, displayName } = useSelector(state => ({
        uuid: state.loginReducer.dataLogin.uuid,
        displayName: state.loginReducer.dataLogin.displayName,
    }))
    
    const _handleLogOut = () => {
        dispatch(logoutAction(uuid, history))
    }
    return (
        <WrapperHeader>
            <ClockControl />
            <styles.Bound>
                <AvatarDefault className="avatar"></AvatarDefault>
                <div className="info">
                    <p className="name">{displayName ?? '-'}</p>
                    <div className="position">
                        <p className="number">STVP123</p>
                        <p>- Giao dịch viên</p>
                    </div>
                </div>
                <IcLogOut className="icon" onClick={_handleLogOut} />
            </styles.Bound>
        </WrapperHeader>
    )
}
