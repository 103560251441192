import React, { Children } from 'react'
//style
import * as styles from './index.styles'
//image
import IcArrowDownGrey from '../../../assets/images/IcArrowDownGrey'

const TitleDropDownFilter = ({ children, textTranForm, isDisable, marginRight, justifyContent}) => {
    return (
        <styles.Bound textTranForm={textTranForm} marginRight={marginRight} justifyContent={justifyContent}>
            <div className="title">{children}</div>
            {/* {
                !isDisable &&
                <IcArrowDownGrey className="icon" />
            } */}
        </styles.Bound>
    )
}

export default TitleDropDownFilter
