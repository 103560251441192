import React from 'react'

// styles
import { WrapStepContainer } from './index.styles'

// component
import Step from './Step'

const stepStatusTypes = {
    "PASSED": "PASSED",
    "INPROGRESS": "INPROGRESS",
}

const StepContainer = ({ children, title, status, animationName, isHideArrow }) => {
    return (
        <WrapStepContainer animationName={animationName}>
            <Step title={title} status={status} isHideArrow={isHideArrow}/>
            {
                (status === stepStatusTypes['PASSED'] || status === stepStatusTypes['INPROGRESS']) &&
                children
            }
        </WrapStepContainer>
    )
}

export default StepContainer
