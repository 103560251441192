import React from 'react'
//style
import * as styles from './index.styles'
//component
import TitleDropDownFilter from '../../../Control/TitleDropdownFilter'


const DropDownFilterCallStatus = ({justifyContent}) => {
    return (
        <styles.Bound>
            <TitleDropDownFilter children="Trạng thái cuộc gọi" marginRight="33px" justifyContent={justifyContent}/>
        </styles.Bound>
    )
}

export default DropDownFilterCallStatus
