import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    align-items: center;
    .date-container{
        display: flex;
        align-items: center;
        &:first-of-type{
            margin-right: 15px;
        }
    }
    p{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #222222;
    }
    .icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    
`

export {
    Bound
}