import styled from 'styled-components'

const WrapIdentity = styled.div`  
    width: 380px;
    height: 240px;
    border-radius: 10px;
    position: relative;

    &:hover {
        .zoom {
            visibility: visible;
        }
    }

    & > img {
        display: block;
    }

    .identity {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
        background-color: #FFFFFF;
    }
    
    .zoom {
        visibility: hidden;
        position: absolute;
        right: 10px;
        bottom: 10px;

        &:hover {
            cursor: pointer;
        }
    }
`;

export {
    WrapIdentity
}