import React, { useState, useEffect } from 'react'
//style
import * as styles from './index.styles'
//tool
import { convertDateTime } from '../../../../helper/tools'
//image
import IcCheck from '../../../../assets/images/IcCheck'
import IcCardMini from '../../../../assets/images/IcCardMini'
import AvatarDefault from '../../../../assets/images/AvatarDefault'
//api
import { apiAvatarImage } from '../../../../assets/constant/host'


const ItemTransactionHistory = ({ data }) => {

    const [isErrImg, setIsErrImg] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [JSON.stringify(data.id)])

    return (
        <styles.Bound className='item-bound'>
            <div className="trans-type">
                <IcCardMini className="icon" />
                <p>Mở tài khoản</p>
            </div>
            <div className="info-customer-wrap">
                <div className="avatar-wrap">
                    {
                        isErrImg ?
                            <AvatarDefault className="avatar" />
                            :
                            <img
                                className="avatar"
                                src={apiAvatarImage(data?.customerInfo?.customerId)}
                                alt="avatar"
                                onError={() => setIsErrImg(true)}
                            />
                    }
                </div>
                <div className="info">
                    <p className="name" title={data?.customerInfo?.fullName || ""}>
                        {data?.customerInfo?.fullName || "-"}
                    </p>
                    <p className="phone" title={data?.customerInfo?.phoneNumber || ""}>
                        {data?.customerInfo?.phoneNumber || "-"}
                    </p>
                </div>
            </div>
            <div className="info-kiosk-wrap">
                <div className="info-kiosk">
                    <p title={data?.kioskInfo?.code || ""}>
                        {data?.kioskInfo?.code || "-"}
                    </p>
                    <p className="location" title={data?.kioskInfo?.name || ""}>
                        {`- ${data?.kioskInfo?.name}` || "-"}
                    </p>
                </div>
                <p className="branck-kiosk" title={data?.kioskInfo?.branchInfo.name || ""}>
                    {data?.kioskInfo?.branchInfo.name || "-"}
                </p>
            </div>
            <div className="type-ekyc-wrap">
                {
                    data?.eKYCType === 1 ?
                        <p>KH tự thực hiện</p>
                        :
                        <p>Qua Video</p>
                }
            </div>
            <div className="time-wrap">
                <p>{data?.createAt ? convertDateTime(data?.createAt, "DD/MM/YYYY") : "-"}</p>
                <p className="time">{data?.createAt ? convertDateTime(data?.createAt, "HH:mm") : "-"}</p>
            </div>
            <div className="status-wrap">
                <div className="icon-received">
                    <IcCheck className="icon" />
                    <p>HOÀN TẤT</p>
                </div>
                {/* {
                    data?.Status === "RECEIVED" ?
                        <div className="icon-received">
                            <IcCheck className="icon" />
                            <p>HOÀN TẤT</p>
                        </div>
                        :
                        <>
                            {
                                data?.Status === "PENDING" ?
                                    <div className="icon-pending">
                                        <IcClockMini className="icon" />
                                        <p>ĐANG GIAO DỊCH</p>
                                    </div>
                                    :
                                    <div className="icon-cancel">
                                        <IcCancel className="icon" />
                                        <p>KHÔNG HOÀN TẤT</p>
                                    </div>
                            }
                        </>
                } */}
                {/* <div className="status-time">{data?.StatusTime ? convertDateTime(data?.StatusTime, "HH:mm:ss") : "-"}</div> */}
            </div>
        </styles.Bound>
    )
}

export default ItemTransactionHistory
