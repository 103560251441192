import React, { useState } from 'react'
//styles
import * as styles from './index.styles'
//component
import CustomerInfo from './CustomerInfo'
import CardInfo from './CardInfo'
import CallInfo from './CallInfo'

const PopupContent = (props) => {
    const { data } = props

    const [step, setStep] = useState('customer-info')

    return (
        <styles.Bound>
            <div className='tab-switch'>
                <div className={`switcher ${step === 'customer-info' ? 'active' : ''}`}
                    onClick={() => setStep('customer-info')}>
                    Thông tin khách hàng
                </div>
                <div className={`switcher ${step === 'card-info' ? 'active' : ''}`}
                    onClick={() => setStep('card-info')}>
                    Thông tin thẻ
                </div>
                {
                    data?.eKYCType !== 1 &&
                    <div className={`switcher ${step === 'call-info' ? 'active' : ''}`}
                        onClick={() => setStep('call-info')}>
                        Thông tin cuộc gọi
                    </div>
                }
            </div>
            {
                step === 'customer-info' &&
                <CustomerInfo data={data} />
            }
            {
                step === 'card-info' &&
                <CardInfo data={data} />
            }
            {
                data?.eKYCType !== 1 && step === 'call-info' &&
                <CallInfo data={data} />
            }
        </styles.Bound>
    )
}

export default PopupContent
