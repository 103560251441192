import styled from 'styled-components'

const Bound = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title-container{
        margin-bottom: 30px;
    }
    .table-container{        
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 10px;
        .header-table{
            display: grid;
            align-items: center;
            grid-template-columns: minmax(170px, 200px) minmax(270px, 1fr) minmax(270px, 1fr) minmax(150px, 180px) minmax(195px, 150px) minmax(110px, 140px); 
            grid-gap: 15px;
            width: calc(100% - 40px);
            height: 85px;
            border-bottom: 1px solid #016730;
            margin: 0 20px 10px 20px;
            .filter-wrap{
                &.status{
                    padding-right: 20px
                }
            }
        }
        .content-table{
            display: grid;
            grid-template-rows: repeat(8, 70px);
            .item-container{
                :last-child{
                    .item-bound{
                        box-shadow: none;
                    }
                }
            }
        }
        .footer-table{
            width: calc(100% - 42px - 20px);
            height: 73px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 42px 0 20px;
            background: #F8F8F8;
            border-radius: 0px 0px 10px 10px;
        }
    }
`

export { Bound }