import React from 'react'
const IcCardGray = (props) => {
  return (
    <svg {...props} width={16} height={12} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.25 8.25H6.5C6.69891 8.25 6.88968 8.17098 7.03033 8.03033C7.17098 7.88968 7.25 7.69891 7.25 7.5C7.25 7.30109 7.17098 7.11032 7.03033 6.96967C6.88968 6.82902 6.69891 6.75 6.5 6.75H4.25C4.05109 6.75 3.86032 6.82902 3.71967 6.96967C3.57902 7.11032 3.5 7.30109 3.5 7.5C3.5 7.69891 3.57902 7.88968 3.71967 8.03033C3.86032 8.17098 4.05109 8.25 4.25 8.25ZM13.25 0.75H2.75C2.15326 0.75 1.58097 0.987053 1.15901 1.40901C0.737053 1.83097 0.5 2.40326 0.5 3V9.75C0.5 10.3467 0.737053 10.919 1.15901 11.341C1.58097 11.7629 2.15326 12 2.75 12H13.25C13.8467 12 14.419 11.7629 14.841 11.341C15.2629 10.919 15.5 10.3467 15.5 9.75V3C15.5 2.40326 15.2629 1.83097 14.841 1.40901C14.419 0.987053 13.8467 0.75 13.25 0.75ZM14 9.75C14 9.94891 13.921 10.1397 13.7803 10.2803C13.6397 10.421 13.4489 10.5 13.25 10.5H2.75C2.55109 10.5 2.36032 10.421 2.21967 10.2803C2.07902 10.1397 2 9.94891 2 9.75V5.25H14V9.75ZM14 3.75H2V3C2 2.80109 2.07902 2.61032 2.21967 2.46967C2.36032 2.32902 2.55109 2.25 2.75 2.25H13.25C13.4489 2.25 13.6397 2.32902 13.7803 2.46967C13.921 2.61032 14 2.80109 14 3V3.75Z" fill="#7A7A7A" />
    </svg>

    
  )
}
export default IcCardGray;
