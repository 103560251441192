import React, { useState, useEffect, useCallback } from 'react'
//styles
import * as styles from './index.styles'
//image
import AvatarDefault from '../../../../../assets/images/AvatarDefault'
import IcExpand from '../../../../../assets/images/IcExpand'
import IcGender from '../../../../../assets/images/IcGender'
import IcIDCard from '../../../../../assets/images/IcIDCard'
import IcMail from '../../../../../assets/images/IcMail'
import IcPhone from '../../../../../assets/images/IcPhone'
import IcCloseBtn from '../../../../../assets/images/IcCloseBtn'
//control
import ModalControl from '../../../../Control/ModalControl'
//component
import identityTypes, { CMND12, CCCD, PASSPORT } from '../../../../../assets/constant/identityCardTypes'
import PopupShowImageIdentityCard from './PopupShowImageIdentityCard'
//api
import { apiAvatarImage } from '../../../../../assets/constant/host'
import genderType from '../../../../../assets/constant/genderType'
import IdetityCard from './ IdentityCard'
import CitizenCard from './CitizenCard'
import PasspordCard from './PasspordCard'

const CustomerInfo = (props) => {
    const { data } = props

    const [isZoomAvatar, setIsZoomAvatar] = useState(false)
    const [isErrImg, setIsErrImg] = useState(false)

    const _handleClickZoom = useCallback(
        () => {
            setIsZoomAvatar(!isZoomAvatar)
        },
        [isZoomAvatar],
    )

    useEffect(() => {
        setIsErrImg(false)
    }, [data?.customerInfo?.customerId])

    return (
        <styles.Bound>
            <div className="image-container">
                <div className="avatar-wrap">
                    {
                        isErrImg ?
                            <AvatarDefault className="avatar" />
                            :
                            <img
                                className="avatar"
                                src={apiAvatarImage(data?.customerInfo?.customerId)}
                                alt="avatar"
                                onError={() => setIsErrImg(true)}
                            />
                    }
                    <IcExpand className="icon-expand" onClick={_handleClickZoom} />
                </div>

                {
                    isZoomAvatar &&
                    <ModalControl extendStyles={styles.extendStyles}>
                        <div className="zoom-img">
                            <IcCloseBtn className="close" onClick={_handleClickZoom} />
                            {
                                isErrImg ?
                                    <AvatarDefault className="avatar-zoom" />
                                    :
                                    <img
                                        src={apiAvatarImage(data?.customerInfo?.customerId)}
                                        alt="avatar-zoom"
                                        className="avatar-zoom"
                                        onError={() => setIsErrImg(true)}
                                    />
                            }
                        </div>
                    </ModalControl>
                }
                <div className="customer-info">
                    <p className="name">{data?.customerInfo?.identityCardInfo?.fullName || "-"}</p>
                    <div className="role-wrap">                       
                        <IcGender />
                        {
                            data?.customerInfo?.identityCardInfo?.gender ?
                                <div className="role">
                                    {genderType[data?.customerInfo?.identityCardInfo?.gender].text}
                                </div>
                                :
                                "-"
                        }
                    </div>
                    <div className="role-wrap">
                        <IcPhone />
                        <p className="role">{data?.customerInfo?.phoneNumber || "-"}</p>
                    </div>
                    <div className="role-wrap">
                        <IcMail />
                        <p className="role">{data?.customerInfo?.email || "-"}</p>
                    </div>
                    <div className="role-wrap">
                        <p className="cif-label">CIF</p>
                        <p className="role cif">{data?.customerInfo?.customerId || "-"}</p>
                    </div>
                </div>
            </div>
            <div className="details-container">
                {
                    data?.customerInfo?.identityCardInfo?.type === CCCD ?
                    <CitizenCard data={data}/>
                    :
                    data?.customerInfo?.identityCardInfo?.type === PASSPORT ?
                    <PasspordCard data={data}/>
                    :
                    <IdetityCard data={data}/>
                }
            </div>
        </styles.Bound>
    )
}

export default CustomerInfo
