import styled from 'styled-components'

const Overlay = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    justify-content: center;
    align-items: center;
    opacity: 0;
`
const PopupContainer = styled.div`
    width: 903px;
    height: fit-content;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    display: grid;
    grid-template-rows: repeat(2, auto);
    position: relative;
    /* animation-name: show-popup;
    animation-duration: 0.3s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    @keyframes show-popup{
        from{ opacity: 0 }
        to{ opacity: 1 }
    } */
    .close-btn{
        width: 36px;
        height: 36px;
        cursor: pointer;
        position: absolute;
        top: -10px;
        left: calc(100% + 10px);
    }
`


export { Overlay, PopupContainer }