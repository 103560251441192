import styled from 'styled-components'

const Bound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 17px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #f2f2f2;
        background-clip: padding-box;
        border-radius: 20px;
        border: 6px solid white;
    }
    .dashboard {
        padding-left: 10px;
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #000000;
    }
    p , h1 {
        padding: 0;
        margin: 0;
    }
    .wrap-list {
        width: 100%;
        /* height: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .customer-line {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        height: 150px;
        margin-top: 30px;
        display: grid;
        grid-template-columns: minmax(68px, 130px) minmax(360px, 1fr) minmax(200px, 350px) minmax(180px, 230px);
        grid-column-gap: 20px;
        .green-number {
            color: #016730;
            margin-right: 3px;
        }
        .icon-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .left-col {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: "SVN-Gilroy";
            svg{
                width: 48px;
                height: 48px;
            }
            .icon-style {
                padding-left: 45px;
            }
            .number {
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 34px;
                color: #000000;
                /* padding-left: 54px; */
                padding-right: 30px;
            }
            .teller-number {
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 34px;
                color: #000000;
                padding-left: 50px;
                padding-right: 30px;
            }
            p {
                height: fit-content;
                padding-bottom: 10px;
            }
            .customer {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 128%;
                color: #212121;
            }
        }
        .left-content, .right-content{
            svg{
                margin-right: 5px;
            }
        }
        .childs-col{
            display : flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 35px 0;
            font-size: 14px;
            font-weight: 500;
            
            .child-item-content{
                svg {
                    margin-right: 15px;
                }
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        .right-col {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 175px;
            justify-self: flex-start;
            text-decoration: none;
            padding-right: 10px;
            a{
                display: flex;
                flex-direction: row;
                text-decoration: none;
            }
            .arrow {
                margin-right: 26px;
            }
        }   
    }
`;

export { Bound }