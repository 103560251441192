import React from 'react'

export default function IconCallHistory(props) {
    return (
        <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9985 1.66663C10.8228 1.66663 7.89305 3.25891 6.16168 5.92588L6.99301 6.4659C8.54104 4.08154 11.1599 2.65778 13.9985 2.65778C18.5998 2.65778 22.3431 6.40108 22.3431 11.0026C22.3431 13.8545 20.9095 16.4798 18.5081 18.0256L19.0446 18.859C21.7307 17.13 23.3342 14.1931 23.3342 11.0026C23.3342 5.85458 19.1461 1.66663 13.9985 1.66663Z" fill="white"/>
            <path d="M13.5029 4.28528V11.2891L18.5867 14.224L19.0824 13.3657L14.4941 10.7168V4.28528H13.5029Z" fill="white"/>
            <path d="M16.0095 17.4253C15.0941 17.4253 14.222 17.2877 13.4103 17.0166C13.2144 16.9512 13.0164 16.9182 12.8203 16.9182C12.3729 16.9182 11.9473 17.0892 11.5893 17.4125L11.5704 17.4295L11.5525 17.4473L10.2821 18.7176C9.4522 18.2207 8.69773 17.6345 8.0327 16.9688C7.36526 16.3017 6.77876 15.5471 6.283 14.7183L7.55324 13.4486L7.5712 13.4303L7.58861 13.411C8.05937 12.8884 8.20011 12.2425 7.98436 11.5924C7.7125 10.7761 7.5749 9.902 7.5749 8.99345C7.5749 8.49824 7.39471 8.05656 7.05377 7.71691C6.71598 7.3782 6.27448 7.19763 5.77927 7.19763H2.90674C2.41302 7.19763 1.97208 7.37764 1.63132 7.71802C1.29094 8.05878 1.11111 8.49972 1.11111 8.99345C1.21964 13.1955 2.68988 16.7282 5.48074 19.5194C8.27067 22.3103 11.804 23.7807 15.981 23.8889C15.9817 23.8889 15.9821 23.8889 15.9828 23.8889C16.5008 23.8889 16.941 23.7098 17.2816 23.3702C17.6253 23.0251 17.8044 22.5831 17.8024 22.0897L17.8035 19.2207C17.8035 18.7274 17.6231 18.2868 17.2825 17.9457C16.9431 17.6064 16.5029 17.4264 16.0095 17.4253ZM16.811 22.094C16.8121 22.3233 16.7345 22.5146 16.5819 22.6681C16.4282 22.8212 16.2367 22.8981 16.0071 22.8981C12.0743 22.7959 8.79884 21.4364 6.1817 18.8189C3.56436 16.201 2.20431 12.9258 2.10208 8.99345C2.10208 8.76325 2.17912 8.57232 2.33228 8.41898C2.48543 8.26564 2.67748 8.18878 2.90674 8.18878H5.77946C6.00965 8.18878 6.20096 8.26527 6.3543 8.41898C6.50764 8.57176 6.58394 8.76325 6.58394 8.99345C6.58394 10.0152 6.73783 10.9852 7.04377 11.9043C7.14544 12.2108 7.08247 12.4921 6.85228 12.7475L5.05239 14.5474C5.66482 15.7232 6.42467 16.763 7.33137 17.6695C8.23697 18.5759 9.27794 19.3355 10.453 19.9485L12.2534 18.1483C12.4294 17.9894 12.619 17.9097 12.8203 17.9097C12.9098 17.9097 13.0016 17.9253 13.0961 17.9568C14.0135 18.2633 14.9821 18.4166 16.0016 18.4166C16.0034 18.4166 16.0051 18.4166 16.0069 18.4166C16.2364 18.417 16.4279 18.4933 16.5814 18.6466C16.7344 18.8 16.812 18.9905 16.812 19.2204L16.811 22.094Z" fill="white"/>
        </svg>

    )
}
