import React, { useState } from 'react'
import moment from 'moment'
//styles
import * as styles from './index.styles'
//image
import IcCallMini from '../../../../../assets/images/IcCallMini'
import IcPlayGreen from '../../../../../assets/images/IcPlayGreen'
//tools
import { convertDateTime } from '../../../../../helper/tools'
//constant
import statusCallType from '../../../../../assets/constant/statusCallType'
import { apiGetVideoCallRecord } from '../../../../../assets/constant/host'

const CallInfo = (props) => {
    const { data } = props

    const _handleOpenVideoRecord = () => {
        window.open(apiGetVideoCallRecord(data?.videoCallInfo?.videoId))
    }
    
    return (
        <styles.Bound>
            <div className="details-container">
                <div className="details-content">
                    <div className="detail">
                        <p className="title">Trạng thái cuộc gọi</p>
                        {
                            data?.videoCallInfo?.status ?
                                <div className="content">
                                    <div className="icon">{statusCallType[data.videoCallInfo.status].icon}</div>
                                    <p>{statusCallType[data.videoCallInfo.status].text}</p>
                                </div>
                                :
                                <span>...</span>
                        }
                    </div>
                    <div className="detail">
                        <p className="title">Thời gian chờ tiếp nhận</p>
                        <p className="content">
                            {
                                data?.videoCallInfo?.waitingTime ?
                                    moment().startOf('day').seconds(data.videoCallInfo.waitingTime).format('HH:mm:ss')
                                    :
                                    "-"
                            }
                        </p>
                    </div>
                    <div className="detail">
                        <p className="title">Giao dịch viên tiếp nhận</p>
                        <p className="content">{data?.videoCallInfo?.counterInfo?.fullName || "-"}</p>
                    </div>
                </div>
                <div className="details-content">
                    <div className="detail">
                        <p className="title">Thời gian tiếp nhận</p>
                        <p className="content">
                            {data?.videoCallInfo?.timeReceived ? convertDateTime(data.videoCallInfo.timeReceived,"DD/MM/YYYY - HH:mm:ss") : "../.."}
                        </p>
                    </div>
                    <div className="detail">
                        <p className="title">Thời lượng cuộc gọi</p>
                        <p className="content">
                            {
                                data?.videoCallInfo?.processingTime ?
                                    moment().startOf('day').seconds(data.videoCallInfo.processingTime).format('HH:mm:ss')
                                    :
                                    "-"
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="button-watch-video" onClick={_handleOpenVideoRecord}>
                <p className="text">XEM video cuộc gọi</p>
                <IcPlayGreen />
            </div>
        </styles.Bound>
    )
}

export default CallInfo
