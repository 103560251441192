import { hostWSFormStream } from "../../../../../../assets/constant/host"

const CODE_CLOSE_SAFE = 4000

const emitDev = async (socket) => {
    if(process.env.NODE_ENV !== "development") return;
    const wait = () => new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, 3000)
    })

    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-1","data":{"type":"CMND"}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))

    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-2","data":{"photo":{"front":"60176fc2f59b1a3aed845b0e","back":"60176fc2f59b1a3aed845b11"}}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))

    await wait()
    socket.emit(JSON.stringify({
        "cmd": "FORM-STEP-3",
        "data": {
            "type": "CMND",
            "photo": {
                "front": "asdasdasd1",
                "back": "asdasdasd"
            },
            "idCardNumber": "241684673",
            "passportNumber": "",
            "fullName": "HẠM NGUYÊN KHANG",
            "dateOfBirth": "29/10/1998",
            "gender": 1,
            "hometown": "Xã Yên Giang .Huyêu Yên Định, Thanh Hòá",
            "permanentAddress": "Xã.EaDrông Thị Xã.Buôn Hổ Tĩnh Đắk Lắk",
            "issuedDate": "05/06/2013",
            "issuedPlace": "Lào Cai",
            "expiredDate": "",
            "nationality": "Việt Nam"
        }
    }))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))

    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-4","data":{"faceImage":"60176fd6f59b1a3aed845b14","idCardMatched":true}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))

    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-5","data":{"cardType":"PLATINUM_CASHBACK"}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))
    
    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-6","data":{"methodOfReceivingType":1,"branchInfo":null,"homeInfo":null}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))

    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-7","data":{"IsConfirmed":true}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))

    await wait()
    socket.emit(JSON.stringify({"cmd":"FORM-STEP-8","data":{"reviewType":1}}))
    await wait()
    socket.emit(JSON.stringify({"cmd":"END-STEP"}))
}

class WSFormStream {
    constructor(uuid) {
        this.uuid = uuid
        this.socket = /* process.env.NODE_ENV === "development" ? 
            new WebSocket('wss://echo.websocket.org') 
            :  */
            new WebSocket(hostWSFormStream(uuid))
        this.socket.onopen = e => {
            console.log('socket open', e)
            // emitDev(this)
        }
        this.socket.onclose = e => {
            console.log('socket close', e)
            const { code } = e;
            if(code !== CODE_CLOSE_SAFE) {
                this.reconnect()
            }
        }
        this.socket.onerror = e => {
            console.log('socket error', e)
            const { code } = e;
            if(code !== CODE_CLOSE_SAFE) {
                this.reconnect()
            }
        }
        this.reconnectRef = null
    }

    reconnect = () => {
        this.reconnectRef = setTimeout(() => {
            this.socket = new WebSocket(hostWSFormStream(this.uuid))
        }, 5000);
    }

    disconnect = () => {
        clearTimeout(this.reconnectRef)
        this.socket.close(CODE_CLOSE_SAFE)
        this.socket = null;
    }

    on = (callback) => {
        this.socket.onmessage = callback
    }

    emit = (data) => {
        this.socket.send(data)
    }
}

export default WSFormStream