import CardDiamondWorldLady from "../images/CardDiamondWorldLady"
import CardShopeePlatinum from "../images/CardShopeePlatinum"
import CardStepUpMasterCard from "../images/CardStepUpMasterCard"

const DIAMOND_WORLD_LADY = 'PLATINUM_CASHBACK'
const SHOPEE_PLATINUM = 'SHOPEE_PLATINUM'
const STEPUP_MASTER_CARD = 'AUTOLINK'

const bankCards = {
    [DIAMOND_WORLD_LADY]: {
        type: DIAMOND_WORLD_LADY,
        nameVi: 'Thẻ Diamond World Lady',        
        Icon: CardDiamondWorldLady
    },
    [STEPUP_MASTER_CARD]: {
        type: STEPUP_MASTER_CARD,
        nameVi: 'Thẻ tín dụng VPBank StepUp',
        Icon: CardStepUpMasterCard
    },
    [SHOPEE_PLATINUM]: {
        type: SHOPEE_PLATINUM,
        nameVi: 'Thẻ tín dụng VPBank Shopee Platinum',
        Icon: CardShopeePlatinum
    },
}

export default bankCards