import React from 'react'

const IcHome = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.33166 1.19304C7.54833 1.02452 7.85172 1.02452 8.06839 1.19304L13.7684 5.62637C13.9145 5.74005 14 5.91483 14 6.09998V13.0667C14 13.5617 13.8034 14.0365 13.4533 14.3866C13.1032 14.7367 12.6284 14.9333 12.1334 14.9333H3.26669C2.77162 14.9333 2.29683 14.7367 1.94676 14.3866C1.59669 14.0365 1.40002 13.5617 1.40002 13.0667V6.09998C1.40002 5.91483 1.48551 5.74005 1.63166 5.62637L7.33166 1.19304ZM2.60002 6.39343V13.0667C2.60002 13.2435 2.67026 13.413 2.79529 13.5381C2.92031 13.6631 3.08988 13.7333 3.26669 13.7333H12.1334C12.3102 13.7333 12.4797 13.6631 12.6048 13.5381C12.7298 13.413 12.8 13.2435 12.8 13.0667V6.39343L7.70002 2.42677L2.60002 6.39343Z" fill="#7A7A7A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.19998 7.99998C5.19998 7.66861 5.46861 7.39998 5.79998 7.39998H9.59998C9.93135 7.39998 10.2 7.66861 10.2 7.99998V14.3333C10.2 14.6647 9.93135 14.9333 9.59998 14.9333C9.26861 14.9333 8.99998 14.6647 8.99998 14.3333V8.59998H6.39998V14.3333C6.39998 14.6647 6.13135 14.9333 5.79998 14.9333C5.46861 14.9333 5.19998 14.6647 5.19998 14.3333V7.99998Z" fill="#7A7A7A" />
        </svg>
    )
}

export default IcHome
