import Axios from "axios";
//host
import { host } from "../../assets/constant/host";
//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
//path
import * as paths from '../../assets/constant/path'
//type
import * as types from './types'
import { NETWORK_ERR } from "../notifies/types";
//key
import * as keys from '../../helper/localStore'



const apiLogin = `${host}/office-account/login`;
const apiLogout = (uuid) => `${host}/office-account/logout?uuid=${uuid}`;

/**
 * @param {*any} history
 * @param  {{ email: string, password: string }} dataLogin
 * @param {*any} uuid
 */

export const loginActicon = (history, dataLogin, callbackSuccess, callbackError) => async (dispatch) => {

    const requestData = JSON.stringify(dataLogin);

    try {
        const res = await Axios.post(apiLogin, requestData);
        const { data } = res;
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.LOGIN_SUCCESS,
                data: data,
            });
            history.push(`/${ paths.MAIN }`);
            let userLogin = {
                displayName: data.displayName,
                username: data.username,
                uuid: data.uuid,
                roles: data.roles,
            }
            localStorage.setItem(keys.VPBANK_COUNTER, JSON.stringify({ langKey: 'vn' }));
            localStorage.setItem(keys.VPBANK_COUNTER_LOGIN, JSON.stringify(userLogin));
            callbackSuccess && callbackSuccess()
        }
        else {
            callbackError && callbackError(data.code)
        }
    } catch (error) {
        const response = error?.response;
        if (response) {
            callbackError && callbackError()
            return;
        }

        dispatch({
            type: NETWORK_ERR,
        });
    }
}

export const logoutAction = (uuid, history, callApi = true) => async dispatch => {    
    
    callApi && await Axios.post(apiLogout(uuid))

    localStorage.removeItem(keys.VPBANK_COUNTER_LOGIN);
    dispatch({
        type: types.LOGOUT
    })
    history.push(`/${ paths.LOGIN } `)
}