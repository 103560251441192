import Axios from 'axios'
//host
import { host } from '../../assets/constant/host'
//help
import mapErrors from '../../helper/mapErrors'
//action
import checkIsSuccessAction from '../../helper/checkIsSuccessAction'

//type
import * as types from './types'

const apiDashBoard = `${host}/counter/dashboard/getStatisticsInfo`
export const getDashBoard = ({ uuid }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        uuid,
    })
    try {
        const res = await Axios.post(apiDashBoard, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DASHBOARD,
                payload: {
                    dashboard: {
                        ...data.result
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_DASHBOARD_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_DASHBOARD_ERR))
    }
}