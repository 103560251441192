import moment from 'moment'

export const convertDateTime = (time, format) => {
    return moment(time).format(format);
};

export const buildMoney = value => {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const checkMail = (email) => {
    var filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
        return false;
    } else {
        return true;
    }
};