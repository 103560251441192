export const UNKNOWN = 0
export const MALE = 1
export const FEMALE = 2
export const OTHER = 3


const genderType = {
    [UNKNOWN]: {
        type: UNKNOWN,
        text: ""
    },
    [MALE]: {
        type: MALE,
        text: "Nam"
    },
    [FEMALE]: {
        type: FEMALE,
        text: "Nữ"
    },
    [OTHER]: {
        type: OTHER,
        text: "Khác"
    }
}
export default genderType