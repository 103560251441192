import styled from 'styled-components'

const StylePagination = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    color: #1C1C1C;
    height: 24px;
    .btn-previous, .btn-next{
        display: flex;
        align-items: center;    
        cursor: pointer;
        width: 9px;
    }
    .paging-container{
        display: flex;
        align-items: center;
        padding: 0 7px;
        .current-page{
            color: #016730;
            max-width: 3ch;
        }
        .total-page{
            font-weight: 600;
            width: fit-content;
        }
    }

`
export { StylePagination }