import React from 'react'
//style
import * as style from './index.style'
//constant
import identityTypes, { CMND12 } from '../../../../../../assets/constant/identityCardTypes'
//image
import IcIDCard from '../../../../../../assets/images/IcIDCard'
//component
import PopupShowImageIdentityCard from '../PopupShowImageIdentityCard'

const IdetityCard = ({ data }) => {
    return (
        <style.Bound>
            <div className="details-content">
                <div className="detail">
                    <p className="title">Giấy tờ</p>
                    <div className="content">
                        {
                            identityTypes[data?.customerInfo?.identityCardInfo?.type] ?
                                <>
                                    <IcIDCard className="icon-card" />
                                    <p className="papers">
                                        {identityTypes[data.customerInfo.identityCardInfo.type].text}
                                    </p>
                                    <PopupShowImageIdentityCard
                                        identityCardFront={data?.customerInfo?.identityCardInfo?.photo?.front}
                                        identityCardBack={data?.customerInfo?.identityCardInfo?.photo?.back}
                                        typeIdentityCard={data?.customerInfo?.identityCardInfo?.type}
                                        className="img-identity-card"
                                    />
                                </>
                                :
                                <>
                                    <span>...</span>
                                </>
                        }
                    </div>
                </div>
                <div className="detail">
                    <p className="title">Số CMND</p>
                    <p className="content">{data?.customerInfo?.identityCardInfo?.idCardNumber || "-"}</p>
                </div>
                <div className="detail">
                    <p className="title">Ngày cấp</p>
                    <p className="content">{data?.customerInfo?.identityCardInfo?.issuedDate || "../.."}</p>
                </div>
                <div className="detail">
                    <p className="title">Nguyên quán</p>
                    <p className="content address" title={data?.customerInfo?.identityCardInfo?.hometown || " "}>
                        {data?.customerInfo?.identityCardInfo?.hometown || "-"}
                    </p>
                </div>
            </div>
            <div className="details-content">
                <div className="detail">
                    <p className="title">Ngày sinh</p>
                    <p className="content">{data?.customerInfo?.identityCardInfo?.dateOfBirth || "../.."}</p>
                </div>
                <div className="detail">
                    <p className="title">Quốc tịch</p>
                    <p className="content">{data?.customerInfo?.identityCardInfo?.nationality || "-"}</p>
                </div>
                <div className="detail">
                    <p className="title">Nơi cấp</p>
                    <p className="content">{data?.customerInfo?.identityCardInfo?.issuedPlace || "-"}</p>
                </div>
                {
                    data?.customerInfo?.identityCardInfo?.type === CMND12 &&
                    <>
                        <div className="detail">
                            <p className="title">Ngày hết hạn</p>
                            <p className="content">{data?.customerInfo?.identityCardInfo?.expiredDate || "../.."}</p>
                        </div>
                    </>
                }
                <div className="detail">
                    <p className="title">Thường trú</p>
                    <p className="content address" title={data?.customerInfo?.identityCardInfo?.permanentAddress || " "}>
                        {data?.customerInfo?.identityCardInfo?.permanentAddress || "-"}
                    </p>
                </div>
            </div>
        </style.Bound>
    )
}

export default IdetityCard
