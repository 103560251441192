import React from 'react'
//style
import * as styles from './index.styles'
//component
import TitleDropDownFilter from '../../../Control/TitleDropdownFilter'


const DropDownFilterStatus = () => {
    return (
        <styles.Bound>
            <TitleDropDownFilter children="Trạng thái" isDisable={true}/>
        </styles.Bound>
    )
}

export default DropDownFilterStatus
