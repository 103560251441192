import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepEightDetails = styled(StepDetailsStyles)`
    display: flex;
    justify-content: center;
    height: 123px;
    padding-top: 16px;

    @keyframes showStepEightAnimation{
        from{ height: 0; }
        to{ height: 133px; };
    }
    .wrap-reaction {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 72px;
        p {
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 140%;
            color: #6F7374;
            text-align: center;
            padding-top: 10px;
        }
        
    }

`;
const WrapItem = styled.div`
    .images-style {
        width: 45px;
        height: 45px;
        filter: saturate(${props => props.isActive ? "100%" : "0%"});
    }
`;
export {
    StepEightDetails, WrapItem
}