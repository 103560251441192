import React from 'react'

const IcIncomingCallWhite = (props) => {
  return (
    <svg {...props} width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.36167 6.79414C5.20167 8.44497 6.555 9.79247 8.20583 10.6383L9.48917 9.35497C9.64667 9.19747 9.88 9.14497 10.0842 9.21497C10.7375 9.43081 11.4433 9.54747 12.1667 9.54747C12.4875 9.54747 12.75 9.80997 12.75 10.1308V12.1666C12.75 12.4875 12.4875 12.75 12.1667 12.75C6.68917 12.75 2.25 8.31081 2.25 2.83331C2.25 2.51247 2.5125 2.24997 2.83333 2.24997H4.875C5.19583 2.24997 5.45833 2.51247 5.45833 2.83331C5.45833 3.56247 5.575 4.26247 5.79083 4.91581C5.855 5.11997 5.80833 5.34747 5.645 5.51081L4.36167 6.79414ZM12.26 2.65247L11.8458 2.24414L8.08333 5.91914V3.41664H7.5V6.91664H11V6.33331H8.57917L12.26 2.65247Z" fill="white" />
    </svg>

  )
}
export default IcIncomingCallWhite;