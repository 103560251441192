import React from 'react'
//style
import * as styles from './index.styles'
//component
import ClockControl from './ClockControl'

const BreadCrumbControl = ({title}) => {
    return (
        <styles.Bound>
            <div className="title-bread">{title}</div>
            {/* <ClockControl />             */}
        </styles.Bound>
    )
}

export default BreadCrumbControl
