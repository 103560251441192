import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepOneDetails = styled(StepDetailsStyles)`
    display: flex;
    justify-content: space-between;
    padding: 26px 19px;
    @keyframes showStepOneAnimation{
        from{ height: 0; }
        to{ height: 169px; };
    }
`;
const WrapCardItem = styled.div`
    .id-card-type-item {
        width: 220px;
        height: 107px;
        box-sizing: border-box;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        background: #FFFFFF;
        
        .active-btn {
            position: relative;
            top: 6px;
            left: 6px;
            margin-bottom: 12px;    
        }
        .infos {
            margin: 0px 14px 0 11px;
            display: flex;
            justify-content: space-between;
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #191919;
        }
        .images-style {
            flex-shrink: 0;
            width: 93px;
            height: 60px;
            filter:  saturate(${props => props.isActive ? "100%" : "0%"});
        }
    }
`;

export {
    StepOneDetails, WrapCardItem
}