import React from 'react'

const IcCloseBtn = (props) => {
    return (
        <svg {...props} width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM21.885 12L18 15.885L14.115 12L12 14.115L15.885 18L12 21.885L14.115 24L18 20.115L21.885 24L24 21.885L20.115 18L24 14.115L21.885 12Z" fill="white" />
            </g>
        </svg>
    )
}

export default IcCloseBtn
