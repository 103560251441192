import IcMissedCall from "../images/IcMissedCall"
import IcReceivedCallGreen from "../images/IcReceivedCallGreen"
import IcRefusedCall from "../images/IcRefusedCall"

export const RECEIVED = 1
export const REJECTED = 2
export const MISSED = 3

const statusCallType = {
    [RECEIVED]: {
        key: RECEIVED,
        text: 'Cuộc gọi đã nhận',
        icon: <IcReceivedCallGreen />,
    },
    [REJECTED]: {
        key: REJECTED,
        text: 'Cuộc gọi đã nhận',
        icon: <IcRefusedCall />,
    },
    [MISSED]: {
        key: MISSED,
        text: 'Cuộc gọi nhỡ',
        icon: <IcMissedCall />,
    },    
}

export default statusCallType