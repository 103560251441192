import react from 'react'
// component
import IcReactionLove from '../images/IcReactionLove'
import IcReactionNeutral from '../images/IcReactionNeutral'
import IcReactionSad from '../images/IcReactionSad'
import IcReactionSmile from '../images/IcReactionSmile'

const ratingTypes = {
    [0]: {
        "message": "Chưa đánh giá",
        "code": "0",
        "numCode": 0,
        "Icon": ""
    },
    [1]: {
        "message": "Tuyệt vời",
        "code": "1",
        "numCode": 1,
        "Icon": IcReactionLove
    },
    [2]: {
        "message": "Dùng được",
        "code": "2",
        "numCode": 2,
        "Icon": IcReactionSmile
    },
    [3]: {
        "message": "Khắc phục thêm",
        "code": "3",
        "numCode": 3,
        "Icon": IcReactionNeutral
    },
    [4]: {
        "message": "Khó dùng!",
        "code": "4",
        "numCode": 4,
        "Icon": IcReactionSad
    }
}

export default ratingTypes