import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepTwoDetails = styled(StepDetailsStyles)`
    display: flex;
    padding: 16px;

    height: 216px;
    @keyframes showStepTwoAnimation{
        from{ height: 0; }
        to{ height: 226px; };
    }
    .wrap-id-card-img {
        &:not(:first-child) {
            margin-left: 20px;
        }
        
        .title {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
        }

        .img-id-card {
            width: 238px;
            height: 150px;
            object-fit: contain;
            background: gray;
            border-radius: 10px;
        }
    }
`;

export {
    StepTwoDetails
}