import styled from 'styled-components'

const Bound = styled.div`
    width: calc(100% - 40px);
    height:70px;
    display: grid;
    grid-template-columns: minmax(230px, 320px) minmax(140px, 170px) minmax(190px, 1fr) minmax(190px, 220px) minmax(140px, 170px) minmax(170px, 200px);
    grid-gap: 20px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.3px 0px rgba(0, 0, 0, 0.2);
    margin: 0 20px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #191919;
    cursor: pointer;
    .info-customer-wrap{
        display: grid;
        grid-template-columns: 48px minmax(172px, 1fr);
        grid-gap: 10px;
        align-items: center;      
        .avatar-wrap{
            height: 48px;
            width: 48px;
            border-radius: 50%;
            .avatar{
                height: 48px;
                width: 48px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .info{
            display: flex;
            flex-direction: column;
            width: 100%;
            p{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .phone{
                font-weight: normal;
                color: rgba(25, 25, 25, 0.6);
                margin-top: 2px; 
            }
        }
    }  
    .trans-type{
        display: flex;
        align-items: center;
        .icon{
            margin-right: 12px;
        }
    }
    .branch-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;      
        p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }        
        .branck-kiosk{
            font-weight: normal;
            margin-top: 2px; 
        }
    }
    .user-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;     
        p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }        
        .full-username{
            font-weight: normal;
            margin-top: 2px; 
        }
    }
    .time-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .time{
            font-weight: normal;
        }
    }
    .status-wrap{
        display: grid;
        grid-template-columns: 82px 1fr;
        grid-gap: 15px;
        align-items: center;
        justify-items: flex-end;
        .icon-received{            
            height: 26px;
            background: #016730;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            color: #ffffff;
            padding: 0 8px; 
        }
        .icon-missed{
            height: 26px;
            background: rgba(25, 25, 25, 0.1);
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: rgba(25, 25, 25, 0.6);
            padding: 0 8px;     
        }
        .icon-refused{
            height: 26px;
            background: rgba(236, 30, 37, 0.2);
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #EC1E25;
            padding: 0 8px;       
        }
        .icon{
            margin-right: 4px;
        }
        .status-time{
            font-weight: normal;
            text-align:start;
            padding-right: 37px;
        }
    }

`
export { Bound }