
//images
import IcCustomerGreen from '../images/IcCustomerGreen'
import IcUserExclamation from '../images/IcUserExclamation'
import IcCardGray from '../images/IcCardGray';
import IcTellerGray from '../images/IcTellerGray'
import IcUserCheck from '../images/IcUserCheck'
import IcArrowGreen from '../images/IcArrowGreen'
import IcCardGreen from '../images/IcCardGreen'
import IcTellerGreen  from '../images/IcTellerGreen'
import IcIncommingCallGreen from '../images/IcIncomingCallGreen'
import IcCheckGray from '../images/IcCheckGray'
import IcMissCallGray from '../images/IcMissCallGrayMini'
import IcIncommingCallGray from '../images/IcIncomingCallMiniGray'

//constant
import * as paths from '../constant/path'

export const data = [
    {
        key: 'customer-system',
        icon: <IcCustomerGreen />,
        title: 'Khách hàng hệ thống',
        navigation: {
            href: `/${paths.MAIN}/${paths.TRANSACTION_HISTORY}`,
            title: 'Quản lý giao dịch'
        },
        childs: [
            {
                key: 'card-auto',
                icon: <IcCardGray />,
                title: 'Lượt mở thẻ tự động'
            },
            {
                key: 'card-support',
                icon: <IcTellerGray />,
                title: 'Lượt mở thẻ có hỗ trợ'
            }
        ]
    },
    {
        key: 'customer-daily',
        icon: <IcCustomerGreen />,
        title: 'Khách hàng ngày hôm nay',
        navigation: {
            href: `/${paths.MAIN}/${paths.TRANSACTION_HISTORY}`,
            title: 'Quản lý giao dịch'
        },
        childs: [
            {
                key: 'card-auto',
                icon: <IcCardGray />,
                title: 'Lượt mở thẻ tự động'
            },
            {
                key: 'card-support',
                icon: <IcTellerGray />,
                title: 'Lượt mở thẻ có hỗ trợ'
            }
        ]
    },
    {
        key: 'call-daily',
        icon: <IcIncommingCallGreen />,
        title: 'Cuộc gọi trong ngày hôm nay',
        navigation: {
            href: `/${paths.MAIN}/${paths.CALL_HISTORY}`,
            title: 'Quản lý cuộc gọi'
        },
        childs: [
            {
                key: 'call-received',
                icon: <IcIncommingCallGray />,
                title: 'Lượt tiếp nhận'
            },
            {
                key: 'call-denied',
                icon: <IcMissCallGray />,
                title: 'Lượt từ chối'
            }
        ]
    }
]