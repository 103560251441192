import React from 'react'

const IcMail = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33317 3.33337H16.6665C17.5832 3.33337 18.3332 4.08337 18.3332 5.00004V15C18.3332 15.9167 17.5832 16.6667 16.6665 16.6667H3.33317C2.4165 16.6667 1.6665 15.9167 1.6665 15V5.00004C1.6665 4.08337 2.4165 3.33337 3.33317 3.33337Z" stroke="#7A7A7A" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3332 5L9.99984 10.8333L1.6665 5" stroke="#7A7A7A" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMail
