import styled from 'styled-components'

const WrapCallInfos = styled.div`
    background: #FFFFFF;
    display: grid;
    grid-template-rows: 71px 1fr;
    font-family: "SVN-Gilroy";
    align-self: baseline;
    position: sticky;
    top: 0;
    max-width: 100vh;
    box-sizing: border-box;

    .call-title {
        height: 71px;
        background: rgba(128, 128, 128, 0.05);
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1);
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #212121;
        padding-left: 30px;
    }
    .wrap-call {
        /* padding: 30px; */
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 71px);
        
        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            color: #191919;
            text-transform: uppercase;
            height: 13px;
        }
        .title-video {
            height: 24px;
        }

        .wrap-scroll {
            flex-grow: 1;
            min-height: 200px;
            overflow: overlay;
            padding: 0 30px 30px;
        }

        .wrap-col-1 {
            padding: 30px 30px 24px 30px;
            .wrap-video {
                position: relative;
                width: 340px;
                height: 236px;
                margin-top: 5px; 
                .video-call {
                    width: 340px;
                    height: 236px;
                    background: #19191973;
                    border: 1px solid #E1E1E1;
                    box-sizing: border-box;
                    border-radius: 10px;
                    outline: none;
                }
                .end-call-btn {
                    position: absolute;
                    bottom: 11px;
                    left: 50%;
                    transform : translate( -50%, 0);
                    cursor: pointer;
                }
                .time-record {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    justify-content: center;
                    .red-dot {
                        margin-right: 6px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #EC1E25;
                        z-index: 1;
                        animation-name: recordAnimation;
                        animation-duration: 1s;
                        animation-iteration-count: infinite;
                        animation-direction: alternate;
                    }  
                    .time {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 15px;
                        color: #7A7A7A;
                        opacity: 0.45;
                    }
                }
            }
        }
        .wrap-col-2, .wrap-col-3 {
            width: 100%;
            padding: 24px 0 27px 0;
            border-top: 0.3px solid #808080;
            .customer-info {
                display: flex;
                align-items: center;
                margin-top: 20px;
                .avatar {
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                }
                .customer-title { 
                    padding-left: 22px;
                }
                .p-firstline {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 140%;
                    color: #00783A;
                    text-transform: uppercase;
                }
            }
            .wrap-detail {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                .wrap-left {
                    color: rgba(25, 25, 25, 0.6);
                    .wrap-content-left {
                        margin-top: 27px;
                    }
                }
                .wrap-right{
                    color: #191919;
                    text-align: right;
                    .card-style {
                        padding-right: 5px;
                    }
                }
            }
        }
        .wrap-col-3 {
            border-bottom: none;
            .wrap-name {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 160px;
                justify-self: end;
                text-align-last: right;
            }
        }
    }
    @keyframes recordAnimation {
        from {opacity: 1;}
        to {opacity: 0.3;}
    }
`;

export {
    WrapCallInfos
}