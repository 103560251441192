import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    width: 459px;
    height: 157px;
    padding: 23px 20px 20px 21px;
    background: #fff;
    box-shadow: -10px -10px 40px rgba(0, 0, 0, 0.1), 10px 10px 40px rgba(174, 174, 192, 0.2);
    border-radius: 20px;
    position: fixed;
    top: -100%;
    right: 40px;
    z-index: 1;
    animation-name: showAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    .container{
        width: 100%;
        height: 100%;
        font-family: "SVN-Gilroy";
        display: flex; 
        flex-direction: column;
        justify-content: space-between;
        .wrap-content {
            display: flex;
            .icon-style {
                margin: 22px 22px 0 0 ;
                animation-name: ringingAnimation;
                animation-duration: 1s;
                animation-iteration-count: infinite;
            }
            .title-call {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                color: #191919;
                padding-bottom: 6px;
            }
            .content-call, .content-call-1 {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: rgba(25, 25, 25, 0.6);
                .icon-transaction {
                    padding-right: 7px;
                    
                }
            }
            .content-call-1 {
                margin-top: 12px;
            }
        }
        .wrap-btn-answer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            text-transform: uppercase;
            & > :first-child {
                border: 1px solid #808080;
                margin-right: 12px;
                color: #808080;
                & > .icon-miss-call {
                    padding-right: 5px;
                }
            }
            & > :not(:first-child) {
                background: #016730;
                color: #ffffff;
                & > .icon-call {
                    padding-right: 5px;
                }
            }                
        }   
    }
    @keyframes ringingAnimation{
        0%{transform: rotate(0deg);}
        10%{transform: rotate(-10deg);}
        20%{transform: rotate(10deg);}
        30%{transform: rotate(-10deg);}
        40%{transform: rotate(10deg);}
        50%{transform: rotate(-10deg);}
        100%{transform: rotate(0deg);}
    }
    @keyframes showAnimation{
        from{top: -100%}
        to{top:40px}
    }
`

export { Bound }