import React from 'react'
const IcArrowGreen = (props) => {
  return (
    <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={8} cy={8} r={8} fill="#016730" />
      <path d="M6.25 11.5L9.75 8L6.25 4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


    
  )
}
export default IcArrowGreen;