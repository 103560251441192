import React from 'react'
//styles
import WrapperHeader from '../../CustomStyles/HeaderWrapper'
import * as styles from './index.styles'
//image
import LogoVPBankFull from '../../../assets/images/LogoVPBankFull';

export default function Header() {
       
    return (
        <WrapperHeader>
            <styles.Bound>
                <div className="logo-vpbank"><LogoVPBankFull /></div>
            </styles.Bound>
        </WrapperHeader>
    )
}
