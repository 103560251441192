import React from 'react'
//style
import * as styles from './index.styles'
//component
import TitleDropDownFilter from '../../../Control/TitleDropdownFilter'


const DropDownFilterTransactionTime = () => {
    return (
        <styles.Bound>
            <TitleDropDownFilter children="TG giao dịch" />
        </styles.Bound>
    )
}

export default DropDownFilterTransactionTime
