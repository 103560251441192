import styled, { css } from 'styled-components'

const Bound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .image-container{
        display: flex;
        margin-bottom: 47px;
        .avatar-wrap{
            width: 188px;
            height: 188px;
            background: rgba(128, 128, 128, 0.05);           
            border-radius: 5px;
            position: relative;
            .avatar{
                width: 188px;
                height: 188px;
                object-fit: contain;
                border-radius: 5px;
            }
            .icon-expand{
                position: absolute;
                right: 8px;
                bottom: 8px;
                cursor: pointer;
            }
        }
        .customer-info{
            display: flex;
            flex-direction: column;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #191919;
            margin-left: 33px;
            .name{
                font-weight: bold;
                font-size: 30px;
                line-height: 26px;
                margin: 4px 0 32px 0;
            }
            .role-wrap{
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                .role{
                    margin-left: 16px;
                }
                .cif-label{
                    font-weight: bold;
                    color: #6F7374;
                }
                .cif{
                    color: #016730;
                }
            }
        }
    }
    .details-container{
        width: 100%;
    }
`
const extendStyles = css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.4);
    .zoom-img {
        position: relative;
        .avatar-zoom{
            width: 400px;
            height: 400px;
            border-radius: 5px;
        }        
        .close {
            position: absolute;
            top: 3px;
            right: 3px;

            &:hover {
                cursor: pointer;
            }
        }        
    }
`;

export { Bound, extendStyles }