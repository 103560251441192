import styled from 'styled-components'

const Overlay = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    .show-popup{
        /* right: -400px; */
        animation-name: show-popup; 
    }
    .hide-popup{
        /* right: 0px; */
        animation-name: hide-popup; 
    }
    @keyframes show-popup{
        from{ right: -400px }
        to{ right: 0px }
    }
    @keyframes hide-popup{
        from{ right: 0px }
        to{ right: -400px }
    }
`
const PopupContainer = styled.div`
    width: 400px;
    height: 100%;
    background: #fff;
    position: absolute;
    top:0;
    right: -400px;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    .title-call-detail{
        height: 71px;
        background: rgba(128, 128, 128, 0.05);
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1);
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-cancel{
            /* width: 24px;
            height: 24px; */
            cursor: pointer;
        }
        .title-name{
            display: flex;
            align-items: center;
            p{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                padding-left: 5px;
            }
        }
    }
    .call-detail-wrap{
        display: flex;
        flex-direction: column;
        padding: 30px 30px 0 30px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 128%;
        color: #191919;
        .button-wrap{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 36px;
            .button-watch{
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 9px;
                background: rgba(1, 103, 48, 0.1);
                border-radius: 20px;
                cursor: pointer;
                &.hidden {
                    visibility: hidden;
                    pointer-events: none;
                }
                .text{                        
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 13px;
                    text-transform: uppercase;
                    color: #016730;
                    margin-right: 6px;
                }
            }
        }            
        .detail-wrap{
            display: flex;
            flex-direction: column;
            border-bottom: 0.3px solid #808080;
            margin-bottom: 24px;
            :last-of-type{
                border-bottom: unset;
            }
            .title-content{                    
                font-weight: bold;
                font-size: 12px;
                line-height: 13px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            .avatar-wrap{
                display: flex;
                margin-bottom: 20px;
                align-items: center;
                .avatar-wrap{
                    width: 65px;
                    height: 65px;
                    object-fit: cover;
                    border-radius: 50%;
                    .avatar{
                        width: 65px;
                        height: 65px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
                .name-wrap{
                    display: flex;
                    flex-direction: column;
                   
                    margin-left: 22px;
                    .name{                        
                        font-weight: bold;
                        line-height: 140%;
                        text-transform: uppercase;
                        color: #00783A;
                        margin: 0 0 8px 0;                        
                        &.visitors {
                            color: #7A7A7A;
                        }
                    }
                    .phone{                        
                        font-weight: 600;
                        line-height: 126%;
                        color: #212121;
                    }
                }
            }
            .detail-content{
                display: flex;
                justify-content: space-between;
                margin-bottom: 23px;
                :last-of-type{
                    margin-bottom: 27px;
                }
                .role{
                    color: rgba(25, 25, 25, 0.6);
                }
                .content{
                    display: flex;
                    align-items: center;
                    text-align: right;
                    .icon{
                        height: 14px;
                        width: 14px;
                        margin-right: 5px;
                    }
                    &.address{
                        max-width: 160px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        justify-self: right;
                    }
                }
            }
            .line{
                width: 100%;
                height: 0.3px;
                background: #808080;
            }
            .button-review{
                width: 195px;
                height: 32px;
                background: rgba(1, 103, 48, 0.1);
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                cursor: pointer;
                .text{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 13px;
                    text-transform: uppercase;
                    color: #016730;
                    margin-right: 9px;
                }
            }
        }       
    }
`


export { Overlay, PopupContainer }