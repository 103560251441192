import React from 'react'

const IcPlayGreen = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.59961 10.1498L9.79958 6.99983L5.59961 3.84985V10.1498Z" fill="#016730" />
            <path d="M6.99984 0C3.13258 0 0 3.13258 0 6.99984C0 10.8671 3.13258 13.9997 6.99984 13.9997C10.8671 13.9997 13.9997 10.8671 13.9997 6.99984C13.9997 3.13258 10.8674 0 6.99984 0ZM6.99984 12.5999C3.91286 12.5999 1.39977 10.0868 1.39977 6.99984C1.39977 3.91286 3.91286 1.4001 6.99984 1.4001C10.0868 1.4001 12.5999 3.91286 12.5999 6.99984C12.5999 10.0868 10.0868 12.5999 6.99984 12.5999Z" fill="#016730" />
        </svg>
    )
}

export default IcPlayGreen
