import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
//styles
import * as styles from './index.styles'
// component
import Portal from '../../Control/Portal'
//mockData
import { callDetailMockData } from '../../../assets/mockData/callDetailMockData'
//constant
import statusCallType, { RECEIVED } from '../../../assets/constant/statusCallType'
import genderType from '../../../assets/constant/genderType'
//tool
import { convertDateTime } from '../../../helper/tools'
//image
import IcCancel from '../../../assets/images/IcCancel'
import IcCallGreenCycle from '../../../assets/images/IcCallGreenCycle'
import IcPlayGreen from '../../../assets/images/IcPlayGreen'
import IcGender from '../../../assets/images/IcGender'
import IcArrowNextGreen from '../../../assets/images/IcArrowNextGreen'
import IcCardMini from '../../../assets/images/IcCardMini'
import AvatarDefault from '../../../assets/images/AvatarDefault'
//api
import { apiAvatarImage, apiGetVideoCallRecord } from '../../../assets/constant/host'


const CallDetail = ({ data, onClosePopup, handleShowPopupDetail }) => {

    const popupRef = useRef()
    const [isErrImg, setIsErrImg] = useState(false)

    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = ""
        }
    }, [])

    const _handleOpenVideoRecord = () => {
        window.open(apiGetVideoCallRecord(data?.videoId))
    }

    const _onClosePopup = () => {
        if (popupRef.current) {
            popupRef.current.classList.remove('show-popup')
            popupRef.current.classList.add('hide-popup')
        }
        setTimeout(() => {
            onClosePopup()
        }, 300);
    }

    console.log(data?.status);
    return (
        <Portal>
            <styles.Overlay>
                <styles.PopupContainer ref={popupRef} className='show-popup'>
                    <div className="title-call-detail">
                        <IcCancel className="icon-cancel" onClick={() => _onClosePopup()} />
                        <div className="title-name">
                            <IcCallGreenCycle />
                            <p>Chi tiết cuộc gọi</p>
                        </div>
                    </div>
                    <div className="call-detail-wrap">
                        <div className="button-wrap">
                            <div className={`button-watch ${data?.status !== RECEIVED && 'hidden'}`} onClick={_handleOpenVideoRecord}>
                                <p className="text">XEM video cuộc gọi</p>
                                <IcPlayGreen />
                            </div>
                        </div>
                        <div className="detail-wrap">
                            <div className="title-content">thông tin cuộc gọi</div>
                            <div className="detail-content">
                                <p className="role">Trạng thái cuộc gọi</p>
                                {
                                    data?.status ?
                                        <div className="content">
                                            <div className="icon">{statusCallType[data?.status]?.icon}</div>
                                            <p>{statusCallType[data?.status]?.text}</p>
                                        </div>
                                        :
                                        <span>-</span>
                                }
                            </div>
                            <div className="detail-content">
                                <p className="role">Thời gian tiếp nhận</p>
                                <p className="content">
                                    {
                                        // data?.status === statusCallType[RECEIVED] &&
                                        data?.status === RECEIVED &&
                                            data?.timeReceived ?
                                            convertDateTime(data?.timeReceived, "DD/MM/YYYY - HH:mm:ss")
                                            :
                                            "-"
                                    }
                                </p>
                            </div>
                            <div className="detail-content">
                                <p className="role">Thời gian chờ tiếp nhận</p>
                                <p className="content">
                                    {
                                        // data?.status === statusCallType[RECEIVED] &&
                                        data?.status === RECEIVED &&
                                            data?.waitingTime ?
                                            moment().startOf('day').seconds(data.waitingTime).format('HH:mm:ss')
                                            :
                                            "-"
                                    }
                                </p>
                            </div>
                            <div className="detail-content">
                                <p className="role">Thời gian xử lý</p>
                                <p className="content">
                                    {
                                        // data?.status === statusCallType[RECEIVED] &&
                                        data?.status === RECEIVED &&
                                            data?.processingTime ?
                                            moment().startOf('day').seconds(data.processingTime).format('HH:mm:ss')
                                            :
                                            "-"
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="detail-wrap">
                            <div className="title-content">thông tin cuộc gọi</div>
                            <div className="detail-content">
                                <p className="role">Mã máy KIOSK</p>
                                <p className="content">
                                    {data?.kioskInfo?.code || "-"}
                                </p>
                            </div>
                            <div className="detail-content">
                                <p className="role">Tên máy </p>
                                <p className="content">
                                    {data?.kioskInfo?.name || "-"}
                                </p>
                            </div>
                            <div className="detail-content">
                                <p className="role">Địa điểm</p>
                                <p className="content address" title={data?.kioskInfo?.branchInfo?.address}>
                                    {data?.kioskInfo?.branchInfo?.address || "-"}
                                </p>
                            </div>
                            <div className="detail-content">
                                <p className="role">Chi nhánh</p>
                                <p className="content address" title={data?.kioskInfo?.branchInfo?.name}>
                                    {data?.kioskInfo?.branchInfo?.name || "-"}
                                </p>
                            </div>
                        </div>
                        <div className="detail-wrap">
                            <div className="title-content">thông tin khách hàng</div>
                            <div className="avatar-wrap">
                                <div className="avatar-wrap">
                                    {
                                        isErrImg ?
                                            <AvatarDefault className="avatar" />
                                            :
                                            <img
                                                className="avatar"
                                                src={apiAvatarImage(data?.customerInfo?.customerId)}
                                                alt="avatar"
                                                onError={() => setIsErrImg(true)}
                                            />
                                    }
                                </div>
                                <div className="name-wrap">
                                    {
                                        data?.customerInfo?.identityCardInfo?.fullName ?
                                            <p className="name">{data.customerInfo.identityCardInfo.fullName}</p>
                                            :
                                            <p className="name visitors">KHÁCH VÃNG LAI</p>
                                    }
                                    <p className="phone">{data?.customerInfo?.phoneNumber || "-"}</p>
                                </div>
                            </div>
                            <div className="detail-content">
                                <p className="role">Giới tính</p>
                                <div className="content">
                                    <IcGender />
                                    {
                                        data?.customerInfo?.identityCardInfo?.gender ?
                                            <div>
                                                {genderType[data?.customerInfo?.identityCardInfo?.gender].text}
                                            </div>
                                            :
                                            "-"
                                    }
                                </div>
                            </div>
                            <div className="detail-content">
                                <p className="role">CMND</p>
                                <p className="content">
                                    {data?.number || "-"}
                                </p>
                            </div>
                            {
                                data?.status === RECEIVED &&
                                data?.transId !== '000000000000000000000000' &&
                                <>
                                    <div className="detail-content">
                                        <p className="role">Giao dịch thực hiện</p>
                                        <div className="content">
                                            <IcCardMini className="icon" />
                                            <p>Mở tài khoản</p>
                                        </div>
                                    </div>
                                    <p className="line"></p>
                                    <div className="button-review" onClick={() => handleShowPopupDetail({ id: data.transId })}>
                                        <p className="text">XEM LẠI chi tiết giao dịch</p>
                                        <IcArrowNextGreen />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </styles.PopupContainer>
            </styles.Overlay>
        </Portal>
    )
}

export default CallDetail
