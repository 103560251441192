import React, { useState, useEffect, useCallback } from 'react'
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'

// constant
import * as keys from '../../assets/constant/key';
//constant
import { VPBANK_COUNTER_LOGIN } from '../../helper/localStore';
//path
import * as paths from '../../assets/constant/path'

const PrivateRouter = ({ children, isLogin, ...rest }) => {

    const [islogin, setIslogin] = useState(false)

    const { loginReducer } = useSelector(state => ({
        loginReducer: state.loginReducer
    }))

    // useEffect(() => {
    //     const login = localStorage.getItem(VPBANK_COUNTER_LOGIN)
    //     if(!login) {
    //         setIslogin(false)
    //         return;
    //     }

    //     setIslogin(true)
    // }, [])

    useEffect(() => {
        if(loginReducer.loggedIn) {
            setIslogin(true)
            return;
        }
    }, [loginReducer.loggedIn])

    const _renderRoute = ({ location }) => {
        // if (islogin === null) return null;
        return isLogin ? (
            children
        ) : (
                <Redirect
                    to={{
                        pathname: `/${paths.LOGIN}`,
                        state: { from: location }
                    }}
                />
            )
    }

    return (
        <Route
            {...rest}
            render={_renderRoute}
        />
    );
}

export default PrivateRouter
