import * as types from './types'

const intitialState = {
    result: [],
    total: 0,
    condFilters: {
        isDesc: true,
        pageNumber: 1,
        pageSize: 8,        
    }
};

const listCallHistoryReducer = (state = intitialState, action) => { 
    switch (action.type) {
        case types.GET_LIST_CALL_HISTORY: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CLEAR_LIST_CALL_HISTORY: {
            return intitialState
        }
        default:
            return state;
    }
};

export default listCallHistoryReducer;