import React, { useState, useRef, useEffect, useLayoutEffect} from 'react'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//styles
import * as styles from './index.styles'
import WrapperPage from '../../CustomStyles/PageWrapper'
//image
import IcSubmit from '../../../assets/images/IcSubmit';
//tool
import { checkMail } from '../../../helper/tools';
//key
import * as keys from '../../../helper/localStore'
//redux
import { loginActicon } from '../../../redux/login/action';
import { clearStores } from '../../../redux/commonAction';
//path
import * as paths from '../../../assets/constant/path'
//help
import usePressEnter from '../../../helper/usePressEnter';

const titleWrongLogin = "Tài khoản đăng nhập không đúng hoặc không tồn tại trong hệ thống.";

export default function LoginPage() {

    const dispatch = useDispatch()
    const history = useHistory()
    const isCallLogedIn = useRef(false)

    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [errContent, setErrContent] = useState("");
    const [isErrorEmail, setIsErrorEmail] = useState(false)
    const [isErrorPassword, setIsErrorPassword] = useState(false) 
    const [isEnter, setIsEnter] = usePressEnter();
    
    useEffect(() => {
        if (isEnter && !isCallLogedIn.current) {
            isCallLogedIn.current = true
            _handleSubmit()
            setIsEnter(false)
        }
    }, [isEnter]);

    useLayoutEffect(() => {
        const login = localStorage.getItem(keys.VPBANK_COUNTER_LOGIN);
        if (!login) {
            dispatch(clearStores())
            return;
        }
        history.push(`/${paths.MAIN}`);
    }, []);

    const _changeInputEmail = (e) => {
        const { value } = e.target
        const val = value.trimStart().replace(/\s+/g, " ")
        if (val.includes(" ")) return;
        setEmailValue(val);
        setErrContent("");
    }

    const _changeInputPassword = (e) => {
        const { value } = e.target
        setPasswordValue(value);
        setErrContent("");
    }

    const _handleLoginError = (code) => {
        isCallLogedIn.current = false
        setErrContent(code === 6 ? 
            "Tài khoản của bạn đã bị ngưng hoạt động. Vui lòng liên hệ với quản trị viên để cập nhật" : 
            titleWrongLogin
        );
    }

    const _handleSubmit = (e) => {
        const checkEmail = checkMail(emailValue);
        const dataLogin = {
            username: emailValue,
            password: passwordValue,
        };
        if (!checkEmail || !emailValue) {
            setErrContent(titleWrongLogin)
            setIsErrorEmail(true);            
        }
        if (!passwordValue) {
            setErrContent(titleWrongLogin)
            setIsErrorPassword(true);           
        }
        if(!checkEmail || !emailValue || !passwordValue) {
            return;
        }        
        setIsErrorEmail(false)
        setIsErrorPassword(false);
        dispatch(loginActicon(history, dataLogin, null, _handleLoginError));
    };

    return (
        <WrapperPage>
            <styles.Bound>
                <p className="title">VP Bank Counter</p>
                <p className="text">Đăng nhập hệ thống</p>
                <div className="input-wrap">
                    <p className="title-input">Email</p>
                    <input
                        className={isErrorEmail ? "input err" : "input"}
                        value={emailValue}
                        placeholder="sample@vpbank.vn"
                        onChange={_changeInputEmail}
                    />
                </div>
                <div className="input-wrap">
                    <p className="title-input">Mật khẩu</p>
                    <input
                        className={isErrorPassword ? "input err" : "input"}
                        value={passwordValue}
                        placeholder="Nhập password ..."
                        type="password"
                        onChange={_changeInputPassword}
                    />
                </div>
                <div className="error">
                    {errContent}
                </div>
                <div className="button-container" onClick={_handleSubmit}>
                    <IcSubmit className="icon" />
                    Đăng nhập
                </div>
            </styles.Bound>
        </WrapperPage>
    )
}
