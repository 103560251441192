import React from 'react'

const IcCustomerGreen = (props) => {
  return (
    <svg {...props} width={45} height={34} viewBox="0 0 45 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="16.2105" cy="9.89474" rx="7.89474" ry="7.89474" stroke="#016730" strokeWidth={4} strokeLinecap="round" />
      <path d="M30.4211 32C30.4211 24.1517 24.0588 17.7894 16.2105 17.7894C8.36227 17.7894 2 24.1517 2 32" stroke="#016730" strokeWidth={4} strokeLinecap="round" />
      <path d="M28.2106 2C32.5707 2 36.1053 5.53459 36.1053 9.89474C36.1053 12.7259 34.6151 15.2089 32.3762 16.6023" stroke="#016730" strokeWidth={4} strokeLinecap="round" />
      <path d="M42.4211 30.802C42.4211 24.1244 37.8153 18.5226 31.6072 17" stroke="#016730" strokeWidth={4} strokeLinecap="round" />
    </svg>
  )
}
export default IcCustomerGreen;