import IcBank from "../images/IcBank"
import IcBankGreen from "../images/IcBankGreen"
import IcHome from "../images/IcHome"
import IcHomeGreen from "../images/IcHomeGreen"
import IcPrint from "../images/IcPrint"
import IcPrintGreen from "../images/IcPrintGreen"

const FROM_DEVICE = 1
const AT_HOME = 2
const AT_BRANCH = 3

const formReceiveCardTypes = {
    [FROM_DEVICE]: {
        type: FROM_DEVICE,
        textStep: 'Nhận thẻ trực tiếp',
        descStep: 'Thẻ sẽ được in trực tiếp từ thiết bị. Thời gian in thẻ từ 3-5 phút.',
        Icon: IcPrint,
        IconActive: IcPrintGreen,
    },
    [AT_HOME]: {
        type: AT_HOME,
        textStep: 'Nhận thẻ tại nhà',
        Icon: IcHome,
        IconActive: IcHomeGreen,
        keyGetInfosReceive: 'homeInfo'
    },
    [AT_BRANCH]: {
        type: AT_BRANCH,
        textStep: 'Nhận tại chi nhánh',
        Icon: IcBank,
        IconActive: IcBankGreen,
        keyGetInfosReceive: 'branchInfo'
    }
}

export {
    FROM_DEVICE,
    AT_HOME,
    AT_BRANCH
}

export default formReceiveCardTypes