import React from 'react'
//style
import * as styles from './index.styles'
//component
import TitleDropDownFilter from '../../../Control/TitleDropdownFilter'


const DropDownFilterSupportStaff = () => {
    return (
        <styles.Bound>
            <TitleDropDownFilter children="Nhân viên hỗ trợ" isDisable={true} />
        </styles.Bound>
    )
}

export default DropDownFilterSupportStaff
