import styled from 'styled-components'

const Bound = styled.div`    
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    padding-left: 79px;
    .logo-vpbank{
        width: 162px;
        height: 32px;
    } 
`

export { Bound }