export const PASSPORT = "PASSPORT"
export const CMND = "CMND"
export const CMND12 = "CMND12"
export const CCCD = "CCCD"

const identityTypes = {
    [CMND]: {
        key: CMND,
        titleValue: 'CMND',
        text: 'Chứng minh nhân dân 9 số',
        typeInfo: "cmndInfo",
    },
    [CMND12]: {
        key: CMND12,
        titleValue: 'CMND12',
        text: 'Chứng minh nhân dân 12 số',
        typeInfo: "cmndInfo",
    },
    [CCCD]: {
        key: CCCD,
        titleValue: 'CCCD',
        text: 'Căn cước công dân',
        typeInfo: "cccdInfo",
    },
    [PASSPORT]: {
        key: PASSPORT,
        titleValue: 'Passport',
        text: 'Hộ chiếu',
        typeInfo: "passportInfo"
    },
}
export default identityTypes