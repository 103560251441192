import { hostWSSocketRTC } from "../../../assets/constant/host"

const CODE_CLOSE_SAFE = 4000
const CODE_CLOSED = 3
class WsConnectRTC {
    constructor(streamID) {
        this.streamID  = streamID
        this.socket = new WebSocket(hostWSSocketRTC(this.streamID))
        this.socket.onopen = e => {
            console.log('socket open', e)
            this.emitRinging()
        }
        this.socket.onclose = e => {
            console.log('socket close', e)
            const { code } = e;
            if(code !== CODE_CLOSE_SAFE) {
                this.reconnect()
            }
        }
        this.socket.onerror = e => {
            console.log('socket error', e)
            const { code } = e;
            if(code !== CODE_CLOSE_SAFE) {
                this.reconnect()
            }
        }
        this.reconnectRef = null
    }

    reconnect = () => {
        this.reconnectRef = setTimeout(() => {
            this.socket = new WebSocket(hostWSSocketRTC(this.streamID))
        }, 3000);
    }

    disconnect = () => {
        this.emit(JSON.stringify({ "Cmd": "CANCEL" }))
        setTimeout(() => {
            this.close()
        }, 1000);
    }

    close = () => {
        clearTimeout(this.reconnectRef)
        if(this.socket.readyState !== CODE_CLOSED)
            this.socket.close(CODE_CLOSE_SAFE)
    }

    emitConnecting = (content) => {
        const dataEmit = {
            "Cmd": "CONNECTING",
            "Content": content
        }
        this.emit(JSON.stringify(dataEmit))
    }

    emitRinging = () => {
        const dataEmit = {
            "Cmd": "RINGING",
        }
        this.emit(JSON.stringify(dataEmit))
    }

    on = (callback) => {
        this.socket.onmessage = callback
    }

    emit = (data) => {
        this.socket.send(data)
    }
}

export default WsConnectRTC