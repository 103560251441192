import React from 'react'

const IcExpand = ({ uniqueKey, ...props }) => {
    const id = uniqueKey ? `ic-expand-ref-${uniqueKey}` : `ic-expand-ref`
	const filter = uniqueKey ? `ic-expand-filter-${uniqueKey}` : `ic-expand-filter`
    return (
        <svg {...props} width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <g opacity="0.66" filter={`url(#${filter})`}>
                    <circle cx={14} cy={14} r={14} fill="#191919" />
                </g>
                <path d="M21 16.6009L19.3503 18.2506L16.8832 15.7834L15.7834 16.8832L18.2506 19.3503L16.6009 21H21V16.6009ZM7 11.3991L8.64967 9.74944L11.1168 12.2166L12.2166 11.1168L9.74944 8.64967L11.3991 7H7V11.3991ZM21 7H16.6009L18.2506 8.64967L15.7834 11.1168L16.8832 12.2166L19.3503 9.74944L21 11.3991V7ZM7 21H11.3991L9.74944 19.3503L12.2166 16.8832L11.1168 15.7834L8.64967 18.2506L7 16.6009V21Z" fill="white" />
            </g>
            <defs>
                <filter id={filter} x={-12} y={-8} width={52} height={52} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={6} />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.345833 0 0 0 0 0.345833 0 0 0 0 0.345833 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <clipPath id="clip0">
                    <rect width={28} height={28} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcExpand
