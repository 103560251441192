import styled from 'styled-components'
import { StepDetailsStyles } from '../StepContainer/StepDetailsStyles'

const StepFiveDetails = styled(StepDetailsStyles)`
    height: 265px;
    @keyframes showStepFiveAnimation{
        from{ height: 0; }
        to{ height: 275px; };
    }

    display: flex;
    box-sizing: border-box;
    padding: 30px 30px 15px 30px;

    & > .wrap-card {
        width: 257px; 
        &:not(:first-child) {
            margin-left: 33px;
        }

        &.selected {
            .icon {
                filter: none;
            }
        }

        .icon {
            width: 100%;
            filter: grayscale(100%);
        }

        .name {
            display: flex;
            justify-content: center;
            padding: 0 20px 0 14px;
            margin-top: 25px;

            .radio-btn {
                flex-shrink: 0;
            }

            & > div {
                margin-left: 10px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                color: #191919;
            }
        }
    }
`;

export {
    StepFiveDetails
}