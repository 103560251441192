
import * as types from './types'

const intitialState = {
    dashboard: {}
}
const dashboardReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_DASHBOARD: {
            return {
                ...state,
                ...action.payload
              }
        }
        default:
            return state;
    }

}

export default dashboardReducer