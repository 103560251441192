import React, { useEffect, useState, useRef } from 'react'

// component
import DetailCall from './DetailCall'

const PopupShowDetailCall = ({ 
    socketRTC, 
    hangupRef, 
    dataRTCConfig, 
    rtcConfigRef,
    kioskData,
    phoneInfos
}) => {


    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = ""
        }
    }, [])
    

    return (
        <DetailCall 
            socketRTC={socketRTC} 
            hangupRef={hangupRef} 
            dataRTCConfig={dataRTCConfig} 
            rtcConfigRef={rtcConfigRef} 
            kioskData={kioskData}
            phoneInfos={phoneInfos}
        />
    )
}

export default PopupShowDetailCall
