import styled from 'styled-components'

const Bound = styled.div`
    width: calc(100% - 80px);
    height: 100%;
    padding-left: 80px;
    display: grid;
    grid-template-rows: 90px 1fr;
    position: relative;
    .title-container{
        padding-left: 4px;
    }
`

export { Bound }