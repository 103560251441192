import React from 'react'
//style
import * as styles from './index.styles'
//component
import TitleDropDownFilter from '../../../Control/TitleDropdownFilter'


const DropDownFilterBranch = () => {
    return (
        <styles.Bound>
            <TitleDropDownFilter children="Chi nhánh" />
        </styles.Bound>
    )
}

export default DropDownFilterBranch
