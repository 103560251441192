import React, { useMemo } from 'react'

// constant
import formReceiveCardTypes, { AT_BRANCH, AT_HOME } from '../../../../../../../assets/constant/formReceiveCardTypes'

// component
import StepContainer from '../StepContainer'
import ActiveCardControl from '../../../../../../Control/ActiveCardControl'
import IcPhoneGrayOutline from '../../../../../../../assets/images/IcPhoneGrayOutline'
import IcLocation from '../../../../../../../assets/images/IcLocation'

// styles
import { StepSixDetails } from './index.styles'


const StepSix = ({ status, selectedForm, infosReceive }) => {

    const receiveCardDetails = useMemo(() => {
        switch(selectedForm) {
            case AT_HOME: {
                const {
                    fullName,
                    phoneNumber,
                    province,
                    district,
                    wards,
                    apartmentNumber
                } = infosReceive
                return (
                    <>
                        <div className="title">Thông tin nơi nhận</div>
                        <div className="receive-at-home-details-item">
                            <div>Tên người nhận:</div>
                            <div>{fullName ?? '-'}</div>
                        </div>
                        <div className="receive-at-home-details-item">
                            <div>Số điện thoại:</div>
                            <div>{phoneNumber ?? '-'}</div>
                        </div>
                        <div className="receive-at-home-details-item">
                            <div>Địa chỉ nhận:</div>
                            <div>{[apartmentNumber, wards, district, province].filter(Boolean).join(', ')}</div>
                        </div>
                    </>
                )
            }
            case AT_BRANCH: {
                // const {
                //     id,
                //     code,
                //     name,
                //     address
                // } = infosReceive
                const branchName = "VPBank Láng Hạ"
                const branchPhone = "024.39288869"
                const branchAddress = "Tòa nhà VP Bank, 89 Láng Hạ, P. Đống Đa, Hà Nội"
                return (
                    <>
                        <div className="title">Thông tin nơi nhận</div>
                        <div className="branch-name">Chi nhánh {branchName}</div>
                        <div className="receive-at-branch-details-item">
                            <IcPhoneGrayOutline />
                            <div>{branchPhone}</div>
                        </div>
                        <div className="receive-at-branch-details-item">
                            <IcLocation />
                            <div>{branchAddress}</div>
                        </div>
                    </>
                )
            }
            default:
                return null;
        }
    }, [selectedForm, JSON.stringify(infosReceive)])

    return (
        <StepContainer title='BƯỚC 6: CHỌN HÌNH THỨC NHẬN THẺ' status={status} animationName='showStepSixAnimation' >
            <div className='show-container'>
                <StepSixDetails>
                    <div className="form-receive-card">
                        {Object.values(formReceiveCardTypes).map(item => {
                            const isSelected = selectedForm === item.type

                            return (
                                <div className={`form-receive-card-item ${isSelected && 'selected'}`} key={item.type}>
                                    <div className="wrap-title">
                                        {
                                            isSelected ?
                                            <item.IconActive />
                                            :
                                            <item.Icon />
                                        }
                                        <div className="title">{item.textStep}</div>
                                        <ActiveCardControl
                                            isActive={isSelected}
                                        />
                                    </div>
                                    {!!item.descStep && <div className="description">{item.descStep}</div>}
                                </div>
                            )
                        })}
                    </div>
                    <div className="form-receive-card-details">
                        {receiveCardDetails}
                    </div>
                </StepSixDetails>
            </div>
        </StepContainer>
    )
}

export default StepSix
