import styled from 'styled-components'
import reportWebVitals from '../../../../reportWebVitals'

const Bound = styled.div`
    padding: 23px 28px 24px; 
    width: calc(100% - 56px);
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${ props => props.type === 1 ? 'repeat(3, auto)' : 'repeat(4, auto)'};
    grid-column-gap: 30px;
    grid-row-gap: 11px;
    grid-auto-flow: column;
    background: rgba(128, 128, 128, 0.05);
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1);
    .title{
        width: 100%;
        height: 30px;
        grid-column: 1 / span 2;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #191919;
        .icon{
            margin-right: 10px;
        }
    }
    .line-container{
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        .label{            
            color: rgba(25, 25, 25, 0.6);
        }
        .content{
            display: flex;
            align-items: center;          
            font-weight: 600;
            color: #191919;
            margin-left: 4px;
            span{
                margin: 0 3px;
            }
            .message{
                padding-left: 6px;
            }
        }
    }
`

export { Bound }