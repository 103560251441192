import styled from "styled-components";

const WrapAnswer = styled.div`
    width: 174px;
    height: 36px;
    border-radius: 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;

    /* ${props => props.extendStyles} */
`;

export {
    WrapAnswer
}