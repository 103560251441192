import React from 'react'

const IcReactionSad = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M8.99837 18.0004C13.968 18.0004 17.9967 13.9717 17.9967 9.00203C17.9967 4.03237 13.968 0.00366211 8.99837 0.00366211C4.02871 0.00366211 0 4.03237 0 9.00203C0 13.9717 4.02871 18.0004 8.99837 18.0004Z" fill="#FFB32B" />
                <path d="M10.5633 10.957C11.2116 10.957 11.737 10.2564 11.737 9.39208C11.737 8.52779 11.2116 7.82715 10.5633 7.82715C9.91513 7.82715 9.38965 8.52779 9.38965 9.39208C9.38965 10.2564 9.91513 10.957 10.5633 10.957Z" fill="#273941" />
                <path d="M3.52038 10.957C4.1686 10.957 4.69408 10.2564 4.69408 9.39208C4.69408 8.52779 4.1686 7.82715 3.52038 7.82715C2.87216 7.82715 2.34668 8.52779 2.34668 9.39208C2.34668 10.2564 2.87216 10.957 3.52038 10.957Z" fill="#273941" />
                <path d="M10.5637 10.5661C10.9959 10.5661 11.3462 10.0407 11.3462 9.39245C11.3462 8.74423 10.9959 8.21875 10.5637 8.21875C10.1316 8.21875 9.78125 8.74423 9.78125 9.39245C9.78125 10.0407 10.1316 10.5661 10.5637 10.5661Z" fill="#141E21" />
                <path d="M3.52172 10.5661C3.95387 10.5661 4.30419 10.0407 4.30419 9.39245C4.30419 8.74423 3.95387 8.21875 3.52172 8.21875C3.08958 8.21875 2.73926 8.74423 2.73926 9.39245C2.73926 10.0407 3.08958 10.5661 3.52172 10.5661Z" fill="#141E21" />
                <path d="M10.9537 9.39233C11.1698 9.39233 11.345 9.21717 11.345 9.0011C11.345 8.78503 11.1698 8.60986 10.9537 8.60986C10.7377 8.60986 10.5625 8.78503 10.5625 9.0011C10.5625 9.21717 10.7377 9.39233 10.9537 9.39233Z" fill="#F6FAFD" />
                <path d="M3.91174 9.39233C4.12782 9.39233 4.30298 9.21717 4.30298 9.0011C4.30298 8.78503 4.12782 8.60986 3.91174 8.60986C3.69567 8.60986 3.52051 8.78503 3.52051 9.0011C3.52051 9.21717 3.69567 9.39233 3.91174 9.39233Z" fill="#F6FAFD" />
                <path d="M8.60643 14.4784H7.82396C7.82396 14.3188 7.51919 14.0872 7.0415 14.0872C6.5638 14.0872 6.25903 14.3188 6.25903 14.4784H5.47656C5.47656 13.8199 6.16396 13.3047 7.0415 13.3047C7.91903 13.3047 8.60643 13.8199 8.60643 14.4784Z" fill="#273941" />
                <path d="M8.99837 0.00366211C7.21866 0.00366211 5.47892 0.531407 3.99915 1.52016C2.51937 2.50891 1.36603 3.91427 0.684964 5.5585C0.0038989 7.20274 -0.174299 9.01201 0.172905 10.7575C0.520109 12.503 1.37712 14.1064 2.63556 15.3648C3.89401 16.6233 5.49736 17.4803 7.24288 17.8275C8.98839 18.1747 10.7977 17.9965 12.4419 17.3154C14.0861 16.6344 15.4915 15.481 16.4802 14.0013C17.469 12.5215 17.9967 10.7817 17.9967 9.00203C17.9967 6.61551 17.0487 4.32674 15.3612 2.63922C13.6737 0.951702 11.3849 0.00366211 8.99837 0.00366211ZM8.2159 17.6092C6.74571 17.6092 5.30853 17.1732 4.08611 16.3564C2.86369 15.5396 1.91093 14.3787 1.34831 13.0204C0.785688 11.6621 0.638481 10.1675 0.925302 8.72554C1.21212 7.28359 1.92009 5.95908 2.95967 4.9195C3.99926 3.87991 5.32377 3.17195 6.76571 2.88513C8.20766 2.59831 9.70227 2.74551 11.0606 3.30813C12.4188 3.87075 13.5798 4.82351 14.3966 6.04594C15.2134 7.26836 15.6493 8.70554 15.6493 10.1757C15.6493 12.1472 14.8662 14.0379 13.4721 15.432C12.0781 16.826 10.1874 17.6092 8.2159 17.6092Z" fill="#FFCE52" />
                <path d="M17.9966 9.0013C17.9964 7.15089 17.4258 5.34553 16.3624 3.83118C15.299 2.31683 13.7946 1.16713 12.0542 0.538713C10.3138 -0.0897052 8.42191 -0.166283 6.63638 0.319411C4.85085 0.805106 3.25846 1.82946 2.07617 3.2529C3.80179 1.82206 5.99934 1.08682 8.23859 1.19115C10.4778 1.29548 12.5975 2.23186 14.1826 3.81696C15.7677 5.40206 16.7041 7.52173 16.8084 9.76097C16.9127 12.0002 16.1775 14.1978 14.7467 15.9234C15.7645 15.08 16.5836 14.0222 17.1454 12.8256C17.7072 11.6291 17.9979 10.3232 17.9966 9.0013Z" fill="#FFE369" />
                <path d="M16.749 10.1083C16.875 9.98227 16.835 9.73793 16.6597 9.56256C16.4843 9.38719 16.24 9.34719 16.1139 9.47322C15.9879 9.59925 16.0279 9.84358 16.2033 10.019C16.3787 10.1943 16.623 10.2343 16.749 10.1083Z" fill="#F6FAFD" />
                <path d="M16.882 7.40618C16.9451 7.34309 16.9251 7.22086 16.8374 7.13318C16.7498 7.04549 16.6275 7.02555 16.5644 7.08864C16.5013 7.15174 16.5213 7.27396 16.609 7.36165C16.6967 7.44934 16.8189 7.46928 16.882 7.40618Z" fill="#F6FAFD" />
                <path d="M17.0669 8.86119C17.319 8.60913 17.2389 8.12034 16.888 7.76944C16.5371 7.41855 16.0483 7.33842 15.7962 7.59048C15.5442 7.84254 15.6243 8.33133 15.9752 8.68223C16.3261 9.03313 16.8149 9.11325 17.0669 8.86119Z" fill="#F6FAFD" />
                <path d="M17.9973 2.74132C17.9973 1.22881 16.421 0.00268555 14.4762 0.00268555C12.5314 0.00268555 10.9551 1.22881 10.9551 2.74132C10.9551 4.16345 12.3017 5.34537 14.0849 5.47995V7.04489L16.5978 4.92323C17.0023 4.71457 17.3445 4.40303 17.5902 4.01998C17.8359 3.63693 17.9763 3.1959 17.9973 2.74132Z" fill="#5987DD" />
                <path d="M17.9973 2.74145C17.9973 1.22894 16.421 0.0028125 14.4762 0.0028125C13.8744 -0.0205492 13.2759 0.101906 12.7317 0.359745C12.1875 0.617584 11.7136 1.00316 11.3506 1.48363C12.0429 1.01895 12.86 0.775424 13.6937 0.785279C15.6385 0.785279 17.2148 2.0114 17.2148 3.52391C17.2113 3.97005 17.0751 4.40506 16.8236 4.77351C17.1699 4.556 17.4578 4.25717 17.6624 3.90303C17.8669 3.54888 17.9819 3.15013 17.9973 2.74145Z" fill="#95B4FF" />
                <path d="M14.0842 7.04503V5.48009C12.301 5.34551 10.9543 4.16359 10.9543 2.74146C10.958 2.29215 11.0957 1.85417 11.3499 1.48364C11.002 1.70181 10.7127 2.0018 10.5074 2.35744C10.3021 2.71307 10.1869 3.11354 10.1719 3.52393C10.1719 4.94606 11.5185 6.12797 13.3017 6.26256V7.82749L15.8146 5.70583C16.2167 5.47502 16.5613 5.15631 16.8228 4.77352C16.7489 4.82517 16.675 4.87681 16.5959 4.92337L14.0842 7.04503Z" fill="#F29410" />
                <path d="M12.5195 2.35034H13.302V3.13281H12.5195V2.35034Z" fill="#EDEDED" />
                <path d="M14.085 2.35034H14.8674V3.13281H14.085V2.35034Z" fill="#EDEDED" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="17.997" height={18} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcReactionSad
