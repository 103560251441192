import styled from 'styled-components'

const Bound = styled.div`
    padding: 25px 28px; 
    width: calc(100% - 56px);
    height: 514px;
    display: grid;
    grid-template-rows: 21px 1fr;
    grid-gap: 30px;
    .tab-switch{
        width: 100%;
        display: flex;
        flex-direction: row;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        color: #B1B1B1;
        text-transform: uppercase;
        .switcher{
            margin-right: 25px;
            cursor: pointer;
        }
        .active{
            color: #016730;
            border-bottom: 2px solid #016730;
        }
    }
`

export { Bound }